import React, { useState } from "react";

import { observer } from "mobx-react-lite";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import styled, { css } from "styled-components";
import { Button, Surface, Text } from "tap2visit-ui-kit";

import { ReactComponent as Arrow } from "public/ArrowLeft.svg";
import AuthStore from "store/Auth.Store";

import AddPassword from "./components/AddPassword";
import ChoiceUK from "./components/ChoiceUK";
import FirstStageAuth from "./components/FirstStageAuth";
import RegistrationStage from "./components/RegistrationStage";
import RestorePassword from "./components/RestorePassword";
import SecondStageAuth from "./components/SecondStageAuth";
import { ReactComponent as Illustration } from "./image/illustration.svg";
import { ReactComponent as Task } from "./image/task.svg";

export type TAuthProps = {
	email: string;
	nextStage: (stage: number) => void;
	setEmail?: React.Dispatch<React.SetStateAction<string>>;
	setCode?: React.Dispatch<React.SetStateAction<string>>;
	havePassword?: boolean;
	setHavePassword?: React.Dispatch<React.SetStateAction<boolean>>;
};

const AuthorizationPage = () => {
	const [email, setEmail] = useState("");
	const [code, setCode] = useState("");
	const [step, setStep] = useState(1);
	const stackStages = React.useRef([step]);

	const [havePassword, setHavePassword] = useState(false);
	const location = useLocation();

	const showBackButton = step === 5 || step === 4 || step === 3;

	const onBackHandler = () => {
		if (stackStages.current.length === 1) {
			return;
		}

		/**
		 * @describe delete current stage and back to previous
		 *
		 **/
		stackStages.current.pop();
		setStep(stackStages.current[stackStages.current.length - 1]);
	};

	const nextStage = (stage: number) => {
		stackStages.current.push(stage);
		setStep(stage);
	};

	React.useEffect(() => {
		const isAuthenticated = AuthStore.checkAuth();
		const searchParams = new URLSearchParams(location.search);
		const isSelectUK = searchParams.get("selectUK");
		if (!isSelectUK && isAuthenticated) {
			setStep(6);
		}
	}, [location.pathname]);

	return (
		<Wrapper step={step}>
			<ImageContainer>
				<Task />
				<Illustration />
			</ImageContainer>
			<AuthorizationContainer>
				{showBackButton && (
					<WrapperAction>
						<ButtonStyled size="large" typeButton="link" icon={Arrow} onClick={onBackHandler}>
							<Text type="small-regular">
								<FormattedMessage id="goBack" />
							</Text>
						</ButtonStyled>
					</WrapperAction>
				)}
				<Surface>
					<Form>
						{step === 1 && <FirstStageAuth email={email} setEmail={setEmail} nextStage={nextStage} setHavePassword={setHavePassword} />}
						{step === 2 && <RegistrationStage email={email} nextStage={nextStage} setCode={setCode} />}
						{step === 3 && <AddPassword email={email} code={code} navigateToChoiceUK={() => nextStage(6)} />}
						{step === 5 && <SecondStageAuth email={email} nextStage={nextStage} />}
						{step === 4 && <RestorePassword email={email} nextStage={nextStage} setCode={setCode} />}
						{step === 6 && <ChoiceUK />}
					</Form>
				</Surface>
			</AuthorizationContainer>
		</Wrapper>
	);
};

export default observer(AuthorizationPage);

const ButtonStyled = styled(Button)`
	font-size: 12px;
`;
const Wrapper = styled.div<{ step: number }>`
	height: 100vh;
	display: grid;
	grid-template-columns: repeat(2, 1fr);

	input {
		width: 100%;
	}

	${(props) => {
		let maxWidth = "1050px";

		if (props.step === 1) {
			maxWidth = "1050px";
		}

		if (props.step === 4 || props.step === 2) {
			maxWidth = "1540px";
		}

		if (props.step === 3) {
			maxWidth = "1340px";
		}

		if (props.step === 5) {
			maxWidth = "1140px";
		}

		return css`
			@media screen and (max-width: ${maxWidth}) {
				grid-template-columns: unset;
				> * {
					&:first-child {
						display: none;
					}
				}
			}
			@media screen and (max-width: 780px) {
				display: unset;
			}
		`;
	}}
`;

const AuthorizationContainer = styled.div`
	padding-left: 27%;
	padding-right: 27%;
	margin: auto;
	width: 100%;

	> * {
		&:last-child {
			height: auto;
			width: auto;
		}
	}

	@media screen and (max-width: 1050px) {
		padding-left: 25%;
		padding-right: 25%;
	}

	@media screen and (max-width: 780px) {
		padding: 40px 16px 0 16px;
	}
`;
const Form = styled.div`
	display: grid;
	gap: 24px;
`;
const ImageContainer = styled.div`
	padding: 8% 18%;
	display: flex;
	flex-direction: column;
	justify-content: center; //TODO: replace color from tokens
	background: #be984114;

	> * {
		&:first-child {
			height: inherit !important;
		}
	}
`;

const WrapperAction = styled.div`
	margin-bottom: 24px;

	button {
		padding: 0;
	}

	svg {
		margin-right: 4px;
	}
`;
