import React, { useEffect, useMemo, useState } from "react";

import { Typography } from "antd";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import { Drawer, FormItem, showToast, Upload } from "tap2visit-ui-kit";

import { Spacer } from "components/layout/Spacer";
import Input from "components/ui/Input/Input";
import { mask } from "constants/constants.mask";
import { emailRegExp } from "constants/constants.regexp";
import { queryClient } from "index";
import { TDataChangeInformation } from "interfaces/IOrganization";
import { QueriesKeys } from "interfaces/queriesKeys";
import UKStore from "store/UK.Store";

import { changeOrganizationApi, getOrganizationApi } from "../../../api/api.organization";
import { postFileUploadApi } from "../../../api/api.storage";
import { editOrCreateOfficeInputMaxLength } from "../constants/inputOffice.constants";
import useLogoUK from "../hooks/useLogoUK";

interface IDrawerChangeInformationProps {
	open: boolean;
	setOpen: (v: boolean) => void;
}
export const DrawerChangeInformation = ({ open, setOpen }: IDrawerChangeInformationProps) => {
	const [shortName, setShortName] = useState("");
	const [phone, setPhone] = React.useState("");
	const [email, setEmail] = React.useState("");
	const [file, setFile] = useState<File>();
	const [error, setError] = useState(false);
	const [errorFormat, setErrorFormat] = useState(false);
	const organizationId = UKStore.getUK()?.managingOrganizationId;
	const fileWasChanged = React.useRef(false);
	const [isErrorSize, setIsErrorSize] = React.useState(false);
	const [isRemoveFile, setIsRemoveFile] = useState(false);
	const changeOrganization = useMutation(changeOrganizationApi);
	const postFileUpload = useMutation(postFileUploadApi);

	const { logoUKImgFile } = useLogoUK();

	const organization = useQuery({
		queryFn: () => getOrganizationApi(organizationId),
		queryKey: [QueriesKeys.idOrganization, organizationId],
		enabled: !!organizationId,
	});

	React.useEffect(() => {
		if (!open) {
			fileWasChanged.current = false;
		}
	}, [open]);

	React.useEffect(() => {
		if (logoUKImgFile) {
			setFile(logoUKImgFile);
		}
	}, [logoUKImgFile]);

	const changeInputData = () => {
		setShortName(organization.data?.shortName);
		setPhone(organization.data?.phones?.find((phone) => phone.isShownToUsers)?.number ?? "");
		setEmail(organization.data?.emails?.find((phone) => phone.isShownToUsers)?.address ?? "");
	};

	useEffect(() => {
		if (file) {
			const convert = (file?.size / (1024 * 1024)).toFixed(2);
			if (Number(convert.split(".")[0]) > 20) {
				showToast({
					description: "Размер фотографии превышает 20 Mb",
					type: "danger",
				});
				setIsErrorSize(true);
			} else setIsErrorSize(false);
		}
	}, [file]);

	useEffect(() => {
		changeInputData();
	}, [organization.data]);

	const updateUK = (args: TDataChangeInformation) => {
		changeOrganization.mutateAsync(args).then(() => {
			setOpen(false);
			queryClient.invalidateQueries([QueriesKeys.idOrganization]);
		});
	};

	const changeInformation = async () => {
		const body: TDataChangeInformation = {
			id: organizationId,
		};

		if (phone.length > 0 && `+${phone.replace(/\D/g, "")}`.length !== 12) {
			return showToast({
				description: "Формат номера телефона неверный",
				type: "danger",
			});
		}

		if (email.length > 0 && !emailRegExp.test(email)) {
			return showToast({
				description: "Формат почты неверный",
				type: "danger",
			});
		}

		if (shortName && shortName !== organization.data.shortName) {
			body.shortName = shortName;
		}

		if (!shortName) body.shortName = null;

		if (phone && phone !== organization.data.phones[0]?.number) {
			body.phones = [
				{
					description: "",
					isShownToUsers: true,
					number: `+${phone.replace(/\D/g, "")}`,
				},
			];
		}

		if (!phone) body.phones = [];

		if (email && email !== organization.data.emails[0]?.address) {
			body.emails = [
				{
					description: "",
					isShownToUsers: true,
					address: email,
				},
			];
		}

		if (!email) body.emails = [];

		if (fileWasChanged.current) {
			if (file) {
				const formData = new FormData();
				if (file) {
					formData.append("file", file);
				}
				setError(false);
				try {
					const uploadedFile = await postFileUpload.mutateAsync({ file: formData, bucket: "MO_LOGOS" });
					body.logoId = uploadedFile.id;
				} catch {
					setError(true);
				}
			}
		}
		if (!organization.data.logoId && !fileWasChanged.current) {
			body.logoId = null;
		}
		if (organization.data.logoId && !isRemoveFile) {
			body.logoId = organization.data.logoId;
		}
		if (isRemoveFile) {
			body.logoId = null;
		}
		setIsRemoveFile(false);
		updateUK(body);
	};

	useEffect(() => {
		if (file) {
			if (file.type !== "image/jpeg" && file.type !== "image/png") {
				setErrorFormat(true);
				setError(true);
			} else {
				setError(false);
				setErrorFormat(false);
			}
		}
	}, [file]);

	const onCloseDrawer = () => {
		changeInputData();
		setOpen(false);
	};

	useEffect(() => {
		const bodyElement = document.querySelector("body");
		bodyElement.style.overflow = open ? "hidden" : "auto";
	}, [open]);

	const uploadComponent = useMemo(
		() => (
			<Upload
				error={error || isErrorSize}
				files={file ? [file] : undefined}
				containerStyle={{
					display: isErrorSize || error ? "block" : "flex",
					border: file ? "4px solid white" : "1px dashed #1212123D",
				}}
				wrapperStyle={{
					border: file ? "1px solid #12121229" : "none",
					boxShadow: file ? "0px -16px 16px -12px #10182808" : "none",
					borderRadius: "5px",
				}}
				onRemove={() => {
					fileWasChanged.current = true;
					setFile(undefined);
					setError(false);
					setIsErrorSize(false);
					setIsRemoveFile(true);
				}}
				textError={isErrorSize ? "Размер фотографии превышает 20 Mb" : "Ошибка добавления файла, неверный формат"}
				onChangeFiles={(e) => {
					fileWasChanged.current = true;
					setFile(e.target?.files[0]);
				}}
				onErrorAddFile={() => {
					setError(true);
				}}
				accept={"image/png,image/jpeg,image/jpg"}
				type="image"
			/>
		),
		[error, isErrorSize, file],
	);

	return (
		<Drawer
			title={<DrawerHeaderTitle>Редактировать сведения об УК</DrawerHeaderTitle>}
			visible={open}
			onClose={onCloseDrawer}
			onOk={changeInformation}
			onCancel={onCloseDrawer}
			width="700px"
			showCancel={true}
			disableOk={postFileUpload.isLoading || changeOrganization.isLoading}
			textOk="Сохранить"
			textCancel="Отменить">
			<Title>Загрузите логотип УК</Title>
			<Spacer px={4} />
			<TypographyStyled>Формат PNG, JPG (800x800px)</TypographyStyled>

			<Spacer px={16} />
			{uploadComponent}
			{errorFormat && <ErrorText>Неправильный формат файла</ErrorText>}

			<Spacer px={16} />
			<GridWrapper>
				<FormItem label="Сокращенное наименование" labelPosition="vertical">
					<InputStyled
						sizeInput="medium"
						value={shortName}
						onChangeValue={(value) => setShortName(value)}
						maxLength={editOrCreateOfficeInputMaxLength.shortName}
					/>
				</FormItem>
			</GridWrapper>

			<Spacer px={16} />
			<GridWrapper>
				<FormItem label="Телефон" labelPosition="vertical">
					<InputStyled sizeInput="medium" value={phone} onChangeValue={(value) => setPhone(value)} mask={mask.phone} />
				</FormItem>
			</GridWrapper>

			<Spacer px={16} />
			<GridWrapper>
				<FormItem label="Эл. почта" labelPosition="vertical">
					<InputStyled
						sizeInput="medium"
						value={email}
						state={!email.match(emailRegExp) && email !== "" ? "error" : "default"}
						onChangeValue={(value) => setEmail(value)}
						maxLength={editOrCreateOfficeInputMaxLength.email}
					/>
				</FormItem>
			</GridWrapper>
		</Drawer>
	);
};

const InputStyled = styled(Input)`
	font-size: 16px;
`;
const TypographyStyled = styled(Typography)`
	font-size: 12px;
`;
const DrawerHeaderTitle = styled.p`
	font-size: 20px;
`;
const ErrorText = styled.p`
	font-size: 14px;
	color: red;
`;
const Title = styled.p`
	font-weight: 700;
`;

const GridWrapper = styled.div`
	&& > div {
		width: 100%;
		p {
			color: rgba(18, 18, 18, 0.74);
		}
		input {
			width: 100%;
		}
	}
`;
