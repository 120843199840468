import { useState } from "react";

import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { Space, FormItem, Text, Select } from "tap2visit-ui-kit";

import DrawerStore from "pages/appeals/store/Drawer.store";
import { itemsPriority } from "pages/appeals/utils/getPriority";

const AppealsSettingsDrawer = () => {
	const [isOpenPrioritySelect, setIsOpenPrioritySelect] = useState(false);
	const [itemsPriorityState, setItemsPriorityState] = useState(itemsPriority);

	return (
		<Space width="100%" direction="column" align="start" size={8}>
			<Text h="h5">Дополнительные настройки</Text>
			<AppealsDrawerWrapper>
				<FormItem label="Приоритет" isRequiredStarLeft required>
					<Select
						onClickSelect={() => setIsOpenPrioritySelect(true)}
						onClickSelectItem={(item: any) => {
							setIsOpenPrioritySelect(false);
							setItemsPriorityState(
								itemsPriorityState.map((v) => ({
									...v,
									focused: v.key === item.key,
								})),
							);
							DrawerStore.setSelectedSettingsAppeals(item.key);
						}}
						onClose={() => setIsOpenPrioritySelect(false)}
						isOpened={isOpenPrioritySelect}
						size="l"
						placeholder="Выберите"
						style={{ width: "470px", background: "white" }}
						items={itemsPriorityState}
					/>
				</FormItem>
			</AppealsDrawerWrapper>
		</Space>
	);
};

const AppealsDrawerWrapper = styled.div`
	padding: 18px;
	background: #12121205;
	border-radius: 12px;
	width: 100%;
	gap: 15px;
	margin-bottom: 30px;
	display: flex;
	flex-direction: column;
`;

export default observer(AppealsSettingsDrawer);
