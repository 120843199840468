import type { TObjectType } from "interfaces/IBuilding";

export const getBuildingObjectTypeTitle = (type: TObjectType) => {
	switch (type) {
		case "BASEMENT":
			return "цоколь";
		case "FLAT":
			return "квартире";
		case "OFFICE":
			return "коммерческой недвижимости";
		case "STORAGE":
			return "кладовке";
		case "PARKING":
			return "парковке";
	}
};
