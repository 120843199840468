import { FC } from "react";

import { Spacer } from "components/layout/Spacer";
import { observer } from "mobx-react-lite";
import PollsRoles from "pages/polls/components/PollsRoles";
import PollSendMethods from "pages/polls/components/PollSendMethods";
import PollsTreeBuildings from "pages/polls/components/PollsTreeBuildings";
import { ReactComponent as PlusSvg } from "public/plus.svg";
import SideBarStore from "store/SideBar.store";
import styled from "styled-components";
import { Button, Loader } from "tap2visit-ui-kit";

import useSelectedPoll from "../../hooks/useSelectedPoll";
import PollsEditStore from "../../store/PollsItem.store";
import PollsSendModal from "../PollsSendModal/PollsSendModal";

import PollsDetailListQuestions from "./components/PollsDetail.listQuestions";
import PollsDetailNameCard from "./components/PollsDetailNameCard/PollsDetailNameCard";
import { OuterContainer } from "./components/QuestionItem/styles/common";

interface IPollsDetail {
	editable?: boolean;
}

// eslint-disable-next-line arrow-body-style
const PollsDetail: FC<IPollsDetail> = (props) => {
	const isFetching = useSelectedPoll();

	return (
		<>
			{isFetching && (
				<LoaderContainer>
					<Loader />
				</LoaderContainer>
			)}
			{!isFetching && (
				<>
					<Container>
						<PollsDetailNameCard editable={props.editable} />

						<Spacer px={16} />
						<PollsDetailListQuestions editable={props.editable} />

						{props.editable && (
							<>
								<Spacer px={16} />
								<Button icon={PlusSvg} typeButton="secondary" onClick={PollsEditStore.addPollQuestion}>
									Добавить вопрос
								</Button>
							</>
						)}

						{!props.editable && (
							<SendContainerInformation>
								<PollSendMethods />

								<Spacer px={16} />

								<PollsRoles />

								<Spacer px={16} />

								<PollsTreeBuildings editable={props.editable} />

								<Spacer px={16} />
							</SendContainerInformation>
						)}
					</Container>

					{props.editable && <PollsSendModal />}
				</>
			)}
		</>
	);
};

export default observer(PollsDetail);

const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
`;

const LoaderContainer = styled.div`
	position: absolute;
	top: 50%;
	left: calc(50% + ${SideBarStore.sideBarWidth});
`;

const SendContainerInformation = styled(OuterContainer)`
	width: 50%;
	padding-left: 24px;
	padding-right: 24px;
	padding-top: 16px;
`;
