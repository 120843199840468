import { useState } from "react";

import { FormItem, Select } from "tap2visit-ui-kit";

import type { IDataList } from "../store/UpdateDataStore";

interface IUpdateDataFormSelectProps {
	items: IDataList[];
	setItems: (items: IDataList[]) => void;
	label: string;
	disabled?: boolean;
}

export const UpdateDataFormSelect = ({ items, setItems, label, disabled }: IUpdateDataFormSelectProps) => {
	const [isOpenSelect, setIsOpenSelect] = useState(false);

	return (
		<FormItem required label={label} labelPosition={"vertical"}>
			<Select
				style={{ width: "100%" }}
				isDisabled={disabled}
				items={items}
				onClickSelect={() => setIsOpenSelect(true)}
				isOpened={isOpenSelect}
				placeholder="Не выбрано"
				onClose={() => setIsOpenSelect(false)}
				onClickSelectItem={(item) => {
					setItems(
						items.map((element) => ({
							...element,
							focused: item.key === element.key,
						})),
					);
					setIsOpenSelect(false);
				}}
			/>
		</FormItem>
	);
};
