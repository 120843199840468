import React, { FC } from "react";

import { useQuery } from "react-query";

import { getAliasesApi } from "api/api.aliases";
import { getBuildingObjectIdsByUserIdApi, getBuildingObjectsApi } from "api/api.realEstate";
import { QueriesKeys } from "interfaces/queriesKeys";

import CitizensDrawerStore from "../../../../components/Citizens/store/Citizens.drawer.store";

import RealEstateRowsItem from "./RealEstateRows.item";

interface IRealEstateRows {
	setOpenRealtyDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}

const RealEstateRows: FC<IRealEstateRows> = (props) => {
	const citizenBuildingObjectsIds = useQuery({
		queryFn: () => getBuildingObjectIdsByUserIdApi({ path: { clientId: CitizensDrawerStore.selectedUser.id } }),
		enabled: !!CitizensDrawerStore.selectedUser.id,
		queryKey: [QueriesKeys.citizenBuildingObjectsIds, CitizensDrawerStore.selectedUser.id],
	});

	const citizenBuildingObjectsIdsMap = citizenBuildingObjectsIds.data?.map((i) => i.buildingObjectId);
	const citizenAliasesIdsMap = citizenBuildingObjectsIds.data?.map((i) => i.aliasId);

	const citizenAliases = useQuery({
		queryFn: () => {
			if (citizenAliasesIdsMap.length) {
				return getAliasesApi({ params: { aliasIds: citizenAliasesIdsMap } });
			}
			return [];
		},
		queryKey: [QueriesKeys.aliases, ...(citizenAliasesIdsMap ?? [])],
		enabled: citizenBuildingObjectsIds.isFetched,
	});

	const citizenBuildingObjects = useQuery({
		queryFn: () => {
			if (citizenBuildingObjectsIdsMap.length) {
				return getBuildingObjectsApi({ params: { buildingObjectIds: citizenBuildingObjectsIdsMap } });
			}
			return [];
		},
		queryKey: [QueriesKeys.citizenBuildingObjects, ...(citizenBuildingObjectsIdsMap ?? [])],
		enabled: citizenBuildingObjectsIds.isFetched,
	});

	return (
		<>
			{citizenBuildingObjectsIds.data?.map((objectsIds) => (
				<RealEstateRowsItem
					objectsIds={objectsIds}
					citizenAliases={citizenAliases.data}
					citizenBuildingObjects={citizenBuildingObjects.data}
				/>
			))}
		</>
	);
};

export default RealEstateRows;
