import ValidationStore, { ValidationStatuses } from "modules/validation/Validation.store";

export type TAppealsCreateTitleValidationStore = {
	title: keyof typeof ValidationStatuses;
	description: keyof typeof ValidationStatuses;
};

const initialValidationData: TAppealsCreateTitleValidationStore = {
	title: ValidationStatuses.VALID,
	description: ValidationStatuses.VALID,
};

const COMMON_REQUIRED_ERROR_MESSAGE = "Обязательно к заполнению";

export const ERROR_MESSAGES: Record<keyof TAppealsCreateTitleValidationStore, Partial<Record<keyof typeof ValidationStatuses, string>>> = {
	title: { REQUIRED: COMMON_REQUIRED_ERROR_MESSAGE, LESS_THEN: "Поле не должно содержать более 50 символов" },
	description: { REQUIRED: COMMON_REQUIRED_ERROR_MESSAGE, LESS_THEN: "Поле не должно содержать более 500 символов" },
};

const checkRules = {
	title: {
		[ValidationStatuses.REQUIRED]: true,
		[ValidationStatuses.LESS_THEN]: 50,
	},
	description: {
		[ValidationStatuses.REQUIRED]: true,
		[ValidationStatuses.LESS_THEN]: 500,
	},
};

const AppealsCreateTitleValidationStore = new ValidationStore<TAppealsCreateTitleValidationStore>(
	initialValidationData,
	ERROR_MESSAGES,
	checkRules,
);

export default AppealsCreateTitleValidationStore;
