import { makeAutoObservable } from "mobx";

class AppealsClose {
	isOpenDrawer = false;

	constructor() {
		makeAutoObservable(this);
	}

	setIsOpenDrawer = (isOpenDrawer: boolean) => {
		this.isOpenDrawer = isOpenDrawer;
	};
}

export default new AppealsClose();
