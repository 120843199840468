import React, { FC } from "react";

import { ReactComponent as AlertCircle } from "public/AlertCircleIcon.svg";
import { ReactComponent as ArrowRight } from "public/arrowRight.svg";
import { ReactComponent as BarrierIcon } from "public/BarrierIcon.svg";
import { ReactComponent as BulletedList } from "public/BulletedList.svg";
import { ReactComponent as CharityCoinsIcon } from "public/CharityCoinsIcon.svg";
import { ReactComponent as ChatIcon } from "public/Chat.svg";
import { ReactComponent as DocumentIcon } from "public/Document.svg";
import { ReactComponent as Entrance } from "public/Entrance.svg";
import { ReactComponent as ReceiptBill } from "public/ReceiptBill.svg";
import { ReactComponent as StopWatch } from "public/StopWatch.svg";
import { ReactComponent as UserCheckedIcon } from "public/UserCheckedIcon.svg";
import { ReactComponent as UserDeleteIcon } from "public/UserDeleteIcon.svg";
import { ReactComponent as MenuSvg } from "public/verticale_dot.svg";
import { ReactComponent as VideoCameraIcon } from "public/VideoCameraIcon.svg";
import { useNavigate, useParams } from "react-router-dom";
import { Badge, Button, colors, Space, Tag } from "tap2visit-ui-kit";
import { getFormtattedSum } from "utils/format/getFormtattedSum";

import { IEntrances } from "../../interfaces/IComplexes";

import BuildingsStore from "./Buildings.store";
import {
	BuildingsFooter,
	BuildingsHouseCardHeader,
	BuildingsHouseCardTitle,
	BuildingsListTitle,
	BuildingsListWrapper,
	BuildingsParkingCardWrapper,
} from "./Buildings.styled";

interface IBuildingsEntranceCard {
	item: IEntrances;
}

const BuildingsEntranceCard: FC<IBuildingsEntranceCard> = ({ item }) => {
	const { id } = useParams();
	const navigate = useNavigate();

	const buildingsList = [
		{
			title: "Дать объявление",
			icon: <ChatIcon />,
			onClick: () => {
				navigate("/announcement/create", { state: { selectedId: BuildingsStore.selectedId, buildingId: id, entranceId: item.id } });
			},
		},
		{
			title: "Запрос на доступ",
			icon: <UserCheckedIcon />,
			isAccessKey: item.requestCount,
			onClick: () => {
				navigate("/access-request", { state: { selectedId: BuildingsStore.selectedId, buildingId: id, entranceId: item.id } });
			},
		},
		{
			title: "Список объявлений",
			isListAnnouncementKey: !!item.emergencyAnnouncementCount,
			icon: <AlertCircle />,
			onClick: () => {
				navigate("/announcement", { state: { selectedId: BuildingsStore.selectedId, buildingId: id, entranceId: item.id } });
			},
		},
		{
			title: "Список жителей",
			icon: <BulletedList />,
			onClick: () => {
				navigate("/citizens", { state: { selectedId: BuildingsStore.selectedId, buildingId: id, entranceId: item.id } });
			},
		},
		{
			title: "Список должников",
			icon: <UserDeleteIcon />,
		},
		{
			title: "Общедомовые счетчики",
			icon: <StopWatch />,
		},
		{
			title: "Документы подъезда",
			icon: <DocumentIcon />,
		},
		{
			title: "Камеры на подъезде",
			icon: <VideoCameraIcon />,
		},
		{
			title: "Шлагбаумы",
			icon: <BarrierIcon />,
		},
	];

	return (
		<BuildingsParkingCardWrapper>
			<BuildingsHouseCardHeader>
				<Space direction="column" align="start">
					<Space justify="space-between">
						<BuildingsHouseCardTitle>Подъезд {item.number}</BuildingsHouseCardTitle>
						<Entrance />
					</Space>
					<Space>
						<Badge
							icon={<CharityCoinsIcon />}
							background={colors.iconDangerDefault}
							color={colors.surfaceNeutralBgWhite}
							text={getFormtattedSum(item.debtSum.toString())}
							type="status"
						/>
						<Badge
							icon={<ReceiptBill />}
							background={colors.iconDangerDefault}
							color={colors.surfaceNeutralBgWhite}
							text={String(item.debtAccountCount)}
							type="status"
						/>
					</Space>
					<Space>
						<Tag color="grey" text={`Квартир: ${item.buildingObjectCount}`} />
						<Tag color="grey" text={`Этажей: ${item.floorCount}`} />
					</Space>
				</Space>
			</BuildingsHouseCardHeader>
			{buildingsList.map((v) => (
				<BuildingsListWrapper onClick={v.onClick}>
					<Space justify="space-between">
						<Space>
							{v.icon}
							<BuildingsListTitle>{v.title}</BuildingsListTitle>
						</Space>
						<Space>
							{v.isAccessKey ? <Badge status="grey" text={String(item.requestCount)} type="status" /> : ""}
							{v.isListAnnouncementKey && (
								<Badge
									background={colors.strokeDangerDefault}
									color={colors.surfaceNeutralBgWhite}
									text={`${item.emergencyAnnouncementCount} ЧП`}
									type="status"
								/>
							)}
							<ArrowRight />
						</Space>
					</Space>
				</BuildingsListWrapper>
			))}
			<BuildingsFooter>
				<Space justify="space-between">
					<Button
						typeButton="secondary"
						onClick={() => {
							BuildingsStore.setEntranceId(id);
							navigate(`/buildings/floor/${item.id}`);
						}}>
						Список квартир
					</Button>
					<Button typeButton="secondary" shape="square" icon={MenuSvg} />
				</Space>
			</BuildingsFooter>
		</BuildingsParkingCardWrapper>
	);
};

export default BuildingsEntranceCard;
