import React, { FC } from "react";

import { isVisibleFunctionality } from "constants/constants.common";
import AppealsHeader from "pages/appeals/Appeals.header";
import CitizensHeader from "pages/citizens/Citizens.header";
import { CountersHeader } from "pages/counters/CountersHeader";
import EmployeeHeader from "pages/employee/Employee.header";
import PollsDetailHeader from "pages/polls/components/PollsDetail.header";
import PollsHeader from "pages/polls/Polls/Polls.header";
import PollsViewHeader from "pages/polls/PollsView/PollsView.header";
import ReceiptsHeader from "pages/receipts/components/Receipts.header";
import { UpdateHeader } from "pages/updateData/UpdateHeader";

import AccessRequestHeader from "../pages/accessRequest/AccessRequest.header";
import AnnouncementCreateHeader from "../pages/announcemets/components/AnnouncementCreateHeader";
import ExclamationHeader from "../pages/announcemets/components/AnnouncementHeader";
import BuildingsHeader from "../pages/buildings/Buildings.header";
import BuildingsEntranceHeader from "../pages/buildings/BuildingsEntrance.header";
import BuildingsHouseHeader from "../pages/buildings/BuildingsHouse.header";
import ProfileHeader from "../pages/profileUK/components/ProfileHeader";

import { ROUTES } from "./constants/routes.constants";

const RoutesHeader: FC = () => {
	if (location.pathname.startsWith(`/${ROUTES.ANNOUNCEMENT}/edit`)) {
		return <AnnouncementCreateHeader mode="edit" />;
	}

	if (location.pathname.startsWith(`/${ROUTES.VOTE}/edit`) || location.pathname.startsWith(`/${ROUTES.VOTE}/create`)) {
		return <PollsDetailHeader />;
	}

	if (location.pathname.startsWith(`/${ROUTES.VOTE}/view`)) {
		return <PollsViewHeader />;
	}

	switch (location.pathname) {
		case `/${ROUTES.RESIDENTIAL_COMPLEX}`:
			if (isVisibleFunctionality) {
				return <BuildingsHeader />;
			}
			break;
		case `/${ROUTES.EMPLOYEE}`:
			return <EmployeeHeader />;
		case `/${ROUTES.APPEAL}`:
			return <AppealsHeader />;
		case `/${ROUTES.PROFILE_MO}`:
		case `/${ROUTES.PROFILE_MO}/edit`:
			if (isVisibleFunctionality) {
				return <ProfileHeader />;
			}
			break;
		case `/${ROUTES.RESIDENT}`:
			return <CitizensHeader />;
		case `/${ROUTES.REQUEST_ACCESS}`:
			return <AccessRequestHeader />;
		case `/${ROUTES.ANNOUNCEMENT}`:
			if (isVisibleFunctionality) {
				return <ExclamationHeader />;
			}
			break;
		case `/${ROUTES.ANNOUNCEMENT}/create`:
			if (isVisibleFunctionality) {
				return <AnnouncementCreateHeader mode="create" />;
			}
			break;
		case `/${ROUTES.VOTE}`:
			return <PollsHeader />;
		case `/${ROUTES.PAYMENT}`:
			return <ReceiptsHeader />;
		case `/${ROUTES.ARCUS}`:
			return <UpdateHeader />;
		case `/${ROUTES.METERING_DEVICE}`:
			return <CountersHeader />;
	}

	if (isVisibleFunctionality) {
		if (location.pathname.includes(`/${ROUTES.RESIDENTIAL_COMPLEX}/house`)) return <BuildingsHouseHeader />;
		if (location.pathname.includes(`/${ROUTES.RESIDENTIAL_COMPLEX}/entrance`)) return <BuildingsEntranceHeader />;
		if (location.pathname.includes(`/${ROUTES.RESIDENTIAL_COMPLEX}/floor`)) return <BuildingsEntranceHeader />;
	}

	return <></>;
};

export default RoutesHeader;
