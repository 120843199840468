import { getUserByIdApi } from "api/api.clients";
import { getRolesApi } from "api/api.realEstate";
import { QueriesKeys } from "interfaces/queriesKeys";
import { useQuery } from "react-query";
import getRoleTranslate from "utils/getRoleTranslate";

const useUserRole = (args: { roleId: string | string[]; clientId?: string }) => {
	const roles = useQuery({
		queryFn: () => getRolesApi(),
		queryKey: [QueriesKeys.roles],
	});

	const roleIds = Array.isArray(args.roleId) ? args.roleId : [args.roleId];
	const currentRoles = roles.data?.filter((role) => roleIds.includes(role.id));

	return currentRoles?.map((role) => getRoleTranslate(role.name)).join(", ");
};

export default useUserRole;
