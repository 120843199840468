import ValidationStore, { ValidationStatuses } from "modules/validation/Validation.store";

export type TAppealsCloseValidation = {
	notification: keyof typeof ValidationStatuses;
};

const initialValidationData: TAppealsCloseValidation = {
	notification: ValidationStatuses.VALID,
};

const COMMON_REQUIRED_ERROR_MESSAGE = "Обязательно к заполнению";

export const ERROR_MESSAGES: Record<keyof TAppealsCloseValidation, Partial<Record<keyof typeof ValidationStatuses, string>>> = {
	notification: { REQUIRED: COMMON_REQUIRED_ERROR_MESSAGE, LESS_THEN: "Поле не должно содержать более 255 символов" },
};

const checkRules = {
	notification: {
		[ValidationStatuses.REQUIRED]: true,
		[ValidationStatuses.LESS_THEN]: 255,
	},
};

const AppealsCloseValidation = new ValidationStore<TAppealsCloseValidation>(initialValidationData, ERROR_MESSAGES, checkRules);

export default AppealsCloseValidation;
