import React from "react";

import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Text } from "tap2visit-ui-kit";

import { getFileInformation } from "api/api.storage";
import { IChatListItem, IMessageContent } from "interfaces/IChat";
import { QueriesKeys } from "interfaces/queriesKeys";

import ChatFileInformation from "./ChatFileInformation";

interface IChatReplayFotterInformation {
	messageListArray: IMessageContent[];
	currentIdMesssage: string;
	user: IChatListItem[];
}
const ChatReplayFotterInformation = ({ user, messageListArray, currentIdMesssage }: IChatReplayFotterInformation) => {
	const { id } = useParams<{ id: string }>();
	const fileInformation = useQuery({
		queryFn: () => getFileInformation(messageListArray.find((v) => v.id === currentIdMesssage)?.fileIds[0]),
		enabled: !!messageListArray.find((v) => v.id === currentIdMesssage)?.fileIds[0],
		queryKey: [QueriesKeys.fileChatInformation, messageListArray.find((v) => v.id === currentIdMesssage)?.fileIds[0]],
	});

	return (
		<ChatReplayFotterInformationWrapper>
			<ChatFileInformation
				isReplayMessage
				isFileDrawer={false}
				fileId={messageListArray.find((v) => v.id === currentIdMesssage)?.fileIds[0]}
			/>
			<TextWrapper>
				<Text type="large-medium" color="#99752C">
					В ответ{" "}
					{messageListArray.filter((v) => v.id === currentIdMesssage && !v.isCreatedByCurrentUser).length > 0
						? user.find((v) => v.id === id).name
						: ""}
				</Text>
				<Text type="base-regular" color="#121212E5">
					{fileInformation.data[0]?.fileName}
				</Text>
			</TextWrapper>
		</ChatReplayFotterInformationWrapper>
	);
};
const TextWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;
const ChatReplayFotterInformationWrapper = styled.div`
	display: flex;
	gap: 10px;
	align-items: center;
`;
export default ChatReplayFotterInformation;
