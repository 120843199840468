import React from "react";

import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { Space, colors, Text } from "tap2visit-ui-kit";

import DrawerStore from "pages/appeals/store/Drawer.store";

import { ReactComponent as BoldStar } from "../../img/BoldStar.svg";
import { ReactComponent as Star } from "../../img/Star.svg";

const AppealFeedback = () => {
	const maxStars = 5;
	const boldStarsCount = DrawerStore.selectedAppeals.notification.rating || 0;
	const regularStarsCount = boldStarsCount ? maxStars - boldStarsCount : maxStars;
	return (
		<Space width="100%" direction="column" align="start">
			<Text h="h5" color={colors.textNeutralPrimary}>
				Обратная связь
			</Text>
			<AppealFeedbackWrapper>
				<AppealNotification>
					<Text color={colors.textNeutralSecondary} type="table-cell-m-regular">
						Уведомление
					</Text>
					<Text color={colors.textNeutralPrimary} type="table-cell-m-medium">
						{DrawerStore.selectedAppeals.notification.value}
					</Text>
				</AppealNotification>

				<GradeWrapper>
					<Text type="table-cell-l-medium" color={colors.textNeutralPrimary}>
						Оценка клиента
					</Text>
					<StarWrapper>
						{boldStarsCount ? [...Array(boldStarsCount || 1)].map((_, index) => <BoldStar key={index} />) : ""}
						{[...Array(regularStarsCount)].map((_, index) => (
							<Star key={index} />
						))}
					</StarWrapper>
				</GradeWrapper>
			</AppealFeedbackWrapper>
		</Space>
	);
};

const AppealNotification = styled.div`
	padding: 8px 12px;
`;
const GradeWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-top: 1px solid #12121229;
	padding: 8px 12px;
`;
const StarWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
	cursor: pointer;
`;
const AppealFeedbackWrapper = styled.div`
	background: #f1f1f1;
	border-radius: 12px;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 5px;
`;
export default observer(AppealFeedback);
