import { makeAutoObservable } from "mobx";

export type TPollsTabs = "active" | "archive" | "template";
export type TOverviewTabs = "statistic" | "respondents";

class PollsTabsStore {
	activeTab: TPollsTabs = "active";
	activeOverviewTab: TOverviewTabs = "statistic";
	onlyActiveStatus = false;
	isCreateTemplate = false;

	constructor() {
		makeAutoObservable(this);
	}

	setActiveTab = (tabKey: TPollsTabs) => {
		this.activeTab = tabKey;
	};

	setActiveOverviewTab = (tabKey: TOverviewTabs) => {
		this.activeOverviewTab = tabKey;
	};

	toggleOverviewTab = () => {
		this.activeOverviewTab = this.activeOverviewTab === "statistic" ? "respondents" : "statistic";
	};

	toggleOnlyActiveStatus = () => {
		this.onlyActiveStatus = !this.onlyActiveStatus;
	};

	getIsCreateTemplate() {
		return this.isCreateTemplate;
	}

	getActiveTab() {
		return this.activeTab;
	}
}

export default new PollsTabsStore();
