import ValidationStore, { ValidationStatuses } from "modules/validation/Validation.store";

export type TAccessValidationFields = {
	comment: keyof typeof ValidationStatuses;
};

const initialValidationData: TAccessValidationFields = {
	comment: ValidationStatuses.VALID,
};

const COMMON_REQUIRED_ERROR_MESSAGE = "Обязательно к заполнению";

export const ERROR_MESSAGES: Record<keyof TAccessValidationFields, Partial<Record<keyof typeof ValidationStatuses, string>>> = {
	comment: { REQUIRED: COMMON_REQUIRED_ERROR_MESSAGE, LESS_THEN: "Поле не должно содержать более 500 символов" },
};

const checkRules = {
	comment: {
		[ValidationStatuses.REQUIRED]: true,
		[ValidationStatuses.LESS_THEN]: 500,
	},
};

const AccessValidationStore = new ValidationStore<TAccessValidationFields>(initialValidationData, ERROR_MESSAGES, checkRules);

export default AccessValidationStore;
