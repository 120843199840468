import styled from "styled-components";
import { Tab } from "tap2visit-ui-kit";

import PollsTabsStore, { TOverviewTabs } from "pages/polls/Polls/store/Polls.tabs.store";

const tabs: { key: TOverviewTabs; label: string }[] = [
	{
		key: "statistic",
		label: "Статистика",
	},
];

export const PollsTabs = () => (
	<TabsContainer>
		<Tab
			activeKey={PollsTabsStore.activeOverviewTab}
			items={tabs}
			paddingItem={"12px"}
			onTabClick={(key: TOverviewTabs) => PollsTabsStore.setActiveOverviewTab(key)}
			gap={12}
		/>
	</TabsContainer>
);

const TabsContainer = styled.div`
	display: flex;
	justify-content: start;
	width: 50%;
`;
