/* eslint-disable arrow-body-style */

import { FC } from "react";

import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { Pagination, Table } from "tap2visit-ui-kit";

import Page from "components/layout/Page";
import { Spacer } from "components/layout/Spacer";
import PaginationContainer from "components/PaginationContainer";
import TableEmptySlug from "components/Table/TableEmptySlug";
import TableLoader from "components/Table/TableLoader";
import { MIN_TABLE_HEIGHT } from "constants/constants.common";
import useWindowWidth from "hooks/useWindowWidth";
import SideBarStore from "store/SideBar.store";

import useTableData from "../hooks/useTableData/useTableData";

interface IPollsTable {}

const PollsTable: FC<IPollsTable> = (props) => {
	const navigate = useNavigate();
	const pollsData = useTableData();
	const windowWidth = useWindowWidth();

	return (
		<Page>
			<Table
				columns={pollsData.pollColumn}
				data={pollsData.pollData}
				size={"m"}
				isFullWidth
				style={{
					width: `calc(${windowWidth}px - ${SideBarStore.sideBarWidth})`,
					overflowX: "auto",
					minHeight: MIN_TABLE_HEIGHT,
				}}
				onRowClick={(r) => {
					if (r.meta.isTemplate) {
						navigate(`/polls/edit/${r.id}`);
						return;
					}

					if (r.meta.status === "ARCHIVE" || r.meta.status === "ACTIVE") {
						navigate(`/polls/view/${r.id}`);
					} else {
						navigate(`/polls/edit/${r.id}`);
					}
				}}
				emptyComponent={pollsData.isFetching ? TableLoader : TableEmptySlug}
			/>

			<Spacer px={10} />
			<PaginationContainer>
				<Pagination
					onChangeSize={pollsData.pagination.setClientsSize}
					currentPage={pollsData.pagination.clientsCurrentPage}
					setCurrentPage={pollsData.pagination.setClientsCurrentPage}
					total={pollsData.pagination.clientsTotal}
					size={pollsData.pagination.clientsSize as 10 | 20 | 50 | 100}
				/>
			</PaginationContainer>
		</Page>
	);
};

export default observer(PollsTable);
