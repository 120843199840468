import React from "react";

import styled from "styled-components";

import { IRequestUser } from "interfaces/IRequest";
import { ReactComponent as CheckSvg } from "public/checkCircle.svg";
import { ReactComponent as UnCheckSvg } from "public/uncheckCircle.svg";

type TStatus = IRequestUser["status"];

const AccessRequestStatus = ({ status }: { status: boolean }) => <Container>{status ? <CheckSvg /> : <UnCheckSvg />}</Container>;

const Container = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
`;

export { AccessRequestStatus, type TStatus };
