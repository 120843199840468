import React, { useEffect, useReducer, useState } from "react";

import { Space } from "antd";
import { createDocumentApi } from "api/api.documents";
import BaseModal from "components/Modals/BaseModal";
import dayjs from "dayjs";
import { queryClient } from "index";
import { IDocumentCreate, TDocumentType } from "interfaces/IDocuments";
import { observer } from "mobx-react-lite";
import { createPortal } from "react-dom";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import { Button, FormItem, Select, TContextMenuItem } from "tap2visit-ui-kit";

import { addClientToBuildingObjectApi, getBuildingObjectIdsByUserIdApi, getRolesApi } from "../../../api/api.realEstate";
import { QueriesKeys } from "../../../interfaces/queriesKeys";
import getRoleTranslate from "../../../utils/getRoleTranslate";
import useNewFileState from "../hooks/useNewFileState";
import useUploadCitizenFiles from "../hooks/useUploadCitizenFiles";
import CitizenBuildingObjectStore from "../store/CitizenBuildingObject.store";
import CitizensDrawerStore from "../store/Citizens.drawer.store";
import { CitizenRealtyCreateDivider, FooterButtonWrapper } from "../style/Citizen.styled";
import getDocumentMask, { TCitizenBuildingDocumentType, TInputType } from "../utils/getDocumentMask";

import CitizenFiles from "./CitizenFiles";
import UserEditInput, { UserEditDate } from "./UserEdit.input";

interface ICitizenRoleModal {
	open: boolean;
	setOpen: (v: boolean) => void;
	selectedCitizenId: string;
	selectedBuildingId?: string;
	selectedBuildingAddress?: string;
	closeSelectUserDrawer?: () => void;
}

const initialState: {
	label: string;
	placeholder: string;
	type: TInputType;
	value: string | dayjs.Dayjs;
}[] = [
	{
		label: "Серия",
		placeholder: "",
		type: "seriesBuilding",
		value: "",
	},
	{
		label: "Номер документа",
		placeholder: "",
		type: "numberBuilding",
		value: "",
	},
	{
		label: "Кем выдан",
		placeholder: "",
		type: "departmentCodeBuilding",
		value: "",
	},
	{
		label: "Дата выдачи",
		placeholder: "00.00.0000",
		type: "dateOfIssueBuilding",
		value: dayjs(),
	},
	{
		label: "Дата окончания срока действия",
		placeholder: "00.00.0000",
		type: "expirationDateBuilding",
		value: dayjs(),
	},
	{
		label: "Доля",
		placeholder: "1/4",
		type: "shareBuilding",
		value: "",
	},
];

const reducer = (state: typeof initialState, action: { field?: TInputType; value?: string | dayjs.Dayjs; type: "SET_VALUE" | "CLEAR" }) => {
	switch (action.type) {
		case "SET_VALUE":
			return state.map((input) => (input.type === action.field ? { ...input, value: action.value } : input));
		case "CLEAR":
			return initialState;
		default:
			return state;
	}
};

const initialSelectedDocumentType = [
	{
		focused: false,
		key: "EGRN",
		text: "ЕГРН",
	},
	{
		focused: false,
		key: "CERTIFICATE_OF_STATE_REGISTRATION_OF_RIGHT",
		text: "Свидетельство о государственной регистрации права",
	},
];

const CitizenRoleModal = ({
	open,
	setOpen,
	selectedCitizenId,
	closeSelectUserDrawer,
	selectedBuildingId,
	selectedBuildingAddress,
}: ICitizenRoleModal) => {
	const [selectedRoles, setSelectedRoles] = useState<TContextMenuItem[]>([]);
	const [selectedDocumentType, setSelectedDocumentType] = useState<TContextMenuItem[]>(initialSelectedDocumentType);
	const { needToRemoveFileIds, newFiles, setNewFiles } = useNewFileState();

	const [inputsValue, dispatchRoleInput] = useReducer(reducer, initialState);

	const [openSelect, setOpenSelect] = useState(false);
	const [openSelectDocument, setOpenSelectDocument] = React.useState(false);

	const currentSelectedDocumentType = selectedDocumentType.find((doc) => doc.focused);
	const currentSelectedRole = selectedRoles.find((role) => role.focused);
	const currentSelectedBuildingId = selectedBuildingId ?? CitizenBuildingObjectStore.selectedBuilding?.id;
	const currentSelectedBuildingAddress = selectedBuildingAddress ?? CitizenBuildingObjectStore.selectedBuildingAddress;

	const roles = useQuery({
		queryFn: () => getRolesApi(),
		queryKey: [QueriesKeys.roles],
	});
	const createDocument = useMutation(createDocumentApi);
	const addClientToBuildingObject = useMutation(addClientToBuildingObjectApi);

	const citizenBuildingObjects = useQuery({
		queryFn: () => getBuildingObjectIdsByUserIdApi({ path: { clientId: CitizensDrawerStore.selectedUser.id } }),
		enabled: !!CitizensDrawerStore.selectedUser.id,
		queryKey: [QueriesKeys.citizenBuildingObjectsIds, CitizensDrawerStore.selectedUser.id],
	});

	const currentBuilding = citizenBuildingObjects.data?.find((buildingObject) => buildingObject.buildingObjectId === selectedBuildingId);

	const uploadFiles = useUploadCitizenFiles({
		needToRemoveFileIds: needToRemoveFileIds,
		newFiles: newFiles,
	});

	const setInputValue = (field: TInputType, value: string | dayjs.Dayjs) => {
		dispatchRoleInput({ type: "SET_VALUE", field, value });
	};

	useEffect(() => {
		if (roles.data) {
			const isAlreadySelectedRoles = currentBuilding?.roles?.map((role) => role.id) ?? [];

			setSelectedRoles(
				roles.data.map((v) => {
					const isDisabled = isAlreadySelectedRoles.includes(v.id);

					return {
						key: v.id,
						text: getRoleTranslate(v.name),
						focused: CitizenBuildingObjectStore.preselectedRole === v.name,
						disabled: isDisabled,
					};
				}),
			);
		}
	}, [roles.data, currentBuilding, open]);

	const onDocumentSelect = (item: TContextMenuItem) => {
		setSelectedDocumentType((contextItems) =>
			contextItems.map((currentItem) => ({
				...currentItem,
				focused: currentItem.key === item.key,
			})),
		);
		setOpenSelectDocument(false);
	};

	const onSave = async () => {
		const newFileIds = await uploadFiles();

		const body: IDocumentCreate = {
			type: currentSelectedDocumentType.key as TDocumentType,
			fileIds: newFileIds,
			number: "", // ! will be filled bellow
			sourceId: selectedCitizenId,
			targetId: currentSelectedBuildingId,
			address: currentSelectedBuildingAddress,
		};

		inputsValue.forEach((v) => {
			switch (v.type) {
				case "numberBuilding":
					body.number = v.value as string;
					break;
				case "seriesBuilding":
					body.series = v.value as string;
					break;
				case "departmentCodeBuilding":
					body.issueBy = v.value as string;
					break;
				case "dateOfIssueBuilding":
					body.dateOfIssue = (v.value as dayjs.Dayjs).format("YYYY-MM-DD");
					break;
				case "expirationDateBuilding":
					body.expirationDate = (v.value as dayjs.Dayjs).format("YYYY-MM-DD");
					break;
				case "shareBuilding":
					body.share = v.value as string;
			}
		});

		await createDocument.mutateAsync({
			body,
		});

		await addClientToBuildingObject.mutateAsync({
			path: {
				clientId: selectedCitizenId,
			},
			body: [
				{
					buildingObjectId: currentSelectedBuildingId,
					roleIds: [currentSelectedRole.key],
					action: "ADD",
				},
			],
		});

		queryClient.invalidateQueries([QueriesKeys.citizenBuildingObjectsIds, CitizensDrawerStore.selectedUser.id]);

		queryClient.invalidateQueries([QueriesKeys.buildingObjectClients]);

		setOpen(false);
		closeSelectUserDrawer?.();
	};

	// ? disposing function
	React.useEffect(() => {
		if (!open) {
			dispatchRoleInput({ type: "CLEAR" });
			setSelectedDocumentType(initialSelectedDocumentType);
		}
	}, [open]);

	return (
		<>
			<BaseModal
				isVisible={open}
				title={"Роль жителя"}
				contentContainerStyle={{ height: "calc(80vh)", overflowY: "auto" }}
				onExit={() => setOpen(false)}
				footerSlot={
					<FooterButtonWrapper>
						<Button onClick={() => setOpen(false)} typeButton="secondary">
							Отменить
						</Button>
						<Button onClick={onSave}>Сохранить</Button>
					</FooterButtonWrapper>
				}>
				<SpaceWrapper direction="vertical">
					<UserEditInput
						disabled={true}
						required
						as="input"
						// placeholder={v.placeholder}
						label={"Адрес"}
						value={currentSelectedBuildingAddress}
						onChange={(value) => undefined}
					/>
					<FormItem label="Роль жителя в недвижимости" required>
						<Select
							onClickSelect={() => {
								setOpenSelect(true);
							}}
							onClickSelectItem={(values) => {
								// CitizensDrawerStore.changeUserInfo({ key: "roleId", value: values.key }); // TODO
								setSelectedRoles(
									selectedRoles.map((v) => ({
										...v,
										focused: values.key === v.key,
									})),
								);
								setOpenSelect(false);
							}}
							items={selectedRoles}
							style={{ width: "100%" }}
							isOpened={openSelect}
							placeholder="-"
							onClose={() => {
								setOpenSelect(false);
							}}
						/>
					</FormItem>
					<FormItem label="Тип документа" required>
						<Select
							onClickSelect={() => {
								setOpenSelectDocument(true);
							}}
							onClickSelectItem={onDocumentSelect}
							items={selectedDocumentType}
							style={{ width: "100%" }}
							isOpened={openSelectDocument}
							placeholder="-"
							onClose={() => {
								setOpenSelectDocument(false);
							}}
						/>
					</FormItem>
					{inputsValue.map((v) => {
						const isDate = v.type.toLowerCase().indexOf("date") !== -1;

						if (isDate) {
							return (
								<UserEditDate
									label={v.label}
									required
									value={v.value as dayjs.Dayjs}
									placeholder="01.01.2000"
									onChange={(value) => setInputValue(v.type, value)}
									disabled={!currentSelectedDocumentType}
								/>
							);
						}

						return (
							<UserEditInput
								disabled={!currentSelectedDocumentType}
								required
								as="input"
								placeholder={v.placeholder}
								label={v.label}
								value={v.value as string}
								onChange={(value) => setInputValue(v.type, value)}
								mask={getDocumentMask(v.type as TInputType, currentSelectedDocumentType?.key as TCitizenBuildingDocumentType)}
							/>
						);
					})}
					<CitizenFiles
						title="Изображение  документа"
						newFiles={newFiles}
						setNewFiles={(files: File[]) => {
							setNewFiles(files);
						}}
						disabled={false}
					/>
				</SpaceWrapper>
			</BaseModal>
		</>
	);
};
const DividerWrapper = styled(CitizenRealtyCreateDivider)`
	margin-top: 20px;
`;

const SpaceWrapper = styled(Space)`
	width: 100%;
`;

export default observer(CitizenRoleModal);
