import React, { FC } from "react";

import styled from "styled-components";
import { Button, Space, Text } from "tap2visit-ui-kit";

import { FormItemsContainer } from "components/DrawerFormFields/DrawerFormFields";
import { IClientContact, ICreateClientContacts, TContactType } from "interfaces/IClient";
import { ReactComponent as EmailIcon } from "public/Email.svg";
import { ReactComponent as PhoneIcon } from "public/Phone.svg";
import { ReactComponent as TrashIcon } from "public/Trash.svg";

import {
	CitizenRealtyWrapper,
	CitizenRealtyTitleAppLogin,
	CitizenRealtyDivider,
} from "../../../../components/Citizens/style/Citizen.styled";
import formatPhoneNumber from "../../../../utils/format/formatPhone";
import CitizenAddButton from "../CitizenAddButton";
import { CitizenDrawerTextHeaderBlock } from "../CitizenTexts";

import CitizenContactModal, { TCitizenContactModalOnSave } from "./CitizenContact.modal";

interface ICitizenContact {
	title: string;
	contactsCollection: (IClientContact | ICreateClientContacts)[];
	onSave: TCitizenContactModalOnSave;
	onDeleteContact: (value: string) => void;

	modalTitle: string;
	modalTitlePlaceholder: string;
	modalTitleLabel: string;
	modalDescriptionPlaceholder: string;
	modalTitleMask?: string;

	buttonText: string;

	type: TContactType;

	isCreateNewCitizen?: boolean; // ? when create a new citizen
	isOpen: boolean;
	setIsOpen: (v: boolean) => void;
}

const CitizenContact: FC<ICitizenContact> = (props) => {
	const { isOpen, setIsOpen } = props;
	return (
		<Space size={8} direction="column" align="start">
			{isOpen && (
				<CitizenContactModal
					type={props.type}
					setIsOpen={setIsOpen}
					isOpen={isOpen}
					onSave={props.onSave}
					title={props.modalTitle}
					titlePlaceholder={props.modalTitlePlaceholder}
					titleLabel={props.modalTitleLabel}
					titleMask={props.modalTitleMask}
					descriptionPlaceholder={props.modalDescriptionPlaceholder}
				/>
			)}
			<CitizenDrawerTextHeaderBlock text={props.title} />

			<CitizenContactContainer isCreateNewCitizen={props.isCreateNewCitizen}>
				<CitizenRealtyWrapper>
					{props.contactsCollection.map((v) => (
						<>
							<ContactWrapper>
								<Space width="100%" justify="space-between">
									<Space size={8}>
										{props.type === "PHONE" && <PhoneIcon />}
										{props.type === "EMAIL" && <EmailIcon />}
										<Space align="start" size={1} direction="column">
											<Text type="table-cell-l-medium">{formatPhoneNumber(v.value)}</Text>
											<TextStyled color="rgba(18, 18, 18, 0.6)" type="table-cell-m-regular">
												{v.description}
											</TextStyled>
										</Space>
									</Space>
									<IconContainer>
										<Button
											typeButton="text"
											shape="square"
											icon={TrashIcon}
											onClick={() => props.onDeleteContact((v as IClientContact).id)}
										/>
									</IconContainer>
								</Space>
							</ContactWrapper>
							<CitizenRealtyDivider />
						</>
					))}

					<CitizenAddButton onClick={() => setIsOpen(true)} text={props.buttonText} />
				</CitizenRealtyWrapper>
			</CitizenContactContainer>
		</Space>
	);
};

const CitizenContactContainer: FC<{ isCreateNewCitizen: boolean; children: React.ReactNode }> = (props) =>
	props.isCreateNewCitizen ? <FormItemsContainer style={{ width: "100%" }}>{props.children}</FormItemsContainer> : <>{props.children}</>;

export default CitizenContact;

const IconContainer = styled.div`
	cursor: pointer;
`;

const TextStyled = styled(Text)`
	width: 550px;
	word-break: break-all;
`;
const ContactWrapper = styled.div`
	padding: 12px;
`;
