import dayjs from "dayjs";
import { IClient } from "interfaces/IClient";
import { colors } from "tap2visit-ui-kit";

export default (currentUserData: Pick<IClient, "lastActivity" | "registration">) => {
	if (!currentUserData.registration) {
		return { color: colors.iconNeutralDisabled, hint: "Нет мобильного приложения" };
	}

	if (currentUserData.lastActivity === null) {
		return { color: colors.iconNeutralDefault, hint: "Приглашение отправлено" };
	}

	const lastActivityDaysAgo = dayjs().diff(dayjs(currentUserData.lastActivity), "days");

	if (lastActivityDaysAgo < 7) {
		return { color: colors.iconSuccessDefault, hint: "Заходил(а) недавно" };
	}

	if (lastActivityDaysAgo < 30) {
		return { color: colors.iconWarningDefault, hint: "Заходил(а) давно" };
	}

	return { color: colors.iconDangerDefault, hint: "Заходил(а) очень давно" };
};
