import React, { FC } from "react";

import { Collapse as CollapseCmp, CollapseProps } from "antd";
import { ReactComponent as ArrowRightSvg } from "public/arrowRight.svg";
import styled, { keyframes } from "styled-components";
import { colors } from "tap2visit-ui-kit";

type TCollapse = CollapseProps;

const Collapse: FC<TCollapse> = (props) => (
	<CustomCollapseCmp
		style={{ background: colors.surfaceNeutralBgWhite }}
		{...props}
		expandIcon={({ isActive }) => <RotatingArrowRightSvg isActive={isActive} />}
	/>
);

export default Collapse;

const rotateDown = keyframes`
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(90deg);
	}
`;

const rotateUp = keyframes`
	from {
		transform: rotate(90deg);
	}
	to {
		transform: rotate(0deg);
	}
`;

const RotatingArrowRightSvg = styled(ArrowRightSvg)<{ isActive: boolean }>`
	transform: rotate(${({ isActive }) => (isActive ? 90 : 0)}deg);
	animation: ${({ isActive }) => (isActive ? rotateDown : rotateUp)} 0.3s ease-in-out;
`;

const CustomCollapseCmp = styled(CollapseCmp)`
	.ant-collapse-content-box {
		background: ${colors.surfaceNeutralBg2};
	}
	.ant-collapse-expand-icon {
		padding-inline-end: 8px !important;
	}
`;
