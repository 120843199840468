import {
	IFileListData,
	IGetFileListChatArgs,
	IGetMessagesListArgs,
	IMessageContent,
	IMessageListData,
	IPostMessage,
	IPostMessageView,
} from "interfaces/IChat";

import apiClient from "./api.client";

export type TGetMessagesList = (args: { params: IGetMessagesListArgs }) => Promise<IMessageListData>;
export type TPostMessage = (args: IPostMessage) => Promise<IMessageContent>;
export type TDeleteMessage = (args: { id: string }) => Promise<IMessageContent>;
export type TChangeMessage = (args: { id: string; text: string }) => Promise<IMessageContent>;
export type TGetFileListChat = (args: { params: IGetFileListChatArgs }) => Promise<IFileListData>;
export type TPostMessageView = (args: IPostMessageView) => Promise<Record<string, never>>;

export const getMessagesList: TGetMessagesList = async (args) => {
	try {
		const res = await apiClient.get("chats/v1/messages/page", {
			params: args.params,
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const postMessageView: TPostMessageView = async (args) => {
	try {
		const res = await apiClient.post(`chats/v1/messages/view?chatId=${args.chatId}`, {
			params: {
				chatId: args.chatId,
			},
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const createMessage: TPostMessage = async (args) => {
	try {
		const res = await apiClient.post("chats/v1/messages", {
			...args,
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const deleteMessage: TDeleteMessage = async (args) => {
	try {
		const res = await apiClient.delete(`chats/v1/messages/${args.id}`);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const changeMessagePatch: TChangeMessage = async (args) => {
	try {
		const res = await apiClient.patch(`chats/v1/messages/${args.id}`, {
			text: args.text,
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getFilesListChat: TGetFileListChat = async (args) => {
	try {
		const res = await apiClient.get("chats/v1/files/page", {
			...args,
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};
