import React, { useState } from "react";

import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { OutlineArrowDown2, OutlineArrowRight2 } from "t2v-icons";
import { Text, colors } from "tap2visit-ui-kit";

import { IAppeal } from "interfaces/IAppeals";

import AppealCloseApplicant from "./AppealCloseApplicant";

const AppealCloseChild = ({ value }: { value: IAppeal }) => {
	const [isOpen, setIsOpen] = useState(false);

	return (
		<div>
			<AppealCloseChildWrapper onClick={() => setIsOpen(!isOpen)}>
				<div>
					<AppealText type="table-cell-m-regular" color={colors.textNeutralSecondary}>
						{dayjs(value.createdAt).format("DD.MM.YYYY")}
					</AppealText>
					<AppealText type="table-cell-l-medium" color={colors.textNeutralPrimary}>
						№{value.number} {value.title}
					</AppealText>
					<AppealText type="table-cell-m-regular" color={colors.textNeutralSecondary}>
						{value.buildingObjectAddress?.addressValue}
					</AppealText>
				</div>
				{isOpen ? <OutlineArrowDown2 fill={colors.iconNeutralDefault} /> : <OutlineArrowRight2 fill={colors.iconNeutralDefault} />}
			</AppealCloseChildWrapper>
			{isOpen && (
				<div style={{ marginLeft: "37px" }}>
					<AppealCloseApplicant applicant={value.applicant} />
				</div>
			)}
		</div>
	);
};

const AppealCloseChildWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	border-bottom: 1px solid #12121229;
	padding: 8px 12px;
	cursor: pointer;
`;

const AppealText = styled(Text)`
	width: 420px;
	overflow: hidden;
	text-overflow: ellipsis;
	text-wrap: nowrap;
`;
export default observer(AppealCloseChild);
