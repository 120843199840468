const TYPE_DATA = [
	{
		focused: false,
		key: "REAL_ESTATE",
		text: "Недвижимость",
	},
	{
		focused: false,
		key: "OPEN_ACCOUNTS",
		text: "Открытые Л/C",
	},
	{
		focused: false,
		key: "DEBTORS",
		text: "Должники",
	},
];

export { TYPE_DATA };
