import { FormattedMessage } from "react-intl";

import TextHeader from "components/TextHeader";

import ChoiceYKList from "./ChoiceUKList";

const ChoiceYK = () => (
	<>
		<TextHeader>
			<FormattedMessage id="selectYK" />
		</TextHeader>
		<ChoiceYKList />
	</>
);

export default ChoiceYK;
