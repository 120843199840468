export const mainSectionsHeader = [
	{
		key: "ACTIVE",
		label: "Действующие обращения",
	},
	{
		key: "ARCHIVE",
		label: "Архив обращений",
	},
];
