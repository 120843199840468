import React from "react";

import TextHeader from "../../components/TextHeader";

const BuildingsHeader = () => (
	<>
		<TextHeader>Жилые комплексы</TextHeader>
	</>
);

export default BuildingsHeader;
