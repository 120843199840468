export const STATUS = {
	NEW: { text: "Новое", color: "blue" },
	IN_PROGRESS: { text: "В процессе", color: "orange" },
	PARTIALLY_PROCESSED: { text: "Частично обработанный", color: "yellow" },
	COMPLETED: { text: "Загружено", color: "green" },
	FAILED: { text: "Ошибка", color: "red" },
};

export const IMPORT_TYPE = {
	METERING_DEVICES: "Данные счетчиков (Измерительные приборы)",
	REAL_ESTATE: "Недвижимость",
	OPEN_ACCOUNTS: "Открытые Л/С",
	DEBTORS: "Должники",
};

export const IMPORT_TYPE_FILTERS = [
	{ text: "Недвижимость", key: "REAL_ESTATE" },
	{ text: "Открытые лицевые счета", key: "OPEN_ACCOUNTS" },
	{ text: "Должники", key: "DEBTORS" },
];

export const MAX_FILE_SIZE_MB = 20;
