export const buildingsFooterItems = [
	{
		title: "Без долгов",
		background: "#5ABA91",
	},
	{
		title: "Долг",
		background: "#CA504A",
	},
	{
		title: "Нет собственника",
		background: "white",
		isBordered: true,
	},
];
