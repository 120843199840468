import React, { FC, useEffect, useState } from "react";

import { useMutation } from "react-query";
import styled from "styled-components";
import { Drawer, FormItem, Select, showToast } from "tap2visit-ui-kit";

import Input from "components/ui/Input/Input";

import { createOrGetAliasApi, searchSuggestAddressApi } from "../../../api/api.aliases";
import { IAliasCreate } from "../../../interfaces/IGeocoder";
import ProfileUKVisibilityStore from "../profileUKVisibility.store";
import DialogAreYouSureCancelEditOfficeAddressStore from "../store/DialogAreYouSureCancelEditOfficeAddress.store";

import PageEditDialogAreYouSureCancelEditOfficeAddress from "./PageInfo.DialogAreYouSureCancelEditOfficeAddress";

interface IModalAddNewAddress {
	open: boolean;
	setOpen: (open: boolean) => void;
}

const inputItems = [
	{ label: "Страна", value: "Россия" },
	{ label: "Регион", value: "" },
	{ label: "Район", value: "" },
	{ label: "Город", value: "" },
	{ label: "Район города", value: "" },
	{ label: "Населенный пункт", value: "" },
	{ label: "Улица", value: "" },
	{ label: "Дом", value: "" },
	{ label: "Квартира или офис", value: "" },
];
const ModalAddNewAddress: FC<IModalAddNewAddress> = (props) => {
	const [isOpenSelect, setIsOpenSelect] = useState(false);
	const [inputItemsState, setInputItemsState] = useState(inputItems);
	const [adressList, setAdressList] = useState([]);
	const [valueInput, setValueInput] = useState("");
	const [delayedValue, setDelayedValue] = useState("");

	const closeDrawer = () => {
		if (JSON.stringify(inputItems) !== JSON.stringify(inputItemsState)) {
			DialogAreYouSureCancelEditOfficeAddressStore.setIsVisible(true);
		} else {
			props.setOpen(false);
		}
	};

	const createOrGetAlias = useMutation({
		mutationFn: (args: IAliasCreate) => createOrGetAliasApi({ body: args }),
	});

	const searchAddress = useMutation({
		mutationFn: (address: string) => searchSuggestAddressApi({ params: { query: address } }),
	});

	useEffect(() => {
		const handler = setTimeout(() => {
			setDelayedValue(valueInput);
		}, 20000);

		return () => {
			clearTimeout(handler);
		};
	}, [valueInput]);

	useEffect(() => {
		(async () => {
			const res = await searchAddress.mutateAsync(delayedValue.length > 2 ? delayedValue : null);
			setAdressList(
				res.map((v) => ({
					text: v.value,
					focused: false,
					key: v.id,
				})),
			);
		})();
	}, [delayedValue]);

	useEffect(() => {
		setValueInput(valueInput);
		if (adressList.find((v) => v.focused)) {
			setValueInput(adressList.find((v) => v.focused).text);
		}
	}, [adressList]);

	const createAlias = async () => {
		const details = {
			country: inputItemsState.find((v) => v.label === "Страна").value,
			region: inputItemsState.find((v) => v.label === "Регион").value,
			area: inputItemsState.find((v) => v.label === "Район").value,
			cityDistrict: inputItemsState.find((v) => v.label === "Район города").value,
			city: inputItemsState.find((v) => v.label === "Город").value,
			settlement: inputItemsState.find((v) => v.label === "Населенный пункт").value,
			street: inputItemsState.find((v) => v.label === "Улица").value,
			buildingNumber: inputItemsState.find((v) => v.label === "Дом").value,
			block: "", //TODO добавить на фигме
			flat: inputItemsState.find((v) => v.label === "Квартира или офис").value,
			isOffice: true,
		};

		const res = await createOrGetAlias.mutateAsync({ value: "", details, addressId: adressList.find((v) => v.focused).key || "" });
		if (res) {
			showToast({
				type: "success",
				description: "Адрес успешно создан!",
			});
			ProfileUKVisibilityStore.setNewAddressId(res.addressId);
			props.setOpen(false);
			closeDrawer();
		}
	};

	return (
		<>
			<Drawer
				title="Адрес офиса"
				visible={props.open}
				onClose={closeDrawer}
				onCancel={closeDrawer}
				onOk={createAlias}
				disableOk={false}
				width="560px"
				textCancel={"Отменить"}
				textOk="Сохранить">
				<DrawerWrapper>
					<FormItem label="Ближайший доступный адрес">
						<Select
							items={adressList}
							onClickSelect={() => setIsOpenSelect(true)}
							isOpened={isOpenSelect}
							placeholder="Выберите"
							tag={false}
							style={{ minWidth: "100%", fontSize: "16px" }}
							onClickSelectItem={(item) => {
								setAdressList(
									adressList.map((v) => ({
										...v,
										focused: item.key === v.key,
									})),
								);
								setIsOpenSelect(false);
							}}
							onClose={() => setIsOpenSelect(false)}
						/>
					</FormItem>
					{inputItemsState.map(({ label, value }) => (
						<FormItem label={label}>
							<InputStyled
								onChangeValue={(value) =>
									setInputItemsState(inputItemsState.map((v) => ({ ...v, value: label === v.label ? value : v.value })))
								}
								sizeInput="medium"
								value={value}
							/>
						</FormItem>
					))}
				</DrawerWrapper>
			</Drawer>
			<PageEditDialogAreYouSureCancelEditOfficeAddress onContinue={() => props.setOpen(false)} />
		</>
	);
};

const InputStyled = styled(Input)`
	width: 100%;
	font-size: 16px;
`;
const DrawerWrapper = styled.div`
	gap: 10px;
	display: flex;
	flex-direction: column;
`;
export default ModalAddNewAddress;
