import { FC } from "react";

import { Pie, PieChart, Tooltip, Cell } from "recharts";
import styled from "styled-components";

import { IQuestionAnswerDto } from "interfaces/IVote";

import PollsEditStore from "../../../store/PollsItem.store";
import ChartDescription from "../ChartDescription/ChartDescription";
import ChartEmpty from "../ChartEmpty/ChartEmpty";
import { getAnswerText } from "../utils/getAnswerText";

import CustomLegend from "./components/CustomLegend";
import { getAnswers } from "./utils/getAnswers";
import getOnlyIconColorsList from "./utils/getOnlyIconColorsList";

interface ICircleChart {
	question: IQuestionAnswerDto;
}

const COLORS = getOnlyIconColorsList();
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
	const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
	const x = cx + radius * Math.cos(-midAngle * RADIAN);
	const y = cy + radius * Math.sin(-midAngle * RADIAN);

	return (
		<text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central">
			{`${(percent * 100).toFixed(0)}%`}
		</text>
	);
};

const CircleChart: FC<ICircleChart> = (props) => {
	const { answersCount, answersChartData } = getAnswers(props.question);
	return (
		<>
			<ChartDescription title={PollsEditStore.poll?.questions[0]?.value} description={`${answersCount} ${getAnswerText(answersCount)}`} />

			{answersCount === 0 && <ChartEmpty />}

			{answersCount !== 0 && (
				<Container>
					<PieChart width={250} height={250}>
						<Pie
							style={{ outline: "none" }}
							data={answersChartData}
							cx="50%"
							cy="40%"
							labelLine={false}
							label={renderCustomizedLabel}
							outerRadius={100}
							fill="#8884d8"
							dataKey="value">
							{answersChartData.map((entry, index) => (
								<Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
							))}
						</Pie>
						<Tooltip />
					</PieChart>
					<CustomLegend payload={answersChartData.map((entry, index) => ({ value: entry.name, color: COLORS[index % COLORS.length] }))} />
				</Container>
			)}
		</>
	);
};

export default CircleChart;

const Container = styled.div`
	display: flex;
`;
