import useTablePaginationLogic from "hooks/useTablePaginationLogic";
import React, { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import { Pagination, Table } from "tap2visit-ui-kit";

interface IAnswer {
	value: string;
}

interface IAnswersData {
	content: IAnswer[];
	totalPages: number;
	page: number;
	size: number;
	totalElements: number;
}

interface IAnswersTableProps {
	answersData: IAnswersData;
	paginationData: {
		clientsSize: number;
		clientsTotal: number;
		clientsCurrentPage: number;
		setClientsCurrentPage: Dispatch<SetStateAction<number>>;
		setClientsSize: (size: number) => void;
		setClientsTotal: Dispatch<SetStateAction<number>>;
	};
}

const AnswersTable: React.FC<IAnswersTableProps> = ({ answersData, paginationData }) => {
	const columns = [
		{
			title: "Ответ",
			dataIndex: "value",
			key: "value",
			render: (text: string) => <TextCell>{text}</TextCell>,
		},
	];

	return (
		<>
			<Table style={{ wordWrap: "break-word" }} isFullWidth columns={columns} data={answersData?.content} size="l" />
			<PaginationContainer>
				<Pagination
					setCurrentPage={paginationData.setClientsCurrentPage}
					currentPage={paginationData.clientsCurrentPage}
					total={paginationData.clientsTotal}
					size={paginationData.clientsSize as 10 | 20 | 50 | 100}
					onChangeSize={paginationData.setClientsSize}
				/>
			</PaginationContainer>
		</>
	);
};

const TextCell = styled.div`
	white-space: normal;
	word-break: break-word;
`;

const PaginationContainer = styled.div`
	display: flex;
	justify-content: center;
	padding-right: 10px;
	padding-bottom: 20px;
	padding-top: 30px;
`;

export default AnswersTable;
