import React from "react";

import dayjs from "dayjs";
import { useQuery } from "react-query";
import type { TTableColumn } from "tap2visit-ui-kit";

import { getCountersPageApi } from "api/api.gateway";
import { useTableFilterContext } from "components/TableFilter/TableFilter.context";
import useTablePaginationLogic from "hooks/useTablePaginationLogic";
import type { TService } from "interfaces/ICounter";
import type { TSortingDirection } from "interfaces/ISorting";
import { QueriesKeys } from "interfaces/queriesKeys";
import { joinArrParams } from "utils/joinParams";

import { getType, serviceTypeArray } from "./getType";

const useTableData = () => {
	const paginationData = useTablePaginationLogic();
	const tableFilterContext = useTableFilterContext();

	const [accountNumberSearch, setAccountNumberSearch] = React.useState("");
	const [meteringDeviceCodeSearch, setMeteringDeviceCodeSearch] = React.useState("");
	const [numberSearch, setNumberSearch] = React.useState("");
	const [filteredTypes, setFilteredTypes] = React.useState<any[]>([]);
	const [sortedBy, setSortedBy] = React.useState<{ [key: string]: TSortingDirection }>({});

	const handleSortChange = (key: string, direction: TSortingDirection | undefined) => {
		setSortedBy((prevState) => {
			const newState = { ...prevState };
			if (direction) {
				newState[key] = direction;
			} else {
				delete newState[key];
			}
			return newState;
		});
	};

	const counters = useQuery({
		queryFn: () => {
			const sort = Object.entries(sortedBy).map(([key, direction]) => ({ key, direction }));

			return getCountersPageApi({
				params: {
					complexIds: tableFilterContext?.selectedComplexIds,
					buildingIds: tableFilterContext?.selectedBuildingsIds,
					entranceIds: tableFilterContext?.selectedEntrancesIds,
					floorIds: tableFilterContext?.selectedFloorsIds,
					buildingObjectIds: tableFilterContext?.selectedBuildingObjectIds,
					page: paginationData.clientsCurrentPage - 1,
					size: paginationData.clientsSize,
					sort: sort,
					accountNumberSearch,
					meteringDeviceCodeSearch,
					serviceType: joinArrParams(filteredTypes) as TService,
					meteringDeviceSerialNumberSearch: numberSearch,
				},
			});
		},
		queryKey: [
			QueriesKeys.payments,
			tableFilterContext.selectedComplexIds,
			tableFilterContext.selectedBuildingsIds,
			tableFilterContext.selectedEntrancesIds,
			tableFilterContext.selectedFloorsIds,
			tableFilterContext.selectedBuildingObjectIds,
			paginationData.clientsSize,
			paginationData.clientsCurrentPage,
			accountNumberSearch,
			meteringDeviceCodeSearch,
			sortedBy,
			filteredTypes,
			numberSearch,
		],
	});

	React.useEffect(() => {
		if (counters.data) {
			paginationData.setClientsTotal(counters.data.totalPages);
		}
	}, [counters.data?.totalPages]);

	const columns: TTableColumn[] = [
		{
			dataIndex: "adress",
			title: "Адрес",
			key: "adress",
			columnCellStyles: {
				minWidth: "150px",
			},
		},
		{
			dataIndex: "personalAccount",
			title: "Лицевой счет",
			key: "personalAccount",
			onSearch: (search) => {
				setAccountNumberSearch(search);
				paginationData.setClientsCurrentPage(0);
				paginationData.setClientsSize(counters.data.size);
			},
			onSortedBy: (direction) => handleSortChange("accounts.number", direction),
			columnCellStyles: {
				minWidth: "120px",
			},
		},
		{
			dataIndex: "code",
			title: "Код счетчика",
			key: "code",
			onSearch: (search) => {
				setMeteringDeviceCodeSearch(search);
				paginationData.setClientsCurrentPage(0);
				paginationData.setClientsSize(counters.data.size);
			},
			onSortedBy: (direction) => handleSortChange("meteringDeviceCode", direction),
			columnCellStyles: {
				minWidth: "120px",
			},
		},
		{
			dataIndex: "date",
			title: "Дата след. поверки",
			key: "date",
			columnCellStyles: {
				minWidth: "100px",
			},
		},
		{
			dataIndex: "type",
			title: "Вид услуги",
			key: "type",
			filters: serviceTypeArray,
			onChangeFilter: (data) => setFilteredTypes(data.map((r) => r.key)),
			columnCellStyles: {
				minWidth: "150px",
			},
		},
		{
			dataIndex: "number",
			title: "Серийный номер",
			key: "number",
			onSearch: (search) => {
				setNumberSearch(search);
				paginationData.setClientsCurrentPage(0);
				paginationData.setClientsSize(counters.data.size);
			},
			columnCellStyles: {
				minWidth: "100px",
			},
		},
		{
			dataIndex: "previousReadings",
			title: "Предыдущие показания",
			key: "previousReadings",
			columnCellStyles: {
				minWidth: "100px",
			},
			onSortedBy: (direction) => handleSortChange("previousValue", direction),
		},
		{
			dataIndex: "currentReadings",
			title: "Текущие показания",
			key: "currentReadings",
			columnCellStyles: {
				minWidth: "100px",
			},
			onSortedBy: (direction) => handleSortChange("value", direction),
		},
		{
			dataIndex: "consumption",
			title: "Расход",
			key: "consumption",
			columnCellStyles: {
				minWidth: "100px",
			},
			onSortedBy: (direction) => handleSortChange("consumption", direction),
		},
	];

	const data =
		counters.data?.content?.map((counter) => ({
			adress: counter.accounts[0]?.buildingObjectAddresses[0]?.addressValue,
			code: counter.meteringDeviceCode,
			number: counter.meteringDeviceSerialNumber,
			date: dayjs(counter.nextVerificationDate).format("DD.MM.YYYY"),
			currentReadings: counter.value,
			previousReadings: counter.previousValue,
			consumption: counter.consumption,
			type: getType(counter.serviceType),
			personalAccount: counter.accounts[0].number,
		})) ?? [];

	return {
		data,
		columns,
		isFetching: counters.isFetching,
		paginationData,
	};
};

export default useTableData;
