import React from "react";

import { Breadcrumbs } from "tap2visit-ui-kit";

const BuildingsHouseHeader = () => (
	<Breadcrumbs
		location="/buildings/house"
		crumbs={[
			{ path: "/buildings", title: "Жилые комплексы" },
			{ path: "/buildings/house", title: "Дома" },
		]}
	/>
);

export default BuildingsHouseHeader;
