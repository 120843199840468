import React, { FC } from "react";

import { observer } from "mobx-react-lite";

import PollsDetail from "../components/PollsDetail/PollsDetail";
import useRedirect from "../hooks/useRedirect";
import PollsEditValidationStore from "../store/PollsEdit.validation.store";
import PollsEditTabsStore from "../store/PollsItem.tabs.store";
import { Center } from "../styles/common";

interface IPollsEditTemplate {}

const PollsCreateTemplate: FC<IPollsEditTemplate> = (props) => {
	React.useEffect(
		() => () => {
			PollsEditTabsStore.dispose();
			PollsEditValidationStore.dispose();
		},
		[],
	);

	useRedirect();

	return (
		<Center>
			<PollsDetail editable={true} />
		</Center>
	);
};

export default observer(PollsCreateTemplate);
