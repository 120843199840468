import dayjs from "dayjs";

import isToday from "utils/isToday";
import isYesterday from "utils/isYesterday";

export default (date: string) => {
	const now = dayjs();
	const dateOfComment = dayjs(date);

	const isCurrentYear = dateOfComment.isSame(now, "year");
	const time = dateOfComment.format("HH:mm");

	if (isToday(date)) {
		return `Сегодня ${time}`;
	} else if (isYesterday(date)) {
		return `Вчера ${time}`;
	} else if (isCurrentYear) {
		return `${dateOfComment.format("DD MMM")} ${time}`;
	}
	return `${dateOfComment.format("DD MMM YYYY")} в ${time}`;
};
