import ValidationStore, { ValidationStatuses } from "modules/validation/Validation.store";

export type TCitizenPhoneValidationFields = {
	comment: keyof typeof ValidationStatuses;
	phone: keyof typeof ValidationStatuses;
};

const initialValidationData: TCitizenPhoneValidationFields = {
	comment: ValidationStatuses.VALID,
	phone: ValidationStatuses.VALID,
};

const COMMON_REQUIRED_ERROR_MESSAGE = "Обязательно к заполнению";

export const ERROR_MESSAGES: Record<keyof TCitizenPhoneValidationFields, Partial<Record<keyof typeof ValidationStatuses, string>>> = {
	comment: { REQUIRED: COMMON_REQUIRED_ERROR_MESSAGE, LESS_THEN: "Поле не должно содержать более 100 символов" },
	phone: { REQUIRED: COMMON_REQUIRED_ERROR_MESSAGE, EQUALS: "Телефон некорректен" },
};

const checkRules = {
	comment: {
		[ValidationStatuses.REQUIRED]: true,
		[ValidationStatuses.LESS_THEN]: 100,
	},
	phone: {
		[ValidationStatuses.REQUIRED]: true,
		[ValidationStatuses.EQUALS]: 11,
	},
};

const CitizenPhoneStore = new ValidationStore<TCitizenPhoneValidationFields>(initialValidationData, ERROR_MESSAGES, checkRules);

export default CitizenPhoneStore;
