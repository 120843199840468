import React, { useEffect, useMemo } from "react";

import dayjs from "dayjs";
import { useQuery } from "react-query";
import styled from "styled-components";
import { Drawer, Loader, Text } from "tap2visit-ui-kit";

import { getListFilesChat } from "api/api.gateway";
import { queryClient } from "index";
import { IFileArray, IFileContent } from "interfaces/IChat";
import { QueriesKeys } from "interfaces/queriesKeys";

import { getUpperCaseText } from "../utils/getUpperCaseText";

import ChatFileInformation from "./ChatFileInformation";

interface IChatFileDrawer {
	open: boolean;
	setOpen: (v: boolean) => void;
	id?: string;
}
const ChatFileDrawer = ({ open, setOpen, id }: IChatFileDrawer) => {
	const file = useQuery({
		queryFn: () => getListFilesChat({ params: { chatId: id } }),
		enabled: !!id,
		queryKey: [QueriesKeys.fileChatInformation, id],
	});
	useEffect(() => {
		if (id || open) {
			queryClient.invalidateQueries([QueriesKeys.fileListChat]);
		}
	}, [id, open]);

	const fileArray = useMemo(() => {
		if (!file.data) return file.data?.content;

		const dataArray = file.data?.content?.reduce((acc, message) => {
			const date = dayjs(message.messageTime).format("MM");

			if (acc[date]) {
				acc[date].content.push(message);
			} else {
				acc[date] = {
					time: dayjs(message.messageTime).format("MMMM"),
					content: [message],
				};
			}
			return acc;
		}, {});
		return Object.values(dataArray);
	}, [file.data]);

	if (file.isLoading || file.isFetching)
		return (
			<LoaderWrapper>
				<Loader />
			</LoaderWrapper>
		);
	return (
		<Drawer width="440px" onClose={() => setOpen(false)} title="Файлы" showCancel={false} showOk={false} visible={open}>
			{fileArray.map((val: IFileArray) => (
				<>
					<Text h="h4">{getUpperCaseText(val.time)}</Text>
					{val.content.map((v: IFileContent) => (
						<ChatFileInformation messageTime={v.messageTime} isFileDrawer fileId={v.id} />
					))}
				</>
			))}
		</Drawer>
	);
};
const LoaderWrapper = styled.div`
	position: absolute;
	margin-left: auto;
	margin-right: auto;
	left: 65%;
	right: 90%;
	top: 50%;
	text-align: center;
`;
export default ChatFileDrawer;
