import { useEffect } from "react";

import { Divider as CustomDivider } from "antd";
import styled from "styled-components";
import { Badge, Text } from "tap2visit-ui-kit";

import { ReactComponent as UnCheckSvg } from "public/uncheckCircle.svg";

import UpdateDataTabsStore from "../store/UpdateData.tabs.store";
import { Item, ItemWrapper, OrderedList, OrderedListItem } from "../UpdateData.styled";

import UpdateDataForm from "./UpdateData.form";

export const UpdateDataDownload = () => {
	useEffect(() => {
		if (UpdateDataTabsStore.getIsTransitionFromHistory()) {
			window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
		}
	}, []);

	return (
		<ItemWrapper>
			<Item>
				<Text h="h5" type="base-bold">
					Инструкция по работе с разделом “Обновление данных”
				</Text>
				<Text h="h5" type="base-bold" style={{ fontSize: "14px" }}>
					Как загрузить отчет, подраздел “Загрузка файла”:
				</Text>
				{/* <OrderedList> */}
				<OrderedListItem>Выгрузить отчет из системы “Аркус” &#8594; Сохранить отчет на рабочем компьютере.</OrderedListItem>
				<OrderedListItem>Выбрать Жилой Комплекс из списка доступного вашей Управляющей компании.</OrderedListItem>
				<OrderedListItem>
					Выбрать Адрес объекта недвижимости для которого будет обновление данных &#8594; Дом/ Литер/ Корпус.
				</OrderedListItem>
				<OrderedListItem>
					Выбрать “Тип данных”:
					<OrderedListItem>“Недвижимость” &#8594; аналог отчета Недвижимость из системы “Аркус”.</OrderedListItem>
					<OrderedListItem>“Открытые лицевые счета” &#8594; аналог отчета Открытые лицевые счета из системы “Аркус”.</OrderedListItem>
					<OrderedListItem>“Должники” &#8594; аналог отчета Должники из системы “Аркус”.</OrderedListItem>
				</OrderedListItem>
				<OrderedListItem>
					Следующий шаг, в область под “Файл” переместите или нажатием выберите требуемый файл для загрузки.
				</OrderedListItem>
				<OrderedListItem>
					Следующий шаг первичной проверки файла (отображение загрузки). При положительном результате проверки у вас появится знак
					подтверждения проверки, и следующие возможные действия :
					<OrderedListItem>
						”Подтвердить загрузку” &#8594; вы направляете файл на вторую проверку и итоговую загрузку в Хранилище данных;
					</OrderedListItem>
					<OrderedListItem>
						”Знак корзины” &#8594; дает возможность удалить файл, при понимании о не корректно загруженном файле в систему, при этом
						разблокирует возможность вносить корректировки в списках выбранных ранее. При отрицательном результе проверки у вас на экране в
						месте загружаемого файла будет подсвечиваться красным цветом с описанием ошибки:
					</OrderedListItem>
					<OrderedListItem>“Не корректный тип файла” &#8594; из системы “Аркус” загружаем только файлы формата xslx;</OrderedListItem>
					<OrderedListItem>
						“Не корректная Шапка файла” &#8594; из системы “Аркус” выгружается типизированный отчет, и если внесены изменения в наименовании
						столбцов в нашей системе будет отображаться как “Ошибка”;
					</OrderedListItem>
					<OrderedListItem>
						“Не корректный тип данных в файле” &#8594; наша система на данном этапе проверяет на наличие расхождений или отсутствие данных в
						файле.
					</OrderedListItem>
				</OrderedListItem>
				<OrderedListItem>
					Так же у вас будут открыты следующие действия:
					<OrderedListItem>
						“Знак обновить загрузку” &#8594; если вы уверены что ошибка вызвана сторонними факторами такими как плохое соединение с
						Интернетом или проблемой работы ПК.
					</OrderedListItem>
					<OrderedListItem>
						”Знак корзины” &#8594; дает возможность удалить файл, при понимании о не корректно загруженном файле в систему, при этом
						разблокирует возможность вносить корректировки в списках выбранных ранее.
					</OrderedListItem>
				</OrderedListItem>
				<OrderedListItem>
					Следующий шаг “Подтвердить загрузку” &#8594; вы направляете файл на вторую проверку и дальнейшую загрузку в Хранилище данных.{" "}
					<OrderedListItem>“Добавлен в статус загрузки” &#8594; при положительном результате отправки на загрузку фала:</OrderedListItem>
					<OrderedListItem>
						“Ошибка (Описание)” &#8594; при отрицательном результате отправки, возможно возникновение из за технических проблем на стороне
						нашего приложения.
					</OrderedListItem>
				</OrderedListItem>
				<Text type="base-bold">Подраздел “История”:</Text>
				<OrderedList style={{ fontWeight: 500, fontSize: "14px" }}>
					Данный раздел нужен для отображения информации по загрузки отчетов в Хранилище данных. Основные данные находящиеся в таблице:
				</OrderedList>
				<OrderedListItem>“Импорт” &#8594; Показывает “Тип данных”, загружаемых в Хранилище.</OrderedListItem>
				<OrderedListItem>
					“Статус” &#8594; Показывает статус загрузки данных. Возможные статусы:
					<OrderedListItem>“Загружено”</OrderedListItem>
					<OrderedListItem>“Обработка”</OrderedListItem>
					<OrderedListItem>“Ошибка”</OrderedListItem>
				</OrderedListItem>
				<OrderedListItem>“Дата и время” &#8594; Отображение времени загрузки файла.</OrderedListItem>
				<OrderedListItem>“Сотрудник” &#8594; ФИО сотрудника, производившего загрузку данных.</OrderedListItem>
				<Text type="base-bold">Расширенное описание статусов:</Text>
				<StatusContainer>
					<Badge text={"Загружено"} type="status" status="green" /> Файл корректно загружен в Хранилище данных.
				</StatusContainer>
				<Divider />
				<StatusContainer>
					<Badge text={"Обработка"} type="status" status="blue" /> Файл с данными находится в процессе обработки в связи с проверкой на ряд
					параметров перед загрузкой в Хранилище данных, сам процесс может занять некоторое время.
				</StatusContainer>
				<Divider />
				<StatusContainer>
					<Badge text={"Частично обработанный"} type="status" status="yellow" /> Файл частично загружен, так как появилась техническая
					ошибка со стороны сервиса. Проблема связана с технической частью со стороны нашего сервиса, файл до загрузиться в течении от 30
					минут до 2 часов. После чего файл автоматически до загрузится.
				</StatusContainer>
				<Divider />
				<ErrorContainer>
					<HeaderError>
						<UnCheckSvg /> Собственник не найден по ID
					</HeaderError>
					<Description>
						Ошибка связанная с некорректными данными по “Собственнику” в загружаемом файле. Просьба обратить внимание на корректность
						заполненных данных, или внести корректировки в данные файла.
					</Description>
				</ErrorContainer>
				<Divider />
				<ErrorContainer>
					<HeaderError>
						<UnCheckSvg /> Некорректное заполнение шапки файла
					</HeaderError>
					<Description>
						Ошибка связанная с наименованием столбцов в загружаемом файле, с нашей стороны производится первичная загрузка данных по
						ЖК(Домам/Литерам/Корпусам). Тем самым задается стандартный вид загружаемых данных на основе отчетов из “Аркуса”. В связи с этим
						при появлении данной ошибки просьба обратить внимание на наименование столбцов в файле.
					</Description>
				</ErrorContainer>
				<Divider />
				<ErrorContainer>
					<HeaderError>
						<UnCheckSvg /> Нет данных для импорта
					</HeaderError>
					<Description>
						Ошибка связанная с наличием или отсутствием не корректных данных в столбцах загружаемого файла, либо данные полностью
						отсутствуют в файле. В связи с этим при появлении данной ошибки просьба обратить внимание на данные в файле. И внести
						корректировки для повторной загрузки.
					</Description>
				</ErrorContainer>
				<Divider />
				<ErrorContainer>
					<HeaderError>
						<UnCheckSvg /> ЛС не найдены (кол-во)
					</HeaderError>
					<Description>
						Ошибка связанная с лицевыми счетам. При загрузке файла со своей стороны мы все лицевые счета проверяем, через “сервис лицевых
						счетов ГИС ЖКХ”.Сверяем по указанному вами адресу и по файлу загруженному вами в систему. Если есть различие даже по одному
						лицевому счету, появится ошибка этого типа. Просьба проверить лицевые счета в файле.
					</Description>
				</ErrorContainer>
				<Divider />
				<ErrorContainer>
					<HeaderError>
						<UnCheckSvg /> Количество ЛС некорректно
					</HeaderError>
					<Description>
						Ошибка связанная с количеством лицевых счетов в загружаемом файле. Мы проверяем по “сервису лицевых счетов ГИС ЖКХ” на
						количество счетов по указанному адресу. Если в результате будет ошибка, означает что лицевые счета совпали с указанным вами
						адресом, но не количество счетов. Так как есть УК в которых лицевые счета могут совпадать, но не их количество. Просьба
						проверить файл или загружаемые настройки.
					</Description>
				</ErrorContainer>
				<Divider />
				<ErrorContainer>
					<HeaderError>
						<UnCheckSvg /> Не существует строение, для которого загружаются данные
					</HeaderError>
					<Description>
						Ошибка связана с указанием не верного адреса в параметрах загрузки. Так как мы понимаем по данных к какому “Объекту управления”
						относятся данные, мы можем проверить через ГИС ЖКХ совпадения по ID. Либо с учетом загружаемых данных делаем вывод что данного
						строения не существует у нас в Хранилище данных. Просьба проверить параметры загрузки и загружаемый файл.
					</Description>
				</ErrorContainer>
				<Divider />
				<ErrorContainer>
					<HeaderError>
						<UnCheckSvg /> Лицевой счёт некорректен (кол-во)
					</HeaderError>
					<Description>
						Ошибка связанная с некорректными лицевыми счетами в загружаемом файле. Демонстрируем кол-во строк по которым лицевые счета
						некорректны относительно объекта недвижимости указанного для загрузкой. Просьба проверить и внести корректировки в файле и
						провести повторную загрузку.
					</Description>
				</ErrorContainer>
				<Divider />
				<ErrorContainer>
					<HeaderError>
						<UnCheckSvg /> Задолженность некорректна (кол-во)
					</HeaderError>
					<Description>
						Ошибка связанная с некорректными данными по загружаемому отчету. Демонстрируем кол-во строк по которым возникли ошибки. Просьба
						проверить и внести корректировки в загружаемом файле.
					</Description>
				</ErrorContainer>
				<Divider />
				<ErrorContainer>
					<HeaderError>
						<UnCheckSvg /> Объект недвижимости некорректен (кол-во)
					</HeaderError>
					<Description>
						Ошибка связанная с данными по объектам недвижимости загружаемыми в файле. Просьба проверить файл на наличие корректных данных,
						внести корректировки и повторно загрузить.
					</Description>
				</ErrorContainer>
				<Divider />
				<ErrorAttention>
					<Text type="base-bold">
						Важно ошибки могут возникать если после скачивания отчета из “Аркуса” вы дополнительно вносили изменения. Решение большей части
						ошибок может быть повторная выгрузка отчета и загрузка в систему ЛК УК
					</Text>
				</ErrorAttention>
			</Item>
			<Item style={{ height: "100%" }}>
				<UpdateDataForm />
			</Item>
		</ItemWrapper>
	);
};

const StatusContainer = styled.div`
	display: flex;
	gap: 4px;
	align-items: start;
	flex-direction: column;
	margin: 4px 0 12px 0;
`;

const ErrorContainer = styled.div`
	display: flex;
	align-items: start;
	justify-content: start;
	flex-direction: column;
	margin: 12px 0;
`;

const HeaderError = styled.div`
	display: flex;
	align-items: center;
	justify-content: start;
	gap: 8px;
`;

const Description = styled.div`
	margin-top: 8px;
`;

const ErrorAttention = styled.div`
	display: flex;
	align-items: center;
	justify-content: start;
	padding: 16px;
	margin-top: 5px;
	background-color: rgba(202, 80, 74, 0.1);
`;

const Divider = styled(CustomDivider)`
	margin: 0;
`;
