import { Divider, Space } from "antd";
import styled, { css } from "styled-components";

import { ReactComponent as DropdownIcon } from "public/dropdownIcon.svg";

export const CitizenRealtyTitleAppLogin = styled.p`
	font-size: 14px;
	font-weight: 600;
`;

export const CitizenRealtyDivider = styled(Divider)`
	margin: 0;
`;
export const CitizenRealtyWrapperFlex = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const CitizenWrapper = styled.div<{ isRealEstateWrapper?: boolean }>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: ${({ isRealEstateWrapper }) => (isRealEstateWrapper ? "pointer" : "unset")};
	padding: 12px;
`;

export const CitizenRealtyWrapperFlexDrawer = styled(CitizenRealtyWrapperFlex)`
	justify-content: start;
`;

export const CitizenRealtyFlex = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	padding: 12px;
`;

export const DropdownIconWrapper = styled(DropdownIcon)`
	margin-top: 5px;
	cursor: pointer;
`;
export const CitizenRealtyWrapperSpace = styled(Space)`
	margin-left: 10px;
`;
export const CitizenRealtyWrapper = styled.div`
	width: 100%;
	background: white;
	border-radius: 10px;
`;
export const CitizenRealtySpace = styled(Space)`
	width: 100%;
	margin-top: 10px;
`;
export const CitizenPersonalEditFooter = styled.div`
	display: flex;
	background: white;
	padding: 20px;
	justify-content: end;
	button:first-child {
		margin-right: 10px;
	}
`;
export const CitizenPersonalEdit = styled.div`
	padding: 20px;
`;
export const CitizenPersonalEditWrapper = styled.div<{ background?: string }>`
	background: #be98411f;
	border-radius: 10px;
	overflow: hidden;
	width: 100%;

	${(props) => {
		if (props.background) {
			return css`
				background: ${props.background};
			`;
		}
	}}
`;
export const PersonalInformationWrapper = styled.div`
	justify-content: space-between;
	align-items: center;
	display: flex;
	width: 100%;
`;

export const FooterButtonWrapper = styled(Space)`
	display: flex;
	justify-content: flex-end;
	width: 100%;
	margin-top: 20px;
	margin-left: -20px;
`;

export const FooterWrapper = styled.div`
	width: 100%;
`;

export const CitizenRealtyCreateDivider = styled(Divider)`
	margin: 2px 0;
`;

export const CitizenImgWrapper = styled.div<{ background?: string }>`
	background: ${(props) => props.background ?? "#1212120a"};
	padding: 20px;
	border-radius: 20px;
	margin-top: 5px;
	width: 100%;
`;
export const CitizenImgWrapperTitle = styled.p`
	font-size: 14px;
	color: #12121299;
	margin-bottom: 10px;
`;
