import React, { FC, useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import { useQuery } from "react-query";
import styled from "styled-components";
import { FormItem, Chip } from "tap2visit-ui-kit";

import { getRolesApi } from "api/api.realEstate";
import { QueriesKeys } from "interfaces/queriesKeys";
import getRoleTranslate from "utils/getRoleTranslate";

import PollsEditStore from "../store/PollsItem.store";

interface IPollsRoles {
	editable?: boolean;
}

type TRoleItem = {
	selected: boolean;
	key: string;
	id: string;
	text: string;
};

const PollsRoles: FC<IPollsRoles> = ({ editable }) => {
	const [roleItems, setRoleItems] = useState<TRoleItem[]>([]);
	const roles = useQuery({
		queryFn: () => getRolesApi(),
		queryKey: [QueriesKeys.roles],
	});

	useEffect(() => {
		const initialSelectedRolesIds = new Set(PollsEditStore.poll.roleIds);
		setRoleItems(
			(roles.data ?? []).map((role) => ({
				key: role.name,
				id: role.id,
				selected: initialSelectedRolesIds.has(role.id),
				text: getRoleTranslate(role.name),
			})),
		);
	}, [roles.data, PollsEditStore.poll.roleIds]);

	const handleRoleClick = (role: TRoleItem) => {
		PollsEditStore.changeRoles({ roleId: role.id });
		setRoleItems((items) => items.map((item) => (item.key === role.key ? { ...item, selected: !item.selected } : item)));
	};

	return (
		<FormItem label="Роли">
			<Row>
				{roleItems.map((item) => (
					<Chip
						key={item.key}
						onClick={() => handleRoleClick(item)}
						title={item.text}
						selected={item.selected}
						size="m"
						disabled={!editable}
					/>
				))}
			</Row>
		</FormItem>
	);
};

export default observer(PollsRoles);

const Row = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
	row-gap: 8px;
`;
