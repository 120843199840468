export const mainSectionsHeader = [
	{
		key: "mobileApp" as string,
		label: "Запрос на доступ с мобильного приложения" as string,
	},
	{
		key: "archive" as string,
		label: "Архив запросов" as string,
	},
];

export const ARCHIVED_STATUSES = ["CANCELED"];

export const PROPERTY_TYPES = {
	FLAT: "Квартира",
	STORAGE: "Кладовка",
	PARKING: "Парковка",
	OFFICE: "Коммерческая недвижимость",
	BASEMENT: "Цоколь",
};
