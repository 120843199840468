import styled from "styled-components";

export const TabsWrapper = styled.div`
	padding: 16px 16px 0 16px;
	width: 100%;
	display: flex;
	flex-direction: column;
`;

export const MainWrapper = styled.div`
	width: 100%;
`;

export const MainPaddingContent = styled.div`
	padding: 16px 16px 0 16px;
`;

const ItemWrapper = styled.div`
	margin-top: 24px;
	display: flex;
	gap: 16px;
`;

const Item = styled.div`
	background-color: #fff;
	border-radius: 16px;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 8px;
	flex: 1 1 auto;
	padding: 16px;
`;

const OrderedList = styled.ol`
	list-style-position: inside;
	li {
		line-height: calc(22 / 14 * 100%);
	}
`;

const OrderedListItem = styled.li`
	margin-left: 25px;
`;

const FormListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

const ConfirmButtonWrapper = styled.div`
	align-self: flex-end;
`;

export { Item, ItemWrapper, OrderedList, OrderedListItem, FormListWrapper, ConfirmButtonWrapper };
