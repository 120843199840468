import React from "react";

import styled from "styled-components";

const CustomLegend = ({ payload }) => (
	<LegendContainer id="legend">
		{payload.map((entry, index) => (
			<LegendItem key={`item-${index}`}>
				<ColorCircle color={entry.color} />
				<EntryValue>{entry.value}</EntryValue>
			</LegendItem>
		))}
	</LegendContainer>
);

export default CustomLegend;

const LegendContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start; /* Выровнять по левому краю */
	margin-left: 20px; /* Добавить отступ слева */
`;

const LegendItem = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 8px;
`;

const ColorCircle = styled.div`
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background-color: ${(props) => props.color};
	margin-right: 8px;
`;

const EntryValue = styled.div`
	width: 300px;
`;
