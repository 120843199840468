import { makeAutoObservable } from "mobx";

import { IAppeal } from "interfaces/IAppeals";

class AppealsStore {
	selectedTab: "ACTIVE" | "ARCHIVE" = "ACTIVE";
	sortedByTypeStore: string[] = [];
	selectedAppeals: IAppeal | null = null;

	constructor() {
		makeAutoObservable(this);
	}

	setSelectedTab = (selectedTab: "ACTIVE" | "ARCHIVE") => {
		this.selectedTab = selectedTab;
	};

	setSortedByTypeStore = (sortedByTypeStore: string[]) => {
		this.sortedByTypeStore = sortedByTypeStore;
	};
	setSelectedAppeals = (selectedAppeals: IAppeal) => {
		this.selectedAppeals = selectedAppeals;
	};
}

export default new AppealsStore();
