import React from "react";

import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Button } from "tap2visit-ui-kit";

import { ReactComponent as CopySvg } from "public/copy.svg";
import { ReactComponent as DiagramSvg } from "public/diagram.svg";
import { ReactComponent as StopSvg } from "public/stop.svg";
import { ReactComponent as TrashSvg } from "public/Trash.svg";

import PollsEditTabsStore from "../store/PollsItem.tabs.store";

const PollActions = ({ poll, isArchiveTab, onArchivePoll, onDeletePoll }) => {
	const navigate = useNavigate();

	const handleNavigate =
		(url: string, isAnswerTab = false): ((e: React.MouseEvent<HTMLButtonElement>) => void) =>
		(e) => {
			e.stopPropagation();
			if (isAnswerTab) {
				PollsEditTabsStore.setActiveTab("answers");
			}
			navigate(url);
		};

	return (
		<>
			<StyledButton onClick={handleNavigate(`/polls/create?from=${poll.id}`)} typeButton="ghost" size="medium" disableShadowOnHover>
				<CopySvg />
			</StyledButton>

			<StyledButton onClick={handleNavigate(`/polls/view/${poll.id}`, true)} typeButton="ghost" size="medium" disableShadowOnHover>
				<DiagramSvg />
			</StyledButton>

			{!isArchiveTab && (
				<>
					<StyledButton
						onClick={(e) => {
							e.stopPropagation();
							onArchivePoll({ id: poll.id, name: poll.name });
						}}
						typeButton="ghost"
						size="medium"
						disableShadowOnHover>
						<StopSvg />
					</StyledButton>

					<StyledButton
						onClick={(e) => {
							e.stopPropagation();
							onDeletePoll({ id: poll.id, name: poll.name });
						}}
						typeButton="ghost"
						size="medium"
						disableShadowOnHover>
						<TrashSvg style={{ fill: "#808080" }} />
					</StyledButton>
				</>
			)}
		</>
	);
};

export default PollActions;

const StyledButton = styled(Button)`
	padding: 0px;

	& > svg {
		transition: fill 0.3s;
		:hover {
			fill: #be9841;
		}
	}
`;
