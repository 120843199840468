import { makeAutoObservable } from "mobx";

class TableStore {
	startCreationTime: Date | undefined = undefined;
	endCreationTime: Date | undefined = undefined;

	constructor() {
		makeAutoObservable(this);
	}

	setStartCreationTime = (value: Date | undefined) => {
		this.startCreationTime = value;
	};

	setEndCreationTime = (value: Date | undefined) => {
		this.endCreationTime = value;
	};
}

export default new TableStore();
