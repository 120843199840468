import React, { useEffect, useState } from "react";

import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { useMutation, useQuery } from "react-query";
import { BoldDelete, BoldEye } from "t2v-icons";
import { Badge, Pagination, showToast, Space, Table, TDatePickerFilterProps } from "tap2visit-ui-kit";

import { getUserApi } from "api/api.employee";
import { deleteAppealApi, getAppealsListApi } from "api/api.gateway";
import Page from "components/layout/Page";
import BaseModal from "components/Modals/BaseModal";
import PaginationContainer from "components/PaginationContainer";
import TableEmptySlug from "components/Table/TableEmptySlug";
import TableLoader from "components/Table/TableLoader";
import { useTableFilterContext } from "components/TableFilter/TableFilter.context";
import { MIN_TABLE_HEIGHT } from "constants/constants.common";
import { Permissions } from "constants/constants.roles";
import useTablePaginationLogic from "hooks/useTablePaginationLogic";
import useWindowWidth from "hooks/useWindowWidth";
import { queryClient } from "index";
import { TAppealPriority, TAppealStatus, TGetAppealsListApiParams } from "interfaces/IAppeals";
import { TSortingDirection } from "interfaces/ISorting";
import { QueriesKeys } from "interfaces/queriesKeys";
import TableStore from "pages/receipts/components/Table/store/Table.store";
import SideBarStore from "store/SideBar.store";
import UKStore from "store/UK.Store";

import AppealsStore from "../store/Appeals.store";
import DrawerStore from "../store/Drawer.store";
import { filterPriority, getPriority } from "../utils/getPriority";
import { filterStatus, getStatus, statusMap } from "../utils/getStatus";

import AppealItemDrawer from "./AppealItemDrawer/AppealItemDrawer";

const AppealsTable = () => {
	const tableFilterContext = useTableFilterContext();

	const [tableColumns, setTableColumns] = useState([]);
	const [createdBySearchNumber, setCreatedBySearchNumber] = useState<undefined | string>(undefined);
	const [createdBySearchTitle, setCreatedBySearchTitle] = useState<undefined | string>(undefined);
	const [createdBySearchDescription, setCreatedBySearchDescription] = useState<undefined | string>(undefined);
	const [createdBySearchFullName, setCreatedBySearchFullName] = useState<undefined | string>(undefined);
	const [createdBySearchPhone, setCreatedBySearchPhone] = useState<undefined | string>(undefined);
	const [sortedBy, setSortedBy] = React.useState<{ [key: string]: TSortingDirection }>({ createdAt: "desc" });
	const [isVisibleDeleteAppeals, setIsVisibleDeleteAppeals] = React.useState(false);
	const [filterAppealsPriority, setFilterAppealsPriority] = React.useState<TAppealPriority[] | undefined>();
	const [filterAppealsStatuses, setFilterAppealsStatuses] = React.useState<TAppealStatus[] | undefined>(statusMap);
	const [datePickerFilterProps, setDatePickerFilterProps] = React.useState<TDatePickerFilterProps[]>([]);

	const preSelectedTime = React.useRef<{ startCreationTime: Date | undefined; endCreationTime: Date | undefined }>({
		startCreationTime: undefined,
		endCreationTime: undefined,
	});

	const windowWidth = useWindowWidth();

	const paginationActiveTableData = useTablePaginationLogic();
	const paginationArchiveTableData = useTablePaginationLogic();
	const paginationData = AppealsStore.selectedTab === "ACTIVE" ? paginationActiveTableData : paginationArchiveTableData;

	const handleSortChange = (key: string, direction: TSortingDirection | undefined) => {
		setSortedBy((prevState) => {
			const newState = { ...prevState };
			if (direction) {
				newState[key] = direction;
			} else {
				delete newState[key];
			}
			return newState;
		});
	};

	const authenticatedUser = useQuery({
		queryFn: () => getUserApi(),
	});

	useEffect(() => {
		const datePickerFilterProps: TDatePickerFilterProps[] = [
			{
				key: "startCreationTime",
				props: {
					isValidDateCallback: (date) => {
						const clickedDate = dayjs(date);
						const today = dayjs();
						if (today.isBefore(clickedDate)) {
							showToast({ type: "danger", description: "Нельзя выбрать дату в будущем" });
							return false;
						}

						if (preSelectedTime.current.endCreationTime && dayjs(preSelectedTime.current.endCreationTime).isBefore(clickedDate)) {
							const endTime = dayjs(preSelectedTime.current.endCreationTime).format("DD.MM.YYYY");
							showToast({ type: "danger", description: `Нельзя указать дату после ${endTime}` });
							return false;
						}

						preSelectedTime.current.startCreationTime = date;
						return true;
					},
				},
			},
			{
				key: "endCreationTime",
				props: {
					isValidDateCallback: (date) => {
						const clickedDate = dayjs(date);
						const today = dayjs();

						if (today.isBefore(dayjs(date))) {
							showToast({ type: "danger", description: "Нельзя выбрать период в будущем" });
							return false;
						}

						if (preSelectedTime.current.startCreationTime && dayjs(preSelectedTime.current.startCreationTime).isAfter(clickedDate)) {
							const startTime = dayjs(preSelectedTime.current.startCreationTime).format("DD.MM.YYYY");
							showToast({ type: "danger", description: `Нельзя указать дату до ${startTime}` });
							return false;
						}

						preSelectedTime.current.endCreationTime = date;
						return true;
					},
				},
			},
		];
		setDatePickerFilterProps(datePickerFilterProps);
	}, []);

	const deleteAppealFunc = useMutation({
		mutationFn: deleteAppealApi,
	});

	useEffect(() => {
		paginationData.setClientsCurrentPage(1);
	}, [
		AppealsStore.selectedTab,
		sortedBy,
		createdBySearchTitle,
		createdBySearchDescription,
		createdBySearchFullName,
		createdBySearchPhone,
		createdBySearchNumber,
		filterAppealsStatuses,
		filterAppealsPriority,
		tableFilterContext.selectedBuildingObjectIds,
		tableFilterContext.selectedComplexIds,
		tableFilterContext.selectedEntrancesIds,
		tableFilterContext.selectedFloorsIds,
		TableStore,
		TableStore?.startCreationTime,
		TableStore?.endCreationTime,
		datePickerFilterProps,
	]);

	const appeals = useQuery({
		queryFn: () => {
			const sort = Object.entries(sortedBy).map(([key, direction]) => ({ key, direction }));

			const params: TGetAppealsListApiParams = {
				buildingObjectIds: tableFilterContext?.selectedBuildingObjectIds,
				complexIds: tableFilterContext?.selectedComplexIds,
				entranceIds: tableFilterContext?.selectedEntrancesIds,
				floorIds: tableFilterContext?.selectedFloorsIds,
				buildingIds: tableFilterContext?.selectedBuildingsIds,
				page: paginationData.clientsCurrentPage - 1,
				size: paginationData.clientsSize,
				titleSearch: createdBySearchTitle,
				descriptionSearch: createdBySearchDescription,
				titleNumberSearch: createdBySearchNumber,
				fullNameSearch: createdBySearchFullName,
				phoneNumberSearch: createdBySearchPhone,
				status: AppealsStore.selectedTab === "ARCHIVE" ? ["ARCHIVED"] : filterAppealsStatuses,
				priority: filterAppealsPriority,
				sort: sort,
				// eslint-disable-next-line prettier/prettier
				startCreatedAt: TableStore?.startCreationTime
					? dayjs(TableStore?.startCreationTime)
							.add(1, "day")
							.toISOString()
					: undefined,
				// eslint-disable-next-line prettier/prettier
				endCreatedAt: TableStore?.endCreationTime
					? dayjs(TableStore?.endCreationTime)
							.add(1, "day")
							.toISOString()
					: undefined,
			};

			return getAppealsListApi({ params });
		},
		queryKey: [
			QueriesKeys.appealsList,
			AppealsStore.selectedTab,
			sortedBy,
			createdBySearchTitle,
			createdBySearchDescription,
			createdBySearchFullName,
			createdBySearchPhone,
			createdBySearchNumber,
			filterAppealsStatuses,
			filterAppealsPriority,
			tableFilterContext.selectedBuildingObjectIds,
			tableFilterContext.selectedComplexIds,
			tableFilterContext.selectedEntrancesIds,
			tableFilterContext.selectedFloorsIds,
			tableFilterContext.selectedBuildingsIds,
			paginationData.clientsSize,
			paginationData.clientsCurrentPage,
			TableStore?.startCreationTime,
			TableStore?.endCreationTime,
			datePickerFilterProps,
			UKStore.getUK(),
		],
	});

	const isVisiblePagination = !appeals.isFetching && appeals.data;

	React.useEffect(() => {
		if (appeals.data) {
			paginationData.setClientsTotal(appeals.data.totalPages);
		}
	}, [appeals.data?.page, TableStore]);

	useEffect(() => {
		setTableColumns([
			{
				dataIndex: "status",
				key: "status",
				title: "Статус",
				filters: AppealsStore.selectedTab !== "ARCHIVE" ? filterStatus : undefined,
				onChangeFilter: (data) => setFilterAppealsStatuses(data.map((d) => d.key)),
				width: "180px",
			},
			{
				dataIndex: "priority",
				key: "priority",
				title: "Приоритет",
				width: "150px",
				filters: filterPriority,
				onChangeFilter: (data) => setFilterAppealsPriority(data.map((d) => d.key)),
				onSortedBy: (direction) => handleSortChange("priority", direction),
			},
			{
				dataIndex: "dateOfCreate",
				key: "dateOfCreate",
				title: "Дата и время создания",
				width: "240px",
				style: {
					minWidth: "240px",
				},
				filters: [
					{ text: "Current year", key: "startCreationTime", as: "date", label: "от" } as const,
					{ text: "Next year", key: "endCreationTime", as: "date", label: "до" } as const,
				],
				onChangeFilter: (data) => {
					const newDatePickerProps = [...datePickerFilterProps];
					const newTime = { startCreationTime: TableStore.startCreationTime, endCreationTime: TableStore.endCreationTime };
					data.forEach((item) => {
						const date = item.resultData?.dates?.date;
						const datePickerItemIndex = newDatePickerProps.findIndex((filter) => {
							if (item.key === "startCreationTime") {
								return filter.key === "endCreationTime";
							}
							return filter.key === "startCreationTime";
						});

						newTime[item.key] = date ? new Date(date) : undefined;
						if (!item.resultData.dates && newDatePickerProps[datePickerItemIndex]) {
							newDatePickerProps[datePickerItemIndex].props = {
								disabledDates: undefined,
							};
						}
					});
					TableStore.setStartCreationTime(newTime.startCreationTime);
					TableStore.setEndCreationTime(newTime.endCreationTime);
					paginationData.setClientsCurrentPage(0);
					paginationData.setClientsSize(appeals.data?.size);

					const [from, to] = data;
					preSelectedTime.current.startCreationTime = from.resultData?.dates?.date;
					preSelectedTime.current.endCreationTime = to.resultData?.dates?.date;
				},
				datePickerFiltersProps: [
					{
						key: "startCreationTime",
						props: {
							isValidDateCallback: (date) => {
								const clickedDate = dayjs(date);
								const today = dayjs().endOf("day");
								if (today.isBefore(clickedDate)) {
									showToast({ type: "danger", description: "Нельзя выбрать дату в будущем" });
									return false;
								}

								if (preSelectedTime.current.endCreationTime && dayjs(preSelectedTime.current.endCreationTime).isBefore(clickedDate)) {
									const endTime = dayjs(preSelectedTime.current.endCreationTime).format("DD.MM.YYYY");
									showToast({ type: "danger", description: `Нельзя указать дату после ${endTime}` });
									return false;
								}

								preSelectedTime.current.startCreationTime = date;
								return true;
							},
						},
					},
					{
						key: "endCreationTime",
						props: {
							isValidDateCallback: (date) => {
								const clickedDate = dayjs(date);
								const today = dayjs();

								if (today.isBefore(dayjs(date))) {
									showToast({ type: "danger", description: "Нельзя выбрать период в будущем" });
									return false;
								}

								if (preSelectedTime.current.startCreationTime && dayjs(preSelectedTime.current.startCreationTime).isAfter(clickedDate)) {
									const startTime = dayjs(preSelectedTime.current.startCreationTime).format("DD.MM.YYYY");
									showToast({ type: "danger", description: `Нельзя указать дату до ${startTime}` });
									return false;
								}

								preSelectedTime.current.endCreationTime = date;
								return true;
							},
						},
					},
				],
				initialSortDirection: "desc",
				onSortedBy: (direction) => handleSortChange("createdAt", direction),
			},
			{
				dataIndex: "number",
				key: "number",
				title: "Номер",
				width: "130px",
				onSortedBy: (direction) => handleSortChange("number", direction),
				onSearch: setCreatedBySearchNumber,
			},
			{
				dataIndex: "adress",
				key: "adress",
				title: "Адрес",
				width: "220px",
			},
			{
				dataIndex: "title",
				key: "title",
				title: "Заголовок",
				columnCellStyles: {
					width: "250px",
				},
				onSearch: setCreatedBySearchTitle,
			},
			{
				dataIndex: "description",
				key: "description",
				title: "Описание",
				columnCellStyles: {
					width: "250px",
				},
				onSearch: setCreatedBySearchDescription,
			},
			{
				dataIndex: "fullName",
				key: "fullName",
				title: "ФИО",
				columnCellStyles: {
					width: "220px",
				},
				onSearch: setCreatedBySearchFullName,
			},
			{
				dataIndex: "phone",
				key: "phone",
				title: "Телефон",
				width: "160px",
				onSearch: setCreatedBySearchPhone,
			},
			{
				dataIndex: "button",
				key: "button",
				title: "",
				width: "60px",
			},
		]);
	}, [AppealsStore.sortedByTypeStore, AppealsStore.selectedTab]);

	const tableData = appeals?.data?.content?.map((v) => ({
		status: <Badge type="status" status={getStatus(v.status).status} text={getStatus(v.status).title} />,
		priority: (
			<Space>
				<img src={getPriority(v.priority)?.img} /> {getPriority(v.priority)?.title}
			</Space>
		),
		dateOfCreate: dayjs(v.createdAt).format("DD.MM.YYYY HH:mm"),
		number: `№ ${v.number}`,
		adress: v.buildingObjectAddress?.addressValue,
		title: v.title,
		description: v.description,
		fullName: `${v.applicant.lastName} ${v.applicant.firstName} ${v.applicant.middleName}`,
		phone: v.applicant.phoneNumbers[0],
		button: (
			<Space>
				<BoldEye size="16" fill="#12121299" />
				{AppealsStore.selectedTab === "ARCHIVE" && authenticatedUser.data.additionalProperties.role === Permissions.ADMIN && (
					<BoldDelete
						onClick={() => {
							AppealsStore.setSelectedAppeals(v);
							setIsVisibleDeleteAppeals(true);
						}}
						size="16"
						fill="#c95146"
					/>
				)}
			</Space>
		),
	}));
	const deleteAppeal = () => {
		deleteAppealFunc.mutateAsync(AppealsStore.selectedAppeals.id).then(() => {
			queryClient.invalidateQueries([QueriesKeys.appealsList]);
		});
		setIsVisibleDeleteAppeals(false);
	};
	return (
		<Page>
			<Table
				isFullWidth
				columns={tableColumns}
				onRowClick={(_, idx) => {
					DrawerStore.openDrawer(appeals.data.content[idx]);
				}}
				style={{
					width: `calc(${windowWidth}px - ${SideBarStore.sideBarWidth} )`,
					overflowX: "auto",
					minHeight: MIN_TABLE_HEIGHT,
				}}
				data={tableData || []}
				size="m"
				emptyComponent={appeals.isFetching ? TableLoader : TableEmptySlug}
			/>
			{isVisiblePagination && (
				<PaginationContainer>
					<Pagination
						onChangeSize={paginationData.setClientsSize}
						currentPage={paginationData.clientsCurrentPage}
						setCurrentPage={paginationData.setClientsCurrentPage}
						total={paginationData.clientsTotal}
						size={paginationData.clientsSize as 10 | 20 | 50 | 100}
					/>
				</PaginationContainer>
			)}
			<BaseModal
				isVisible={isVisibleDeleteAppeals}
				okText="Да"
				onCancel={() => setIsVisibleDeleteAppeals(false)}
				onOk={deleteAppeal}
				cancelText="Нет"
				status="delete"
				title="Вы уверены, что хотите удалить обращение?"
			/>
			<AppealItemDrawer />
		</Page>
	);
};

export default observer(AppealsTable);
