import { FC } from "react";

import { observer } from "mobx-react-lite";

import Tab from "components/ui/Tab/Tab";

import UpdateDataTabsStore, { TUpdateDataTabs } from "../store/UpdateData.tabs.store";

const tabs: { key: TUpdateDataTabs; label: string }[] = [
	{
		key: "download",
		label: "Загрузка файла",
	},
	{
		key: "history",
		label: "История ",
	},
];

const UpdateDataTabs: FC = () => (
	<Tab
		activeKey={UpdateDataTabsStore.activeTab}
		items={tabs}
		paddingItem={"12px"}
		onTabClick={(key: TUpdateDataTabs) => UpdateDataTabsStore.setActiveTab(key)}
	/>
);

export default observer(UpdateDataTabs);
