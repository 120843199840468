import React, { FC, memo, useCallback, useEffect, useMemo, useState } from "react";

import styled from "styled-components";
import { showToast, Upload } from "tap2visit-ui-kit";

import useDownloadedFiles from "components/Citizens/hooks/useDownloadedFiles";

import CitizensDrawerStore from "../store/Citizens.drawer.store";
import { CitizenImgWrapper, CitizenImgWrapperTitle } from "../style/Citizen.styled";

interface ICitizenFiles {
	disabled?: boolean;
	fileIds?: string[];
	accept?: string;
	newFiles?: File[];
	setNewFiles?: React.Dispatch<React.SetStateAction<File[]>>;

	needToRemoveFileIds?: string[];
	setNeedToRemoveFileIds?: React.Dispatch<React.SetStateAction<string[]>>;

	title?: string;

	background?: string;
}

const areEqual = (prevProps: ICitizenFiles, nextProps: ICitizenFiles) =>
	prevProps?.needToRemoveFileIds?.length === nextProps?.needToRemoveFileIds?.length &&
	prevProps?.newFiles?.length === nextProps?.newFiles?.length;

const CitizenFiles: FC<ICitizenFiles> = memo((props) => {
	const [newFiles, setNewFiles] = useState<any[]>([]);
	const downloadedFiles = useDownloadedFiles({ fileIds: props.fileIds });

	useEffect(() => {
		setNewFiles(
			props.newFiles?.map((v) => ({
				file: v,
				error: false,
			})) || [],
		);
	}, [props.newFiles]);

	const onChangeFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file = e.target.files[0];
		const convert = (file.size / (1024 * 1024)).toFixed(2);
		if (Number(convert.split(".")[0]) > 20) {
			showToast({
				description: "Размер фотографии превышает 20 Mb",
				type: "danger",
			});
			return;
		}

		const updatedNewFiles = [
			...newFiles,
			{
				file: file,
				error: false,
				errorSize: Number(convert.split(".")[0]) > 20 ? true : false,
			},
		];

		setNewFiles(updatedNewFiles);
		props.setNewFiles(updatedNewFiles.map((v) => v.file));
	};

	const onRemoveNewFiles = (fileIdx: number) => {
		const updatedNewFiles = [...props.newFiles.slice(0, fileIdx), ...props.newFiles.slice(fileIdx + 1)];
		props.setNewFiles(updatedNewFiles);
	};

	const onRemoveDownloadedFile = (fileId: string) => {
		CitizensDrawerStore.setHasUnsavedData(true);
		const newArrNeedToRemoveFileIds = [...props.needToRemoveFileIds, fileId];
		props.setNeedToRemoveFileIds(newArrNeedToRemoveFileIds);
	};

	const getFilteredDownloadFilesWithoutDeleted = useMemo(() => {
		if (props.disabled) {
			return downloadedFiles.data;
		}
		return downloadedFiles.data?.filter((fileObject) => !props.needToRemoveFileIds?.includes(fileObject.fileId));
	}, [downloadedFiles.data, props.disabled, props.needToRemoveFileIds]);

	return (
		<CitizenImgWrapper background={props.background}>
			{props.title && <CitizenImgWrapperTitle>{props.title}</CitizenImgWrapperTitle>}
			<FilesContainer>
				{getFilteredDownloadFilesWithoutDeleted?.length &&
					getFilteredDownloadFilesWithoutDeleted?.map((fileObject) => (
						<Upload
							key={fileObject.fileId}
							files={[fileObject.file]}
							accept="image/jpeg,image/png,image/jpg,image/webp"
							type="image"
							disabled={props.disabled}
							onRemove={() => onRemoveDownloadedFile(fileObject.fileId)}
						/>
					))}
				{newFiles?.map((file: any, idx) => (
					<Upload
						key={file.file.name}
						onErrorAddFile={() => {
							setNewFiles(
								newFiles.map((v, index) => ({
									...v,
									error: idx === index ? true : v.error,
								})),
							);
						}}
						error={file.error || file.errorSize}
						files={[file.file]}
						accept="image/jpeg,image/png,image/svg,image/jpg,image/webp"
						type="image"
						disabled={props.disabled}
						onRemove={() => onRemoveNewFiles(idx)}
					/>
				))}
				{!props.disabled && (
					<Upload
						key={`${newFiles.length + 1}`}
						onChangeFiles={onChangeFiles}
						accept={props.accept ?? "image/jpeg,image/png,image/jpg,image/webp,application/pdf"}
						type="image"
						disabled={props.disabled}
					/>
				)}
			</FilesContainer>
		</CitizenImgWrapper>
	);
}, areEqual);

export default CitizenFiles;

const FilesContainer = styled.div`
	display: flex;
	gap: 10px;
	flex-wrap: wrap;
`;
