import React from "react";

import { observer } from "mobx-react-lite";
import { useQuery } from "react-query";
import styled from "styled-components";
import { OutlinePlus4 } from "t2v-icons";
import { Space, colors, Text, Button, Loader } from "tap2visit-ui-kit";

import { getAppealsListByIds } from "api/api.gateway";
import { QueriesKeys } from "interfaces/queriesKeys";
import DrawerStore from "pages/appeals/store/Drawer.store";

import AppealConnectionItem from "./AppealConnectionItem";
import AppealConnectionModal from "./AppealConnectionModal";

const AppealConnection = () => {
	const isShowCompletedText =
		(DrawerStore.selectedAppeals.status === "COMPLETED" || DrawerStore.selectedAppeals.status === "ARCHIVED") &&
		DrawerStore.selectedAppeals.childAppealIds?.length > 0;
	const isShowText = DrawerStore.selectedAppeals.status !== "COMPLETED" && DrawerStore.selectedAppeals.status !== "ARCHIVED";
	const isHaveParentAppeal = DrawerStore.selectedAppeals.parentAppealId;

	const childAppeals = useQuery({
		queryFn: () => getAppealsListByIds(isHaveParentAppeal ?? DrawerStore.selectedAppeals.childAppealIds.join(",")),
		queryKey: [QueriesKeys.appealsListByIds, DrawerStore.selectedAppeals.childAppealIds, isHaveParentAppeal],
		enabled: isHaveParentAppeal ? !!isHaveParentAppeal : !!DrawerStore.selectedAppeals.childAppealIds?.length,
	});

	if (childAppeals.isLoading || childAppeals.isFetching) return <Loader />;
	return (
		<Space width="100%" direction="column" align="start">
			<AppealConnectionModal />
			{isShowCompletedText ||
				(isShowText && (
					<Text h="h5" color={colors.textNeutralPrimary}>
						{isHaveParentAppeal ? "Обращение связано" : "Связанные обращения"}
					</Text>
				))}

			{DrawerStore.selectedAppeals.status !== "COMPLETED" && DrawerStore.selectedAppeals.status !== "ARCHIVED" && (
				<AppealConnectionWrapper>
					{childAppeals.data?.map((v) => <AppealConnectionItem value={v} />)}
					{!isHaveParentAppeal && (
						<Button
							onClick={() => {
								DrawerStore.setIsOpenConnectionAppeal(true);
							}}
							typeButton="default"
							size="large"
							icon={OutlinePlus4}>
							Добавить связь
						</Button>
					)}
				</AppealConnectionWrapper>
			)}
		</Space>
	);
};

const AppealConnectionWrapper = styled.div`
	background: white;
	border-radius: 15px;
	width: 100%;
`;

export default observer(AppealConnection);
