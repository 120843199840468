import React, { useState } from "react";

import { observer } from "mobx-react-lite";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import { BoldEdit } from "t2v-icons";
import { Button, colors, FormItem, Input, Space, Text, TextArea } from "tap2visit-ui-kit";

import { getUserApi } from "api/api.employee";
import { changeAppealApi } from "api/api.gateway";
import CitizenFiles from "components/Citizens/components/CitizenFiles";
import useNewFileState from "components/Citizens/hooks/useNewFileState";
import useUploadCitizenFiles from "components/Citizens/hooks/useUploadCitizenFiles";
import { Permissions } from "constants/constants.roles";
import { queryClient } from "index";
import { QueriesKeys } from "interfaces/queriesKeys";
import { ValidationStatuses } from "modules/validation/Validation.store";
import AppealsCreateTitleValidationStore from "pages/appeals/store/AppealsCreateTitleValidationStore";
import DrawerStore from "pages/appeals/store/Drawer.store";

const AppealDescription = () => {
	const validationFields = AppealsCreateTitleValidationStore.validationData;
	const errorMessageTitle = AppealsCreateTitleValidationStore.getMessageIfError("title");
	const errorMessageDescription = AppealsCreateTitleValidationStore.getMessageIfError("description");

	const [isViewAllDescription, setIsViewAllDescription] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const { newFiles, setNewFiles, needToRemoveFileIds } = useNewFileState();

	const uploadFiles = useUploadCitizenFiles({
		needToRemoveFileIds: needToRemoveFileIds,
		newFiles: newFiles,
		bucket: "APPEAL_FILES",
	});
	const user = useQuery({
		queryFn: () => getUserApi(),
	});

	const changeAppeal = useMutation(changeAppealApi);

	const changeAppealFunc = async () => {
		const isValidTitle = AppealsCreateTitleValidationStore.checkValidation({
			title: DrawerStore.selectedAppeals.title,
			description: DrawerStore.selectedAppeals.description,
		});

		if (!isValidTitle) return;

		const newFileIds = await uploadFiles();

		changeAppeal
			.mutateAsync({
				args: {
					title: DrawerStore.selectedAppeals.title,
					description: DrawerStore.selectedAppeals.description,
					fileIds: newFileIds,
				},
				id: DrawerStore.selectedAppeals.id,
			})
			.then((res) => {
				setIsEdit(false);
				DrawerStore.setSelectedAppeals(res);
				queryClient.invalidateQueries([QueriesKeys.appealsList]);
			});
	};

	const isAdmin = user.data.additionalProperties?.role === Permissions.ADMIN;
	const isNotCompletedOrArchived = DrawerStore.selectedAppeals.status !== "COMPLETED" && DrawerStore.selectedAppeals.status !== "ARCHIVED";
	const canEdit = !isEdit;

	return (
		<Space width="100%" direction="column" align="start">
			<Space width="100%" justify="space-between">
				<Text h="h5" color={colors.textNeutralPrimary}>
					Описание
				</Text>
				{isAdmin && isNotCompletedOrArchived && canEdit && (
					<Button onClick={() => setIsEdit(true)} typeButton="secondary" icon={BoldEdit} shape="square" />
				)}
			</Space>
			{isEdit ? (
				<PersonalEditWrapper background={colors.surfaceNeutralBg2}>
					<PersonalEditContent>
						<FormItem
							state={validationFields.title !== ValidationStatuses.VALID ? "error" : "default"}
							message={errorMessageTitle}
							label="Заголовок"
							isRequiredStarLeft
							required>
							<Input
								state={validationFields.title !== ValidationStatuses.VALID ? "error" : "default"}
								onChange={(e) => DrawerStore.setSelectedAppeals({ ...DrawerStore.selectedAppeals, title: e.target.value })}
								value={DrawerStore.selectedAppeals.title}
							/>
						</FormItem>
						<FormItem
							state={validationFields.description !== ValidationStatuses.VALID ? "error" : "default"}
							message={errorMessageDescription}
							label="Описание"
							isRequiredStarLeft
							required>
							<TextArea
								state={validationFields.description !== ValidationStatuses.VALID ? "error" : "default"}
								onChange={(e) => DrawerStore.setSelectedAppeals({ ...DrawerStore.selectedAppeals, description: e.target.value })}
								style={{ width: "100%", background: "white" }}
								value={DrawerStore.selectedAppeals.description}
							/>
						</FormItem>
						<CitizenFiles
							newFiles={newFiles}
							setNewFiles={setNewFiles}
							fileIds={DrawerStore.selectedAppeals.files.map((v) => v.id)}
							title="Прикрепить фотографии"
							accept="image/jpeg,image/png,image/jpg,image/webp"
						/>
					</PersonalEditContent>

					<PersonalEditFooter>
						<Button
							typeButton="secondary"
							onClick={() => {
								setIsEdit(false);
							}}>
							Отменить
						</Button>
						<Button onClick={changeAppealFunc}>Сохранить</Button>
					</PersonalEditFooter>
				</PersonalEditWrapper>
			) : (
				<>
					<Text h="h4" color={colors.textNeutralPrimary}>
						{DrawerStore.selectedAppeals.title}
					</Text>
					<TextStyled isViewAllDescription={isViewAllDescription} type="large-regular" color={colors.textNeutralPrimary}>
						{DrawerStore.selectedAppeals.description}
					</TextStyled>
					{DrawerStore.selectedAppeals.description.length > 156 && (
						<Button style={{ marginLeft: "-10px" }} onClick={() => setIsViewAllDescription(!isViewAllDescription)} typeButton="link">
							{isViewAllDescription ? "Скрыть" : "Показать всё"}
						</Button>
					)}
					{DrawerStore.selectedAppeals.files.map((v) => v.id).length > 0 && (
						<CitizenFiles disabled fileIds={DrawerStore.selectedAppeals.files.map((v) => v.id)} title="Прикрепленные фотографии" />
					)}
				</>
			)}
		</Space>
	);
};

export const PersonalEditContent = styled.div`
	padding: 34px;
	display: flex;
	flex-direction: column;
	gap: 10px;
`;
export const PersonalEditFooter = styled.div`
	display: flex;
	background: white;
	padding: 20px;
	justify-content: end;
	button:first-child {
		margin-right: 10px;
	}
`;
export const PersonalEditWrapper = styled.div<{ background?: string }>`
	background: #be98411f;
	border-radius: 10px;
	overflow: hidden;
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 10px;
`;
const TextStyled = styled(Text)<{ isViewAllDescription: boolean }>`
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: ${({ isViewAllDescription }) => (isViewAllDescription ? "none" : "3")};
	overflow: ${({ isViewAllDescription }) => (isViewAllDescription ? "none" : "hidden")};
`;
export default observer(AppealDescription);
