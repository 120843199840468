import { useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { HOME_PAGE } from "constants/constants.paths";
import { ROUTES } from "routes/constants/routes.constants";
import AuthStore from "store/Auth.Store";
import UKStore from "store/UK.Store";

type THook = (args: { publicPaths: string[] }) => void;

const useRedirect: THook = (args) => {
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const isPublicLocation = (args.publicPaths ?? []).includes(location.pathname);
		const managingOrganizationId = UKStore.getUK().managingOrganizationId;
		const isAuthenticated = AuthStore.checkAuth();

		if (isAuthenticated && !managingOrganizationId) {
			if (location.pathname !== `/${ROUTES.LOGIN}`) {
				navigate(`/${ROUTES.LOGIN}?selectUK=true`);
			}
			return;
		}

		if (!isAuthenticated && !isPublicLocation) {
			navigate(`/${ROUTES.LOGIN}`);
			return;
		}

		if (location.pathname === "/") {
			navigate(HOME_PAGE);
		}
	}, [location.pathname, args.publicPaths]);
};

export default useRedirect;
