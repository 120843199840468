import React, { useEffect, useState } from "react";

import dayjs, { Dayjs } from "dayjs";
import { observer } from "mobx-react-lite";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import { colors, FormItem, Loader, Select, Space, Tag, Text, TimePicker } from "tap2visit-ui-kit";

import { getUsersApi } from "api/api.employee";
import { changeExecutionAppealApi } from "api/api.gateway";
import DatePicker from "components/DatePicker";
import { QueriesKeys } from "interfaces/queriesKeys";
import DrawerStore from "pages/appeals/store/Drawer.store";

const AppealSpecialist = () => {
	const [date, setDate] = useState<Dayjs>();
	const [isOpenedSelect, setIsOpenedSelect] = useState(false);
	const [tagsMaster, setTagsMaster] = useState([]);
	const masters = useQuery({
		queryFn: () =>
			getUsersApi({
				roles: ["MASTER"],
				page: 0,
				size: 100,
			}),
		queryKey: [QueriesKeys.masters],
	});

	const changeAppeal = useMutation(changeExecutionAppealApi);

	useEffect(() => {
		if (masters.isSuccess && DrawerStore.selectedAppeals.execution?.masterIds) {
			setTagsMaster([
				...DrawerStore.selectedAppeals.execution.masterIds.map((v) => ({
					key: v,
					focused: false,
					text: `${masters.data?.content.find((val) => val.id === v)?.lastName ?? ""} ${
						masters.data?.content.find((val) => val.id === v)?.firstName ?? ""
					} ${masters.data?.content.find((val) => val.id === v)?.middleName ?? ""}`,
				})),
			]);
		}
	}, [DrawerStore.selectedAppeals.execution, masters.isSuccess]);

	const changeAppealExecution = ({ masterIds, time }: { masterIds: string[]; time: Date }) => {
		changeAppeal
			.mutateAsync({
				args: {
					masterIds: [...DrawerStore.selectedAppeals.execution.masterIds, ...masterIds],
					time,
				},
				id: DrawerStore.selectedAppeals.id,
			})
			.then((res) => {
				DrawerStore.setSelectedAppeals(res);
				setDate(res.execution.time ? dayjs(res.execution.time) : dayjs());
			});
	};

	const isShowSelect = DrawerStore.selectedAppeals.status !== "COMPLETED" && DrawerStore.selectedAppeals.status !== "ARCHIVED";

	if (masters.isFetching || masters.isLoading) return <Loader />;
	return (
		<Space width="100%" direction="column" align="start">
			<Text h="h5" color={colors.textNeutralPrimary}>
				Специалист
			</Text>
			{isShowSelect && (
				<FormItem label="Мастер">
					<Select
						isDisabled={DrawerStore.selectedAppeals.status === "COMPLETED" || DrawerStore.selectedAppeals.status === "ARCHIVED"}
						style={{ width: "510px", background: "white" }}
						items={masters.data?.content.map((v) => ({
							text: `${v.lastName} ${v.firstName} ${v.middleName}`,
							key: v.id,
							focused: false,
						}))}
						onClickSelect={() => setIsOpenedSelect(true)}
						onClose={() => setIsOpenedSelect(false)}
						onClickSelectItem={(item) => {
							const items = [...tagsMaster, item];
							setTagsMaster(items);
							setIsOpenedSelect(false);
							changeAppealExecution({ masterIds: items.map((v) => v.key), time: dayjs(date).toDate() });
						}}
						isOpened={isOpenedSelect}
					/>
				</FormItem>
			)}

			<Space size={8} wrap="wrap" width="100%">
				{isShowSelect &&
					tagsMaster?.map((v) => (
						<Tag
							onDelete={() => {
								setTagsMaster(tagsMaster?.filter((val) => v.id !== val.id));
								changeAppealExecution({
									masterIds: tagsMaster.filter((v) => v.key !== v.key).map((v) => v.key),
									time: dayjs(date).toDate(),
								});
							}}
							text={v.text}
						/>
					))}
			</Space>

			{!isShowSelect && tagsMaster?.map((v) => <Tag text={v.text} />)}
			<FormItem label="Время посещения">
				<DateWrapper
					style={{
						pointerEvents:
							DrawerStore.selectedAppeals.status === "COMPLETED" || DrawerStore.selectedAppeals.status === "ARCHIVED" ? "none" : "unset",
						opacity: DrawerStore.selectedAppeals.status === "COMPLETED" || DrawerStore.selectedAppeals.status === "ARCHIVED" ? "0.8" : "1",
					}}>
					<DatePicker
						currentDate={date}
						onChangeDate={(dateValue) => {
							setDate(dayjs(dateValue[0]));
						}}
						onSave={(value) => {
							changeAppealExecution({
								masterIds: tagsMaster.filter((v) => v.key !== v.key).map((v) => v.key),
								time: dayjs(value).add(1, "day").toDate(),
							});
							setDate(dayjs(value));
						}}
					/>
					<TimePicker
						onChange={(time) => {
							changeAppealExecution({
								masterIds: tagsMaster.filter((v) => v.key !== v.key).map((v) => v.key),
								time: dayjs(date).add(1, "day").hour(time.get("hour")).minute(time.get("minute")).toDate(),
							});
						}}
						size="medium"
						initialTime={date}
					/>
				</DateWrapper>
			</FormItem>
		</Space>
	);
};

const DateWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
`;

export default observer(AppealSpecialist);
