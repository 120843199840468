import React, { FC, useEffect } from "react";

import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { FormItem, Table, colors } from "tap2visit-ui-kit";

import TreeSendToEveryOne from "components/TreeSendToEveryOne";
import RealEstateTreeStore from "hooks/useRealEstateTreeData/store/RealEstateTree.store";
import useRealEstateTreeData from "hooks/useRealEstateTreeData/useRealEstateTreeData";

import PollsEditStore from "../store/PollsItem.store";

interface IPollsTreeBuildings {
	editable?: boolean;
}

const columns = [
	{
		key: "realEstateObject",
		dataIndex: "realEstateObject",
		title: "",
		width: "100%",
		textStyle: {
			display: "flex",
			alignItems: "center",
		},
		onCell: () => {
			const props: React.TdHTMLAttributes<HTMLTableCellElement> = {};
			props.style = { background: "white", borderRight: 0 };
			return props;
		},
	},
];

const PollsTreeBuildings: FC<IPollsTreeBuildings> = (props) => {
	const { treeData, sendToEveryOneData } = useRealEstateTreeData({ disabled: !props.editable });

	React.useEffect(() => {
		RealEstateTreeStore.setInitialSelectedIds({
			ids: {
				complexIds: PollsEditStore.poll.complexIds,
				buildingIds: PollsEditStore.poll.buildingIds,
				entranceIds: PollsEditStore.poll.entranceIds,
				floorsIds: PollsEditStore.poll.floorIds,
				buildingObjectIds: PollsEditStore.poll.buildingObjectIds,
			},
		});
	}, [PollsEditStore.poll]);

	React.useEffect(
		() => () => {
			RealEstateTreeStore.selectedComplexesIds = [];
			RealEstateTreeStore.selectedBuildingsIds = [];
			RealEstateTreeStore.selectedEntrancesIds = [];
			RealEstateTreeStore.selectedFloorsIds = [];
			RealEstateTreeStore.selectedBuildingObjectsIds = [];
		},
		[],
	);

	return (
		<>
			<FormItem label="Недвижимость">
				<Container>
					<TreeSendToEveryOneContainer>
						<TreeSendToEveryOne
							visible={!!treeData.length}
							checked={sendToEveryOneData.value}
							changeChecked={sendToEveryOneData.toggle}
							disabled={!props.editable}
						/>
					</TreeSendToEveryOneContainer>
					<Table data={treeData} columns={columns} size={"m"} showHeader={false} isFullWidth />
				</Container>
			</FormItem>
		</>
	);
};

export default observer(PollsTreeBuildings);

const Container = styled.div`
	border-right: 0.5px solid ${colors.strokeDivider};
`;

const TreeSendToEveryOneContainer = styled.div`
	border-top: 0.5px solid ${colors.strokeDivider};
	border-left: 0.5px solid ${colors.strokeDivider};
	padding: 10px 12px;
`;
