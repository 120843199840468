import React, { FC } from "react";

import { observer } from "mobx-react-lite";
import { Button, Modal, Space } from "tap2visit-ui-kit";

import BaseModal from "components/Modals/BaseModal";
import { TContactType } from "interfaces/IClient";
import { ValidationStatuses } from "modules/validation/Validation.store";
import CitizenEmailStore from "pages/citizens/store/CitizenEmailStore";
import CitizenPhoneStore from "pages/citizens/store/CitizenPhoneStore";

import UserEditInput from "../../../../components/Citizens/components/UserEdit.input";

export type TCitizenContactModalOnSave = (value: string, description: string) => void;

interface ICitizenContactModal {
	onSave: TCitizenContactModalOnSave;
	type: TContactType;

	isOpen: boolean;
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;

	title: string;
	titlePlaceholder: string;
	titleLabel: string;
	descriptionPlaceholder: string;
	titleMask?: string;
}

const CitizenContactModal: FC<ICitizenContactModal> = (props) => {
	const [value, setValue] = React.useState("");
	const [description, setDescription] = React.useState("");
	const [openWarningModal, setOpenWarningModal] = React.useState(false);
	const validationFields = CitizenEmailStore.validationData;
	const errorMessageEmail = CitizenEmailStore.getMessageIfError("email");
	const errorMessageEmailComment = CitizenEmailStore.getMessageIfError("comment");
	const validationFieldsPhone = CitizenPhoneStore.validationData;
	const errorMessagePhone = CitizenPhoneStore.getMessageIfError("phone");

	const onSave = () => {
		const isValidEmail = CitizenEmailStore.checkValidation({
			email: value,
			comment: description,
		});

		const isValidPhone = CitizenPhoneStore.checkValidation({
			phone: value.replace(/[-+()_\s]/g, ""),
			comment: description,
		});

		if (props.type === "EMAIL" && !isValidEmail) {
			return;
		}
		if (props.type === "PHONE" && !isValidPhone) {
			return;
		}
		props.onSave(value, description);
	};

	const closeModal = () => {
		CitizenEmailStore.resetValidation();
		CitizenPhoneStore.resetValidation();

		if (value || description) {
			return setOpenWarningModal(true);
		}

		props.setIsOpen(false);
	};

	return (
		<>
			<Modal
				onExit={closeModal}
				footerSlot={
					<Space size={6} width="510px" justify="flex-end" align="end">
						<Button typeButton="secondary" onClick={closeModal}>
							Отменить
						</Button>
						<Button onClick={onSave}>Сохранить</Button>
					</Space>
				}
				title={props.title}>
				<UserEditInput
					isError={
						props.type === "EMAIL"
							? validationFields.email !== ValidationStatuses.VALID
							: validationFieldsPhone.phone !== ValidationStatuses.VALID
					}
					message={props.type === "EMAIL" ? errorMessageEmail : errorMessagePhone}
					label={props.titleLabel}
					required
					placeholder={props.titlePlaceholder}
					value={value}
					mask={props.titleMask}
					onChange={setValue}
				/>
				<UserEditInput
					isError={validationFields.comment !== ValidationStatuses.VALID}
					message={errorMessageEmailComment}
					placeholder={props.descriptionPlaceholder}
					required
					label={"Комментарий"}
					as="textarea"
					value={description}
					onChange={setDescription}
				/>
			</Modal>
			<BaseModal
				title="Не сохраненные изменения будут потеряны"
				description="Вы внесли изменения и не сохранили их"
				okText={"Продолжить"}
				cancelText="Отменить"
				isVisible={openWarningModal}
				status="warning"
				onCancel={() => setOpenWarningModal(false)}
				onOk={() => props.setIsOpen(false)}
				onExit={() => setOpenWarningModal(false)}
			/>
		</>
	);
};

export default observer(CitizenContactModal);
