import { FC, useEffect, useRef, useState } from "react";
import React from "react";

import { observer } from "mobx-react-lite";
import ChatList from "pages/chat/components/ChatList";
import { useLocation } from "react-router-dom";
import SideBarStore from "store/SideBar.store";
import UKStore from "store/UK.Store";
import styled, { css } from "styled-components";
import { colors } from "tap2visit-ui-kit";

import Menu from "../components/Menu/Menu";

import ExtendedRoutes from "./extendedRoutes";
import RoutesHeader from "./Routes.header";

export const DEFAULT_PAGE_HEADER_PADDING = "14px 16px";

interface IWithNavbar {
	children: JSX.Element;
}

const WithNavbar: FC<IWithNavbar> = (props) => {
	const [headerExist, setHeaderExist] = useState(false);
	const ref = useRef<HTMLDivElement>(null);

	const location = useLocation();

	useEffect(() => {
		switch (location.pathname) {
			case "/":
				setHeaderExist(true);
				break;
			case "/users":
				setHeaderExist(true);
				break;
			default:
				setHeaderExist(false);
		}
	}, [location]);

	if (location.pathname === "/login") {
		return <ExtendedRoutes>{props.children}</ExtendedRoutes>;
	}

	return (
		<div>
			{!location.pathname.includes("chat") && (
				<TopHeader menuWidth={SideBarStore.sideBarWidth} id="top-page-header">
					<RoutesHeader />
				</TopHeader>
			)}
			<Container headerExist={headerExist} className={" "}>
				{location.pathname !== "/login" && (
					<GridArea area="sidebar" ref={ref}>
						<Menu setSideBarWidth={SideBarStore.setSideBarWidth} />
					</GridArea>
				)}

				<GridArea area="main-content">
					<ContentOffset menuWidth={SideBarStore.sideBarWidth}>
						<ExtendedRoutes>{props.children}</ExtendedRoutes>
					</ContentOffset>
				</GridArea>
			</Container>
			{location.pathname.includes("chat") && <ChatList />}
		</div>
	);
};

export default observer(WithNavbar);

const Container = styled.div<{ headerExist: boolean }>`
	display: grid;
	grid-template-areas:
		"header ${(props) => (props.headerExist ? "header" : "main-content")}"
		"sidebar main-content";
	grid-template-columns: auto 1fr;
	grid-template-rows: auto 1fr;
	overflow-x: hidden;
`;

const GridArea = styled.div<{ area: string; width?: string }>`
	grid-area: ${(props) => props.area};
`;

const LeftPlaceholder = styled.div`
	position: fixed;
	height: 100%;
	width: 15%;
	left: 0;
	top: 0;
	background-color: ${(props) => props.theme.colors.blue100};
	z-index: -1;
`;

const ContentOffset = styled.div<{ menuWidth: string }>`
	transition: all 0.5s;
	${(props) => css`
		width: calc(100% - ${props.menuWidth});
		margin-left: ${props.menuWidth};
	`}
`;

const TopHeader = styled.div<{ menuWidth: string }>`
	transition: all 0.3s;
	background: ${colors.surfaceNeutralBgWhite};
	padding: ${DEFAULT_PAGE_HEADER_PADDING};
	border-bottom: 1px solid ${colors.strokeDivider};
	display: flex;
	justify-content: space-between;
	align-items: center;
	${(props) => css`
		width: calc(100% - ${props.menuWidth});
		margin-left: ${props.menuWidth};
	`}
`;
