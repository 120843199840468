import React from "react";

import styled from "styled-components";

import ChatInformation from "./components/ChatInformation";

const ChatItem = () => (
	<ChatWrapper>
		<ChatInformation />
	</ChatWrapper>
);

const ChatWrapper = styled.div`
	display: flex;
`;
export default ChatItem;
