export const ROUTES = {
	LOGIN: "login",
	RESIDENTIAL_COMPLEX: "buildings",
	PROFILE_MO: "profile",
	PAYMENT: "receipts",
	METERING_DEVICE: "counters",
	RESIDENT: "citizens",
	REQUEST_ACCESS: "access-request",
	CHAT: "chat",
	APPEAL: "appeal",
	ANNOUNCEMENT: "announcement",
	EMPLOYEE: "employee",
	VOTE: "polls",
	ARCUS: "update-data",
	CREATE_TEMPLATE: "create-template",
	FROM_TEMPLATE: "from-template",
};
