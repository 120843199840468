import { TAppealStatus } from "interfaces/IAppeals";

interface IGetStatus {
	title: string;
	status: "blue" | "red" | "green" | "lightBlue" | "pink" | "purple" | "yellow" | "orange" | "lime" | "grey" | "gold";
}
export const getStatus = (status: TAppealStatus): IGetStatus => {
	if (status === "NEW") {
		return {
			title: "Ожидает обработки",
			status: "lightBlue",
		};
	}
	if (status === "IN_REVIEW") {
		return {
			title: "Рассмотрение",
			status: "yellow",
		};
	}
	if (status === "IN_PROGRESS") {
		return {
			title: "Решается",
			status: "orange",
		};
	}
	if (status === "COMPLETED") {
		return {
			title: "Завершено",
			status: "lime",
		};
	}
	if (status === "ARCHIVED") {
		return {
			title: "Архив",
			status: "grey",
		};
	}
};

export const filterStatus = [
	{ text: "Ожидает обработки", key: "NEW" },
	{ text: "Рассмотрение", key: "IN_REVIEW" },
	{ text: "Решается", key: "IN_PROGRESS" },
	{ text: "Завершено", key: "COMPLETED" },
];

export const statusMap: TAppealStatus[] = ["NEW", "IN_REVIEW", "IN_PROGRESS", "COMPLETED"];
