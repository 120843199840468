import { useState } from "react";

import { useMutation } from "react-query";
import styled from "styled-components";

import { queryClient } from "index";
import { QueriesKeys } from "interfaces/queriesKeys";
import { ReactComponent as TrashIcon } from "public/Trash.svg";

import { createContactApi, deleteClientContactApi } from "../../../api/api.clients";
import CitizensDrawerStore from "../../../components/Citizens/store/Citizens.drawer.store";

import CitizenContact from "./CitizenContact/CitizenContact";

const CitizenEmail = () => {
	const citizenEmailMap = CitizensDrawerStore.selectedUser.contacts.filter((v) => v.type === "EMAIL");
	const createClientContact = useMutation(createContactApi);
	const deleteClientContact = useMutation(deleteClientContactApi);
	const [isOpen, setIsOpen] = useState(false);

	const onChange = async (email: string, description: string) => {
		const dataInputs = CitizensDrawerStore.selectedUser;

		await createClientContact
			.mutateAsync({
				body: {
					clientId: dataInputs.id,
					description: description,
					isDefault: false,
					type: "EMAIL",
					value: email,
				},
			})
			.then(() => {
				setIsOpen(false);
				dataInputs.contacts.push({ description, isDefault: false, type: "EMAIL", value: email });
			});

		queryClient.invalidateQueries([QueriesKeys.clients]);
	};

	const deleteEmail = async (id: string) => {
		await deleteClientContact.mutateAsync({ path: { id } });

		const removedContactIdx = CitizensDrawerStore.selectedUser.contacts.findIndex((contact) => contact.id === id);
		if (removedContactIdx !== -1) {
			CitizensDrawerStore.removeContact(removedContactIdx);
		}

		queryClient.invalidateQueries([QueriesKeys.clients]);
	};
	return (
		<CitizenContact
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			title={"Эл. почта"}
			contactsCollection={citizenEmailMap}
			onSave={onChange}
			onDeleteContact={deleteEmail}
			modalTitle={"Добавить эл.почту"}
			modalTitlePlaceholder="example@example.com"
			modalTitleLabel="Эл. почта"
			modalDescriptionPlaceholder="Например: почта собственника"
			buttonText={"Добавить эл. почту"}
			type="EMAIL"
		/>
	);
};

export default CitizenEmail;

const TrashIconContainer = styled(TrashIcon)`
	cursor: pointer;
`;
