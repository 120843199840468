import React from "react";

import { useQuery } from "react-query";
import styled from "styled-components";
import { colors, Text, Tooltip } from "tap2visit-ui-kit";

import { getUserByIdApi } from "api/api.clients";
import getCitizenStatus from "components/UserAvatarAndInitials/utils/getCitizenStatus";
import { IApplicant } from "interfaces/IAppeals";
import { QueriesKeys } from "interfaces/queriesKeys";
import { ReactComponent as SmartPhone } from "public/SmartPhone.svg";

const AppealCloseApplicant = ({ applicant }: { applicant: IApplicant }) => {
	const currentUser = useQuery({
		queryFn: () => getUserByIdApi({ path: { id: applicant.clientId } }),
		queryKey: [QueriesKeys.user, applicant.clientId],
		enabled: !!applicant.clientId,
		cacheTime: 0,
		staleTime: 0,
	});

	const citizenUserStatus = getCitizenStatus({
		lastActivity: currentUser.data?.lastActivity,
		registration: currentUser.data?.registration,
	});

	const loginDefaultIsOwner = currentUser.data?.contacts?.find((v) => v.isDefault && v.type === "PHONE")?.value;

	return (
		<div>
			<Tooltip style={{ zIndex: 1000 }} id="user_mobile_status">
				{citizenUserStatus.hint}
			</Tooltip>
			<AppealCloseApplicantWrapper>
				<div>
					<Text type="table-cell-s-regular" color={colors.textNeutralSecondary}>
						ФИО
					</Text>
					<AppealText type="table-cell-m-medium" color={colors.textNeutralPrimary}>
						{applicant.lastName} {applicant.firstName} {applicant.middleName}
					</AppealText>
				</div>
				<SmartPhone
					data-tooltip-id="user_mobile_status"
					data-tooltip-place="bottom"
					data-tooltip-variant="light"
					width={16}
					height={16}
					fill={citizenUserStatus.color}
				/>
			</AppealCloseApplicantWrapper>
			<AppealCloseApplicantWrapper>
				<div>
					<Text type="table-cell-s-regular" color={colors.textNeutralSecondary}>
						Логин в приложении
					</Text>
					<AppealText type="table-cell-m-medium" color={colors.textNeutralPrimary}>
						{applicant.login ?? ((applicant.isOwner && loginDefaultIsOwner) || "Нет данных")}
					</AppealText>
				</div>
			</AppealCloseApplicantWrapper>
			<AppealCloseApplicantWrapper>
				<div>
					<Text type="table-cell-s-regular" color={colors.textNeutralSecondary}>
						Телефон для связи
					</Text>
					<AppealText type="table-cell-m-medium" color={colors.textNeutralPrimary}>
						{applicant.phoneNumbers[0] ?? "Нет данных"}
					</AppealText>
				</div>
			</AppealCloseApplicantWrapper>
			<AppealCloseApplicantWrapper>
				<div>
					<Text type="table-cell-s-regular" color={colors.textNeutralSecondary}>
						Эл. почты
					</Text>
					<AppealText type="table-cell-m-medium" color={colors.textNeutralPrimary}>
						{applicant.emails[0] ?? "Нет данных"}
					</AppealText>
				</div>
			</AppealCloseApplicantWrapper>
		</div>
	);
};

const AppealText = styled(Text)`
	width: 320px;
	overflow: hidden;
	text-overflow: ellipsis;
	text-wrap: nowrap;
`;

const AppealCloseApplicantWrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #12121229;
	padding: 8px 16px;
`;

export default AppealCloseApplicant;
