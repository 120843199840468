import React, { FC, useState } from "react";

import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { BoldUserdelete, BoldUserchecked5, BoldRefresh1 } from "t2v-icons";
import { Button, LoaderV2, showToast } from "tap2visit-ui-kit";

import { IRequestUser } from "interfaces/IRequest";
import { useApproveRequest, useRestoreRequest } from "pages/accessRequest/api/api.request";
import AccessRequestRejectModalStore from "pages/accessRequest/components/ModalCommentRejection/store/AccessRequestRejectModal.store";
import AccessRequestCanApproveRequestStore from "pages/accessRequest/store/AccessRequestCanApproveRequest.store";
import { IApproveResponse } from "pages/accessRequest/types/types";

interface IActionButtons {
	requestUser: IRequestUser;
	compareUserId: string | undefined;
	onRequestSuccess: () => void;
}

const ActionButtons: FC<IActionButtons> = ({ requestUser, compareUserId, onRequestSuccess }) => {
	const [isApproving, setIsApproving] = useState(false);
	const [isRestoring, setIsRestoring] = useState(false);

	const { mutate: approveRequest } = useApproveRequest({
		requestUserId: requestUser.id,
		compareUserId,
		onRequestSuccess: (response: IApproveResponse) => {
			onRequestSuccess();
			setIsApproving(false);
			if (response.message) {
				AccessRequestRejectModalStore.showAlert(response.message);
			}
		},
		onRequestError: (error: Error) => {
			setIsApproving(false);
			const errorMessage = (error as any)?.response?.data?.message || "Произошла ошибка при выполнении запроса";
			showToast({
				description: errorMessage,
				type: "danger",
			});
		},
	});

	const { mutate: restoreRequest } = useRestoreRequest({
		requestUserId: requestUser.id,
		onRequestSuccess: () => {
			onRequestSuccess();
			setIsRestoring(false);
		},
		onRequestError: () => {
			setIsApproving(false);
		},
	});

	const isDisabled = () => !requestUser.canBeRestored || requestUser.status !== "CANCELED";

	const onOpenCancelRejectCommentModal = () => {
		AccessRequestRejectModalStore.showModal({ requestId: requestUser.id });
	};

	const onOpenRejectCommentModal = () => {
		AccessRequestRejectModalStore.showModal({ requestId: requestUser.id, isCancelMode: true });
	};

	const handleApprove = () => {
		setIsApproving(true);
		approveRequest();
	};

	const handleRestore = () => {
		setIsRestoring(true);
		restoreRequest();
	};

	return (
		<>
			{requestUser.status === "NEW" && (
				<WrapperUserButton>
					<Button
						icon={(args) => <BoldUserdelete {...args} style={{ height: "18px", width: "18px" }} />}
						variant="red"
						onClick={onOpenCancelRejectCommentModal}>
						Отклонить
					</Button>
					<AcceptButton
						icon={() => (isApproving ? <LoaderV2 /> : <BoldUserchecked5 style={{ height: "18px", width: "18px" }} />)}
						disabled={!AccessRequestCanApproveRequestStore.canApproveRequest || isApproving}
						onClick={handleApprove}>
						Принять
					</AcceptButton>
				</WrapperUserButton>
			)}

			{requestUser.status === "CANCELED" && (
				<WrapperUserButton>
					<Button size="small" typeButton="secondary" onClick={onOpenRejectCommentModal}>
						Комментарий
					</Button>
					<Button
						disabled={isDisabled() || isRestoring}
						size="small"
						shape="square"
						typeButton="primary"
						icon={() => (isRestoring ? <LoaderV2 /> : <BoldRefresh1 style={{ height: "18px", width: "18px" }} />)}
						onClick={handleRestore}
					/>
				</WrapperUserButton>
			)}
		</>
	);
};

export default observer(ActionButtons);

const WrapperUserButton = styled.div`
	display: flex;
	button {
		&:first-child {
			margin-right: 8px;
		}
		&:last-child {
			div {
				padding: 0px 4px 0px 6px !important;
			}
		}
	}
`;

const AcceptButton = styled(Button)`
	padding: 0px 8px !important;
`;
