import { useEffect, useState } from "react";
import React from "react";

import { observer } from "mobx-react-lite";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import { BoldPlus4 } from "t2v-icons";
import { Button, FormItem, Switch, TextArea, showToast, SelectV2 } from "tap2visit-ui-kit";

import { createOrGetAliasApi, getAddressById, searchAddressApi, searchSuggestAddressApi } from "api/api.aliases";
import BaseModal from "components/Modals/BaseModal";
import TextHeader from "components/TextHeader";
import Input from "components/ui/Input/Input";
import { mask } from "constants/constants.mask";
import { IAliasCreate } from "interfaces/IGeocoder";
import { QueriesKeys } from "interfaces/queriesKeys";
import { ReactComponent as Delete } from "public/delete.svg";
import { ReactComponent as Eye_off } from "public/eye_off.svg";
import { ReactComponent as Eye_on } from "public/eye_on.svg";
import { ReactComponent as Plus } from "public/plus.svg";
import UKStore from "store/UK.Store";

import { postFileUploadApi } from "../../../api/api.storage";
import { editOrCreateOfficeInputMaxLength } from "../constants/inputOffice.constants";
import useCreateAndUpdateOfficeMutations from "../hooks/useCreateAndUpdateOfficeMutations";
import useOfficeLogo from "../hooks/useOfficeLogo";
import profileUKVisibilityStore from "../profileUKVisibility.store";
import ProfileUKVisibilityStore from "../profileUKVisibility.store";
import { showModalProfileEditExistIfThereChanges } from "../utils/showModalProfileEditExistIfThereChanges";

import ModalAddNewAddress from "./ModalAddNewAddress";
import { addNewFieldInput, deleteFieldInput, updateInputOfInputArr } from "./PageEdit.utils";
import useTimeState from "./PageEditTime/hooks/useTimeState";
import PageEditTime from "./PageEditTime/PageEdit.time";
import PageEditDialogAreYouSureCancelEditOffice from "./PageInfo.DialogAreYouSureCancelEditOffice";
import ProfileUKUpload from "./ProfileUKImage";
import RenderByCondition from "./RenderByCondition";

type TModalText = { title: string; description: string };

const PageEdit = () => {
	const [file, setFile] = useState<File>();
	const [isOpenStaff, setIsOpenStaff] = useState(localStorage.getItem("staff_is_shown") ? localStorage.getItem("staff_is_shown") : true);
	const [isOpenEmail, setIsOpenEmail] = useState(localStorage.getItem("email_is_shown") ? localStorage.getItem("email_is_shown") : true);
	const [isOpenPhone, setIsOpenPhone] = useState(localStorage.getItem("phone_is_shown") ? localStorage.getItem("phone_is_shown") : true);
	const [isOpenSelect, setIsOpenSelect] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [valueInput, setValueInput] = useState("");
	const [adressList, setAdressList] = useState([]);
	const [delayedValue, setDelayedValue] = useState("");

	// #region inputs logic
	const [isShowOfficeToUsers, setIsShowOfficeToUsers] = React.useState(false);

	const [officeName, setOfficeName] = React.useState("");
	const [officeAddress, setOfficeAddress] = React.useState("");
	const [additionalInformation, setAdditionalInformation] = React.useState("");

	const [positions, setPositions] = React.useState<string[]>([]);
	const [initials, setInitials] = React.useState<string[]>([]);
	const [isShowUserOfficials, setIsShowUserOfficials] = React.useState<boolean[]>([]);

	const [phonesDescriptions, setPhoneDescriptions] = React.useState<string[]>([]);
	const [phones, setPhones] = React.useState<string[]>([]);
	const [isShowUserPhones, setIsShowUserPhones] = React.useState<boolean[]>([]);

	const [emails, setEmails] = React.useState<string[]>([]);
	const [emailsDescriptions, setEmailsDescriptions] = React.useState<string[]>([]);
	const [isShowUserEmails, setIsShowUserEmails] = React.useState<boolean[]>([]);
	const isFocusedInAdressList = adressList.filter((v) => v.focused).length === 0;

	const timeState = useTimeState();
	// #endregion

	// #region confirm deletion modal logic
	const callbackAfterConfirmDeletion = React.useRef<() => void>();
	const deletionModalText = React.useRef<TModalText>({
		description: "",
		title: "",
	});
	const searchAddress = useMutation({
		mutationFn: (address: string) => searchAddressApi({ params: { query: address } }),
	});

	const searchAddressSuggest = useMutation({
		mutationFn: (address: string) => searchSuggestAddressApi({ params: { query: address } }),
	});

	useEffect(() => {
		const handler = setTimeout(() => {
			setDelayedValue(valueInput);
		}, 1000);

		return () => {
			clearTimeout(handler);
		};
	}, [valueInput]);

	useEffect(() => {
		(async () => {
			if (isFocusedInAdressList) {
				const res = await searchAddressSuggest.mutateAsync(delayedValue.length > 2 ? delayedValue : null);
				setAdressList(
					res.map((v) => ({
						text: v.value,
						focused: false,
						key: v.id,
					})),
				);
			}
		})();
	}, [delayedValue]);

	useEffect(() => {
		setValueInput(valueInput);
		if (adressList.find((v) => v.focused)) {
			setValueInput(adressList.find((v) => v.focused).text);
		}
	}, [adressList]);

	const [isVisibleDeletionModal, setIsVisibleDeletionModal] = React.useState(false);

	const onClickRemoveButton = (callback: () => void, modalText: TModalText) => {
		deletionModalText.current = modalText;
		callbackAfterConfirmDeletion.current = () => {
			callback();
			setIsVisibleDeletionModal(false);
		};

		setIsVisibleDeletionModal(true);
	};
	// #endregion

	const address = useQuery({
		queryFn: () => getAddressById({ params: { id: profileUKVisibilityStore.editableOffice?.addressId } }),
		queryKey: [QueriesKeys.officeAddress, profileUKVisibilityStore.editableOffice?.id],
		enabled: !!profileUKVisibilityStore.editableOffice,
	});

	// #region clear the fields if we create a new office
	React.useEffect(() => {
		if (profileUKVisibilityStore.isEditModeEnabled && !profileUKVisibilityStore.editableOffice) {
			setIsShowOfficeToUsers(false);
			setOfficeName("");
			setOfficeAddress("");
			setAdditionalInformation("");
			setPositions([]);
			setInitials([]);
			setIsShowUserOfficials([]);
			setPhoneDescriptions([]);
			setPhones([]);
			setIsShowUserPhones([]);
			setEmails([]);
			setEmailsDescriptions([]);
			setIsShowUserEmails([]);
			timeState.resetTimeState();
		}
	}, [profileUKVisibilityStore.editableOffice]);
	// #endregion

	React.useEffect(() => {
		if (!profileUKVisibilityStore.editableOffice || address.isFetching) {
			return;
		}

		setIsShowOfficeToUsers(profileUKVisibilityStore.editableOffice.isShownToUsers);

		setOfficeName(profileUKVisibilityStore.editableOffice.name);
		setAdditionalInformation(profileUKVisibilityStore.editableOffice.description);
		setOfficeAddress(address.data?.value);

		const editableOfficials = profileUKVisibilityStore.editableOffice.executives.reduce(
			(acc, curr) => {
				acc.officials.push(curr.position);
				acc.initials.push(curr.fio);
				acc.isShowUserOfficials.push(curr.isShownToUsers);
				return acc;
			},
			{ officials: [], initials: [], isShowUserOfficials: [] },
		);
		const editablePhones = profileUKVisibilityStore.editableOffice.phones.reduce(
			(acc, curr) => {
				acc.phones.push(curr.number);
				acc.phonesDescriptions.push(curr.description);
				acc.isShowUserPhones.push(curr.isShownToUsers);
				return acc;
			},
			{ phones: [], phonesDescriptions: [], isShowUserPhones: [] },
		);
		const editableEmails = profileUKVisibilityStore.editableOffice.emails.reduce(
			(acc, curr) => {
				acc.emails.push(curr.address);
				acc.emailsDescriptions.push(curr.description);
				acc.isShowUserEmails.push(curr.isShownToUsers);

				return acc;
			},
			{ emails: [], emailsDescriptions: [], isShowUserEmails: [] },
		);

		setPositions(editableOfficials.officials);
		setInitials(editableOfficials.initials);
		setIsShowUserOfficials(editableOfficials.isShowUserOfficials);

		setPhones(editablePhones.phones);
		setPhoneDescriptions(editablePhones.phonesDescriptions);
		setIsShowUserPhones(editablePhones.isShowUserPhones);

		setEmails(editableEmails.emails);
		setEmailsDescriptions(editableEmails.emailsDescriptions);
		setIsShowUserEmails(editableEmails.isShowUserEmails);
	}, [profileUKVisibilityStore.editableOffice, address.data, address.isFetching]);

	const imgData = useOfficeLogo({
		isDefaultImage: false,
		avatarId: profileUKVisibilityStore.editableOffice?.avatarId,
	});

	// #region save office logic
	const createOrGetAlias = useMutation({
		mutationFn: (args: IAliasCreate) => createOrGetAliasApi({ body: args }),
	});

	const { createOffice, updateOffice } = useCreateAndUpdateOfficeMutations({
		state: {
			name: officeName,
			addressId: adressList.find((v) => v.focused)?.key || ProfileUKVisibilityStore.newAddressId,
			managingOrganizationId: UKStore.getUK().managingOrganizationId,
			description: additionalInformation,
			isShownToUsers: isShowOfficeToUsers,
			executives: initials.map((initial, idx) => ({
				fio: initial,
				position: positions[idx],
				isShownToUsers: isShowUserOfficials[idx] ?? false,
				id: profileUKVisibilityStore.editableOffice?.executives.find((v) => v.fio === initial)?.id,
			})),
			phones: phones.map((phone, idx) => ({
				number: `+${phone.replace(/\D/g, "")}`,
				description: phonesDescriptions[idx],
				isShownToUsers: isShowUserPhones[idx] ?? false,
				isDefault: idx === 0,
				id: profileUKVisibilityStore.editableOffice?.phones.find((v) => v.number === phone)?.id,
			})),
			emails: emails.map((email, idx) => ({
				address: email,
				description: emailsDescriptions[idx],
				isShownToUsers: isShowUserEmails[idx] ?? false,
				isDefault: idx === 0,
				id: profileUKVisibilityStore.editableOffice?.emails?.find((v) => v.address === email)?.id,
			})),
			timetables: [
				...timeState.timeState.map((v) => ({
					...v,
					workingTimePeriod: v.workingTimePeriod,
					breakTimePeriod: v.breakTimePeriod?.endTime === "00:00Z" || v.breakTimePeriod?.startTime === "00:00Z" ? null : v.breakTimePeriod,
					receptionHours: v.receptionHours ?? [],
				})),
			],
		},
	});

	const postFileUpload = useMutation(postFileUploadApi);

	const getFilteredTimeStateArray = () =>
		timeState?.timeState?.filter(
			(v) =>
				v.receptionHours?.length > 0 &&
				v.receptionHours.every(
					(reception) =>
						reception.description.trim() !== "" && reception.timePeriod.endTime !== "" && reception.timePeriod.startTime !== "",
				),
		).length === 0;

	const fileMutateFunc = async ({ alias, edit }) => {
		const addressId = alias;
		const editableOffice = profileUKVisibilityStore.editableOffice;

		const handleResponse = async (avatarId) => {
			if (edit) {
				await updateOffice.mutateAsync({ addressId, avatarId });
			} else {
				const newOffice = await createOffice.mutateAsync({ addressId, avatarId });
				if (!edit) profileUKVisibilityStore.cancelEdit(newOffice?.id);
			}
			profileUKVisibilityStore.cancelEdit();
		};

		if (file) {
			const formData = new FormData();
			formData.append("file", file);
			const res = await postFileUpload.mutateAsync({ file: formData, bucket: "MO_LOGOS" });
			await handleResponse(res.id);
		} else {
			await handleResponse(editableOffice?.avatarId);
		}
	};

	const checkWorkingTimePeriods = () =>
		timeState.timeState.every((item) => {
			if (
				!item.workingTimePeriod ||
				!item.workingTimePeriod.startTime ||
				!item.workingTimePeriod.endTime ||
				item.workingTimePeriod.startTime === "00:00Z" ||
				item.workingTimePeriod.endTime === "00:00Z"
			) {
				return true;
			}
			const startTime = new Date(`1970-01-01T${item.workingTimePeriod.startTime}`);
			const endTime = new Date(`1970-01-01T${item.workingTimePeriod.endTime}`);
			return endTime < startTime;
		});

	const checkReceptionTimePeriods = () =>
		timeState.timeState.every((item) => {
			if (item.receptionHours.length === 0) {
				return true;
			}
			return item.receptionHours.every(
				(hour) => new Date(`1970-01-01T${hour.timePeriod.startTime}`) > new Date(`1970-01-01T${hour.timePeriod.endTime}`),
			);
		});

	const checkBreakingTimePeriods = () =>
		timeState.timeState.every((item) => {
			if (
				!item.breakTimePeriod ||
				!item.breakTimePeriod.startTime ||
				!item.breakTimePeriod.endTime ||
				item.breakTimePeriod.startTime === "00:00Z" ||
				item.breakTimePeriod.endTime === "00:00Z"
			) {
				return true;
			}
			const startTime = new Date(`1970-01-01T${item.breakTimePeriod.startTime}`);
			const endTime = new Date(`1970-01-01T${item.breakTimePeriod.endTime}`);
			return endTime < startTime;
		});

	const convertTimeToMinutes = (time) => {
		const timeWithoutZ = time?.replace("Z", "");
		const [hours, minutes] = timeWithoutZ.split(":").map(Number);
		return hours * 60 + minutes;
	};

	const isBreakingTimeWithinWorkingTime = (period) => {
		const workingStart = convertTimeToMinutes(period.workingTimePeriod.startTime);
		const workingEnd = convertTimeToMinutes(period.workingTimePeriod.endTime);
		const breakingStart = convertTimeToMinutes(period.breakTimePeriod.startTime);
		const breakingEnd = convertTimeToMinutes(period.breakTimePeriod.endTime);

		return workingStart <= breakingStart && breakingEnd <= workingEnd;
	};

	const isAlertWithinWorkingTime = () => {
		let alert = false;
		timeState.timeState
			.filter((v) => v.workingTimePeriod && v.breakTimePeriod)
			.forEach((period) => {
				if (period.breakTimePeriod.startTime === "00:00Z" || period.breakTimePeriod.endTime === "00:00Z") {
					return (alert = false);
				}
				if (!isBreakingTimeWithinWorkingTime(period)) {
					return (alert = true);
				}
			});

		return alert;
	};

	const validateReceptionHours = (workingTimePeriod, receptionHours) => {
		const workingStart = convertTimeToMinutes(workingTimePeriod.startTime);
		const workingEnd = convertTimeToMinutes(workingTimePeriod.endTime);

		for (const receptionHour of receptionHours) {
			const receptionStart = convertTimeToMinutes(receptionHour.timePeriod.startTime);
			const receptionEnd = convertTimeToMinutes(receptionHour.timePeriod.endTime);

			if (receptionStart < workingStart || receptionEnd > workingEnd) {
				return true;
			}
		}
	};

	const isAlertWithinReceptionTime = () => {
		let alert = false;
		timeState.timeState
			.filter((v) => v.workingTimePeriod && v.receptionHours)
			.forEach((period) => {
				alert = validateReceptionHours(period.workingTimePeriod, period.receptionHours);
			});
		return alert;
	};

	const onSave = async () => {
		const foundAddress = adressList.find((v) => v.focused);

		if (!foundAddress?.key) {
			return showToast({
				description: "Выберите адрес офиса",
				type: "danger",
			});
		}

		if (
			timeState.timeState.filter((v) => v.workingTimePeriod).length > 0 &&
			timeState.timeState.filter((v) => !v.isWeekend).length > 0 &&
			checkWorkingTimePeriods()
		) {
			return showToast({
				description: "Проверьте часы работы! Дата окончания должна быть позже даты начала!",
				type: "danger",
			});
		}
		if (
			timeState.timeState.filter((v) => v.breakTimePeriod).length > 0 &&
			timeState.timeState.filter((v) => !v.isWeekend).length > 0 &&
			isAlertWithinWorkingTime()
		) {
			return showToast({
				description: "Проверьте время перерыва! Значение должно входить в интервал часов работы",
				type: "danger",
			});
		}
		if (
			timeState.timeState.filter((v) => v.breakTimePeriod).length > 0 &&
			timeState.timeState.filter((v) => !v.isWeekend).length > 0 &&
			checkBreakingTimePeriods()
		) {
			return showToast({
				description: "Проверьте время перерыва! Дата окончания должна быть позже даты начала!",
				type: "danger",
			});
		}
		if (
			timeState.timeState.filter((v) => v.receptionHours?.length)?.length > 0 &&
			timeState.timeState.filter((v) => !v.isWeekend).length > 0 &&
			getFilteredTimeStateArray()
		) {
			return showToast({
				description: "Заполните приемные часы!",
				type: "danger",
			});
		}
		if (
			timeState.timeState.filter((v) => v.receptionHours?.length)?.length > 0 &&
			timeState.timeState.filter((v) => !v.isWeekend).length > 0 &&
			checkReceptionTimePeriods()
		) {
			return showToast({
				description: "Проверьте часы приема! Дата окончания должна быть позже даты начала!",
				type: "danger",
			});
		}

		if (timeState.timeState.filter((v) => !v.isWeekend).length > 0 && isAlertWithinReceptionTime()) {
			return showToast({
				description: "Проверьте часы приема! Значение должно входить в интервал часов работы",
				type: "danger",
			});
		}

		if (profileUKVisibilityStore.editableOffice) {
			await fileMutateFunc({ edit: true, alias: foundAddress.key });
		} else {
			await fileMutateFunc({ edit: false, alias: foundAddress.key });
		}
	};
	// #endregion

	useEffect(() => {
		if (!localStorage.getItem("is_shown_requisite") && localStorage.getItem("is_shown_requisite") === "false") {
			localStorage.setItem("is_shown_requisite", String(true));
		}

		if (!localStorage.getItem("staff_is_shown") && localStorage.getItem("staff_is_shown") === "false") {
			localStorage.setItem("staff_is_shown", String(true));
		}

		if (!localStorage.getItem("email_is_shown") && localStorage.getItem("email_is_shown") === "false") {
			localStorage.setItem("email_is_shown", String(true));
		}

		if (!localStorage.getItem("phone_is_shown") && localStorage.getItem("phone_is_shown") === "false") {
			localStorage.setItem("phone_is_shown", String(true));
		}

		if (!localStorage.getItem("hours_is_shown") && localStorage.getItem("hours_is_shown") === "false") {
			localStorage.setItem("hours_is_shown", String(true));
		}
	}, []);

	const addNewOfficial = () => {
		addNewFieldInput(setPositions);
		addNewFieldInput(setInitials);
	};

	const addNewPhones = () => {
		addNewFieldInput(setPhones);
		addNewFieldInput(setPhoneDescriptions);
	};

	const addNewEmails = () => {
		addNewFieldInput(setEmails);
		addNewFieldInput(setEmailsDescriptions);
	};

	const getHeaderText = () => {
		if (profileUKVisibilityStore.editableOffice) {
			return "Редактировать офис";
		}

		if (profileUKVisibilityStore.isThereAtLeastOneOffice) {
			return "Создание нового офиса";
		}

		return "Создание головного офиса";
	};

	const isDisabledSaveButton =
		!officeName ||
		createOffice.isLoading ||
		updateOffice.isLoading ||
		searchAddress.isLoading ||
		createOrGetAlias.isLoading ||
		postFileUpload.isLoading ||
		(initials.length > 0 && initials.filter((v) => v === "").length > 0) ||
		(positions.length > 0 && positions.filter((v) => v === "").length > 0) ||
		(emails.length > 0 && emails.filter((v) => v === "").length > 0) ||
		(emailsDescriptions.length > 0 && emailsDescriptions.filter((v) => v === "").length > 0) ||
		(phones.length > 0 && phones.filter((v) => v === "").length > 0) ||
		(phonesDescriptions.length > 0 && phonesDescriptions.filter((v) => v === "").length > 0);

	useEffect(() => {
		profileUKVisibilityStore.setNewEditableOffice({
			officeName,
			additionalInformation,
			phones,
			positions,
			emails,
			timeState: timeState.timeState,
		});
	}, [officeName, additionalInformation, positions, emails, phones, timeState.timeState]);

	useEffect(() => {
		if (address.data) {
			setAdressList(
				adressList.map((v) => ({
					...v,
					focused: v.key === address.data.id,
				})),
			);
			setValueInput(address.data.value);
		}
	}, [address]);
	return (
		<>
			{openModal && <ModalAddNewAddress open={openModal} setOpen={setOpenModal} />}

			<ProfileWrapper>
				<ProfileHeader>
					<BasicInformation>
						<TextHeader>{getHeaderText()}</TextHeader>
					</BasicInformation>
					<ActionWrapper>
						{profileUKVisibilityStore.isThereAtLeastOneOffice && (
							<Button
								typeButton="secondary"
								size="medium"
								onClick={() => {
									showModalProfileEditExistIfThereChanges({ onContinue: profileUKVisibilityStore.cancelEdit });
								}}>
								Отменить
							</Button>
						)}
						<Button disabled={isDisabledSaveButton} onClick={onSave} size="medium">
							Сохранить
						</Button>
					</ActionWrapper>
				</ProfileHeader>
				<ProfileDescription>
					<ProfileUKUpload
						uploadFile={setFile}
						img={imgData.img}
						loading={profileUKVisibilityStore.editableOffice?.avatarId && imgData.isFetching}
					/>
					<Column>
						<FormItem label={"Название офиса"} labelPosition={"vertical"}>
							<Input
								sizeInput={"medium"}
								value={officeName}
								onChange={(e) => setOfficeName(e.target.value)}
								maxLength={editOrCreateOfficeInputMaxLength.officeName}
							/>
						</FormItem>
						<FormItem label={"Адрес офиса"} labelPosition={"vertical"}>
							<>
								<SelectV2
									withIconArrow
									value={valueInput}
									onChange={(value) => {
										setValueInput(value);
									}}
									items={adressList}
									onClickSelect={() => setIsOpenSelect(true)}
									isOpened={isOpenSelect}
									placeholder="Выберите"
									tag={false}
									style={{ minWidth: "100%", fontSize: "16px" }}
									onClose={() => setIsOpenSelect(false)}
									onClickSelectItem={(item) => {
										setAdressList(
											adressList.map((v) => ({
												...v,
												focused: item.key === v.key,
											})),
										);
										setIsOpenSelect(false);
									}}
								/>
								{isFocusedInAdressList && (
									<ButtonStyled onClick={() => setOpenModal(true)} typeButton="link" icon={BoldPlus4}>
										Добавить адрес
									</ButtonStyled>
								)}
							</>
						</FormItem>
						<FormItem>
							<SwitchElement>
								<Switch
									size={"medium"}
									isActive={isShowOfficeToUsers}
									onChangeActive={setIsShowOfficeToUsers}
									labelPosition={"right"}
									label={"Отображать офис пользователям"}
								/>
							</SwitchElement>
						</FormItem>
						<FormItem label={"Дополнительная информация"} labelPosition={"vertical"}>
							<TextArea
								value={additionalInformation}
								onChangeValue={setAdditionalInformation}
								maxLength={editOrCreateOfficeInputMaxLength.additionalInformation}
								showLengthValue={false}
							/>
						</FormItem>
					</Column>
				</ProfileDescription>

				<InlineHeader>
					<TextHeader>Должностные лица</TextHeader>
					<ButtonStyledIcon
						typeButton={"secondary"}
						size={"medium"}
						shape="square"
						onClick={() => {
							setIsOpenStaff((init) => !init);
							localStorage.setItem("staff_is_shown", String(!isOpenStaff));
						}}>
						{isOpenStaff ? <Eye_on fillOpacity={0.6} /> : <Eye_off />}
					</ButtonStyledIcon>
				</InlineHeader>
				<RenderByCondition condition={!!isOpenStaff}>
					<>
						{positions.map((official, idx) => {
							const updateOfficial = (newValue: string) => updateInputOfInputArr(setPositions, idx, newValue);
							const updateInitials = (newValue: string) => updateInputOfInputArr(setInitials, idx, newValue);

							const deleteOfficial = () => {
								deleteFieldInput(setPositions, idx);
								deleteFieldInput(setInitials, idx);
								deleteFieldInput(setIsShowUserOfficials, idx);
							};

							const updateIsShowToUser = (idx: number) => {
								setIsShowUserOfficials((prevState) => {
									const newState = [...prevState];
									newState[idx] = !newState[idx];
									return newState;
								});
							};
							return (
								<React.Fragment key={idx}>
									<StaffBlock>
										<GridWrapper>
											<FormItem label={"Должность"} labelPosition={"vertical"}>
												<Input
													sizeInput={"medium"}
													value={official}
													onChange={(e) => updateOfficial(e.target.value)}
													maxLength={editOrCreateOfficeInputMaxLength.position}
												/>
											</FormItem>
											<FormItem label={"ФИО"} labelPosition={"vertical"}>
												<Input
													sizeInput={"medium"}
													value={initials[idx]}
													onChange={(e) => updateInitials(e.target.value)}
													maxLength={editOrCreateOfficeInputMaxLength.initials}
												/>
											</FormItem>
											<SwitchContainer>
												<Switch
													isActive={isShowUserOfficials[idx]}
													onChangeActive={() => updateIsShowToUser(idx)}
													label={"Отображать пользователям"}
													size={"medium"}
													labelPosition={"right"}
												/>
											</SwitchContainer>
										</GridWrapper>
										<ButtonStyled
											icon={Delete}
											typeButton={"primary"}
											size={"medium"}
											variant={"red"}
											shape="square"
											onClick={() =>
												onClickRemoveButton(deleteOfficial, {
													title: "Удалить должностное лицо",
													description: "Вы уверены, что хотите удалить должностное лицо?",
												})
											}
										/>
									</StaffBlock>
								</React.Fragment>
							);
						})}
					</>
				</RenderByCondition>

				<RenderByCondition condition={!!isOpenStaff}>
					<ButtonBlock>
						<Button size="medium" icon={Plus} typeButton={"secondary"} onClick={addNewOfficial}>
							Добавить должностное лицо
						</Button>
					</ButtonBlock>
				</RenderByCondition>

				<InlineHeader>
					<TextHeader>Телефоны</TextHeader>
					<ButtonStyledIcon
						typeButton={"secondary"}
						size={"medium"}
						shape="square"
						onClick={() => {
							localStorage.setItem("phone_is_shown", String(!isOpenPhone));
							setIsOpenPhone((init) => !init);
						}}>
						{isOpenStaff ? <Eye_on fillOpacity={0.6} /> : <Eye_off />}
					</ButtonStyledIcon>
				</InlineHeader>
				<RenderByCondition condition={!!isOpenPhone}>
					<>
						{phones.map((phone, idx) => {
							const updatePhone = (newValue: string) => updateInputOfInputArr(setPhones, idx, newValue);
							const updateDescription = (newValue: string) => updateInputOfInputArr(setPhoneDescriptions, idx, newValue);
							const updateIsShowToUser = (idx: number) => {
								setIsShowUserPhones((prevState) => {
									const newState = [...prevState];
									newState[idx] = !newState[idx];
									return newState;
								});
							};
							const deleteOfficial = () => {
								deleteFieldInput(setPhones, idx);
								deleteFieldInput(setPhoneDescriptions, idx);
								deleteFieldInput(setIsShowUserPhones, idx);
							};

							return (
								<>
									<StaffBlock>
										<GridWrapper>
											<FormItem label={"Заголовок"} labelPosition={"vertical"}>
												<Input
													sizeInput={"medium"}
													value={phonesDescriptions[idx]}
													onChange={(e) => updateDescription(e.target.value)}
													maxLength={editOrCreateOfficeInputMaxLength.phoneDescription}
												/>
											</FormItem>
											<FormItem label={"Телефон"} labelPosition={"vertical"}>
												<Input sizeInput={"medium"} value={phone} mask={mask.phone} onChange={(e) => updatePhone(e.target.value)} />
											</FormItem>
											<SwitchContainer>
												<Switch
													isActive={isShowUserPhones[idx]}
													onChangeActive={() => updateIsShowToUser(idx)}
													label={"Отображать пользователям"}
													size={"medium"}
													labelPosition={"right"}
												/>
											</SwitchContainer>
										</GridWrapper>
										<ButtonStyled
											icon={Delete}
											typeButton={"primary"}
											size={"medium"}
											variant={"red"}
											shape="square"
											onClick={() =>
												onClickRemoveButton(deleteOfficial, {
													title: "Удалить телефон",
													description: "Вы уверены, что хотите удалить телефон?",
												})
											}
										/>
									</StaffBlock>
								</>
							);
						})}
					</>
				</RenderByCondition>

				<RenderByCondition condition={!!isOpenPhone}>
					<ButtonBlock>
						<Button size="medium" icon={Plus} typeButton={"secondary"} onClick={addNewPhones}>
							Добавить телефон
						</Button>
					</ButtonBlock>
				</RenderByCondition>

				<InlineHeader>
					<TextHeader>Эл. почты</TextHeader>
					<ButtonStyledIcon
						typeButton={"secondary"}
						size={"medium"}
						shape="square"
						onClick={() => {
							localStorage.setItem("email_is_shown", String(!isOpenEmail));
							setIsOpenEmail((init) => !init);
						}}>
						{isOpenStaff ? <Eye_on fillOpacity={0.6} /> : <Eye_off />}
					</ButtonStyledIcon>
				</InlineHeader>
				<RenderByCondition condition={!!isOpenEmail}>
					<>
						{emails.map((email, idx) => {
							const updateEmail = (newValue: string) => updateInputOfInputArr(setEmails, idx, newValue);
							const updateEmailDescription = (newValue: string) => updateInputOfInputArr(setEmailsDescriptions, idx, newValue);
							const updateIsShowToUser = (idx: number) => {
								setIsShowUserEmails((prevState) => {
									const newState = [...prevState];
									newState[idx] = !newState[idx];
									return newState;
								});
							};
							const deleteOfficial = () => {
								deleteFieldInput(setEmails, idx);
								deleteFieldInput(setEmailsDescriptions, idx);
								deleteFieldInput(setIsShowUserEmails, idx);
							};

							return (
								<>
									<StaffBlock>
										<GridWrapper>
											<FormItem label={"Заголовок"} labelPosition={"vertical"}>
												<Input
													sizeInput={"medium"}
													value={emailsDescriptions[idx]}
													onChange={(e) => updateEmailDescription(e.target.value)}
													maxLength={editOrCreateOfficeInputMaxLength.emailDescription}
												/>
											</FormItem>
											<FormItem label={"Эл. почта"} labelPosition={"vertical"}>
												<Input
													sizeInput={"medium"}
													value={email}
													onChange={(e) => updateEmail(e.target.value)}
													maxLength={editOrCreateOfficeInputMaxLength.email}
												/>
											</FormItem>
											<SwitchContainer>
												<Switch
													isActive={isShowUserEmails[idx]}
													onChangeActive={() => updateIsShowToUser(idx)}
													label={"Отображать пользователям"}
													size={"medium"}
													labelPosition={"right"}
												/>
											</SwitchContainer>
										</GridWrapper>
										<ButtonStyled
											icon={Delete}
											typeButton={"primary"}
											size={"medium"}
											variant={"red"}
											shape="square"
											onClick={() =>
												onClickRemoveButton(deleteOfficial, {
													title: "Удалить эл. почту",
													description: "Вы уверены, что хотите удалить эл. почту?",
												})
											}
										/>
									</StaffBlock>
								</>
							);
						})}
					</>
				</RenderByCondition>

				<RenderByCondition condition={!!isOpenEmail}>
					<ButtonBlock>
						<Button size="medium" icon={Plus} typeButton={"secondary"} onClick={addNewEmails}>
							Добавить E-mail
						</Button>
					</ButtonBlock>
				</RenderByCondition>

				<PageEditTime {...timeState} />
			</ProfileWrapper>

			<PageEditDialogAreYouSureCancelEditOffice onContinue={() => profileUKVisibilityStore.cancelEdit()} />

			{isVisibleDeletionModal && (
				<BaseModal
					title={deletionModalText.current.title}
					description={deletionModalText.current.description}
					okText={"Да"}
					cancelText="Нет"
					isVisible
					status="delete"
					onCancel={() => setIsVisibleDeletionModal(false)}
					onOk={callbackAfterConfirmDeletion.current}
					onExit={() => setIsVisibleDeletionModal(false)}
				/>
			)}
		</>
	);
};

export default observer(PageEdit);

const ButtonStyledIcon = styled(Button)`
	z-index: 9;
	padding: 0px 8px !important;
`;
const ButtonStyled = styled(Button)`
	height: 32px !important;
	align-self: end;
	&:hover {
		border: none !important;
	}
`;

const WeekContainer = styled.div`
	gap: 16px;
	display: grid;
`;

const ProfileWrapper = styled.div`
	background: ${(props) => props.theme.theme.absolute_100};
	box-shadow: 0px 16px 24px 0px rgba(142, 141, 208, 0.12);
	border-radius: 16px;
	padding: 24px;
`;

const ProfileHeader = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 24px;
`;

const BasicInformation = styled.div``;

const ActionWrapper = styled.div`
	display: flex;
	align-items: center;
	> * {
		&:first-child {
			margin-right: 8px;
			svg {
				margin-right: 6px;
			}
		}
	}
`;

const ProfileDescription = styled.div`
	display: grid;
	grid-template-columns: min-content 1fr 1fr;
	margin-bottom: 24px;
	gap: 16px;
`;

const Column = styled.div`
	> * {
		> div:first-child {
			margin-bottom: 12px;
		}
	}

	& input {
		width: 100%;
	}

	& textarea {
		width: 100%;
	}
`;

const SwitchElement = styled.div`
	width: max-content;
	p {
		font-size: 14px;
		font-weight: 500;
		line-height: 22px;
		letter-spacing: -0.02em;
		text-align: left;
	}
`;

const InlineHeader = styled.div`
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-bottom: 20px;
`;

const GridWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 16px;
	width: inherit;

	&& > div {
		width: 100%;
		input {
			width: 100%;
		}
	}
`;

const TimeTableWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 16px;
	width: inherit;
`;

const StaffBlock = styled.div`
	display: flex;
	width: 100%;
	gap: 20px;
	margin-bottom: 12px;
	align-items: center;
	button {
		border: none;
		height: min-content;
	}
`;

const ButtonBlock = styled.div`
	button {
		margin-bottom: 24px;
	}
`;

const HoursShow = styled.div`
	margin-bottom: 17px;
	width: fit-content;
`;

const SwitchContainer = styled.div`
	display: flex;
	align-items: center;
	margin-top: 15px;
`;
