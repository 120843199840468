import React, { useState } from "react";

import { Space } from "antd";
import { useMutation } from "react-query";
import { Alert, Button } from "tap2visit-ui-kit";

import { updateBuildingObjectApi } from "api/api.realEstate";
import { Spacer } from "components/layout/Spacer";
import { queryClient } from "index";
import { QueriesKeys } from "interfaces/queriesKeys";
import { getBuildingObjectTypeTitle } from "utils/getBuildingObjectTypeTitle";

import UserEditInput from "../../../components/Citizens/components/UserEdit.input";
import CitizenBuildingObjectStore from "../../../components/Citizens/store/CitizenBuildingObject.store";
import {
	CitizenPersonalEdit,
	CitizenPersonalEditFooter,
	CitizenPersonalEditWrapper,
	CitizenRealtyDivider,
	CitizenRealtySpace,
	CitizenRealtyWrapper,
	PersonalInformationWrapper,
} from "../../../components/Citizens/style/Citizen.styled";
import { getBuildingTypeConfigurations } from "../utils/getBuildingTypeConfigurations";

import {
	CitizenDrawerInformationBlockDescription,
	CitizenDrawerInformationBlockHeader,
	CitizenDrawerTextHeaderBlock,
} from "./CitizenTexts";

const CitizenRealtyInformation = () => {
	const [edit, setEdit] = useState(false);

	const [totalArea, setTotalArea] = React.useState(CitizenBuildingObjectStore.selectedBuilding?.totalArea);
	const [livingArea, setLivingArea] = React.useState(CitizenBuildingObjectStore.selectedBuilding?.livingArea);
	const [terraceArea, setTerraceAre] = React.useState(CitizenBuildingObjectStore.selectedBuilding?.terraceArea);
	const buildingType = getBuildingObjectTypeTitle(CitizenBuildingObjectStore.selectedBuilding?.objectType);
	const updateBuildingObject = useMutation(updateBuildingObjectApi);

	const citizenPersonalInformation =
		getBuildingTypeConfigurations()[CitizenBuildingObjectStore.selectedBuilding?.objectType.toLowerCase()] || [];

	const onSave = async () => {
		await updateBuildingObject.mutateAsync({
			body: {
				flatType: CitizenBuildingObjectStore.selectedBuilding.flatType,
				livingArea: livingArea,
				terraceArea: terraceArea,
				totalArea: totalArea,
			},
			path: {
				id: CitizenBuildingObjectStore.selectedBuilding.id,
			},
		});
		setEdit(false);
		queryClient.invalidateQueries([QueriesKeys.citizenBuildingObjects]);
	};

	return (
		<>
			<PersonalInformationWrapper>
				<CitizenDrawerTextHeaderBlock text={`Информация о ${buildingType}`} />
				{/* TEMPORARY HIDDEN */}
				{/* <Button onClick={() => setEdit((status) => !status)} shape="square" typeButton="secondary" icon={EditIcon} /> */}
			</PersonalInformationWrapper>
			{edit ? (
				<>
					<Spacer px={8} />

					<CitizenPersonalEditWrapper>
						<CitizenPersonalEdit>
							<Alert
								visible
								withIcon
								closeIcon={false}
								variant="warning"
								title="При изменении типа помещения, общей площади, жилой площади предоставьте подтверждающие документы!"
							/>

							{citizenPersonalInformation.map((v, index) => (
								<UserEditInput
									key={index}
									label={v.label}
									required={v.required}
									value={v.value}
									as={v.as}
									onChange={v.onChange}
									disabled={v.disabled}
									message={v.message}
								/>
							))}
						</CitizenPersonalEdit>

						<CitizenPersonalEditFooter>
							<Button typeButton="secondary" onClick={() => setEdit(false)}>
								Отменить
							</Button>
							<Button onClick={onSave}>Сохранить</Button>
						</CitizenPersonalEditFooter>
					</CitizenPersonalEditWrapper>
				</>
			) : (
				<CitizenRealtySpace direction="vertical">
					<CitizenRealtyWrapper>
						{citizenPersonalInformation.map((v, index) => (
							<React.Fragment key={index}>
								<Space size={1} direction="vertical" style={{ padding: "12px" }}>
									<CitizenDrawerInformationBlockHeader text={v.label} />
									<CitizenDrawerInformationBlockDescription text={v.value} />
								</Space>
								<CitizenRealtyDivider />
							</React.Fragment>
						))}
					</CitizenRealtyWrapper>
				</CitizenRealtySpace>
			)}
		</>
	);
};

export default CitizenRealtyInformation;
