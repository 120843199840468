import { makeAutoObservable } from "mobx";

import { IAppeal, IApplicant, TAppealPriority } from "interfaces/IAppeals";

class DrawerStore {
	isOpenDrawer = false;
	isOpenCreateDrawer = false;
	selectedAppeals: IAppeal = {} as IAppeal;
	selectedSettingsAppeals: TAppealPriority | undefined | string = undefined;
	titleAppeal = "";
	descriptionAppeal = "";
	applicant: IApplicant = {} as IApplicant;
	selectButtonIsOwner = true;
	isOpenConnectionAppeal = false;

	constructor() {
		makeAutoObservable(this);
	}

	setSelectButtonIsOwner = (selectButtonIsOwner: boolean) => {
		this.selectButtonIsOwner = selectButtonIsOwner;
	};
	setIsOpenConnectionAppeal = (isOpenConnectionAppeal: boolean) => {
		this.isOpenConnectionAppeal = isOpenConnectionAppeal;
	};
	openDrawer = (selectedAppeals: any) => {
		this.isOpenDrawer = true;
		this.selectedAppeals = selectedAppeals;
	};

	setSelectedAppeals = (selectedAppeals: IAppeal) => {
		this.selectedAppeals = selectedAppeals;
	};
	setApplicant = (applicant: IApplicant | null) => {
		this.applicant = applicant;
	};
	setSelectedSettingsAppeals = (selectedSettingsAppeals: TAppealPriority) => {
		this.selectedSettingsAppeals = selectedSettingsAppeals;
	};

	setTitleAppeal = (titleAppeal: string) => {
		this.titleAppeal = titleAppeal;
	};

	setDescriptionAppeal = (descriptionAppeal: string) => {
		this.descriptionAppeal = descriptionAppeal;
	};

	openCreateDrawer = () => {
		this.isOpenCreateDrawer = true;
	};

	closeCreateDrawer = () => {
		this.isOpenCreateDrawer = false;
		this.selectedSettingsAppeals = undefined;
		this.titleAppeal = "";
		this.descriptionAppeal = "";
		this.applicant = {} as IApplicant;
	};
	closeDrawer = () => {
		this.isOpenDrawer = false;
	};
	clearSelectedAppeals = () => {
		this.selectedAppeals = {} as IAppeal;
	};
}

export default new DrawerStore();
