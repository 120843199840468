import React from "react";

import { Breadcrumbs } from "tap2visit-ui-kit";

const BuildingsEntranceHeader = () => (
	<Breadcrumbs
		location="/buildings/entrance"
		crumbs={[
			{ path: "/buildings", title: "Жилые комплексы" },
			{ path: `/buildings/house/${localStorage.getItem("complexId")}`, title: "Дома" },
			{ path: "/buildings/entrance", title: "Подъезды" },
		]}
	/>
);

export default BuildingsEntranceHeader;
