import React, { useEffect, useState } from "react";

import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import { Button, Checkbox, colors, Pagination, Search, Space, Text } from "tap2visit-ui-kit";

import { changeChildAppealApi, getAppealsListApi } from "api/api.gateway";
import BaseModal from "components/Modals/BaseModal";
import PaginationContainer from "components/PaginationContainer";
import BuildingFilterStore from "components/TableFilter/BuildingFilter.store";
import TableFilter from "components/TableFilter/TableFilter";
import { TableFilterContext } from "components/TableFilter/TableFilter.context";
import useTablePaginationLogic from "hooks/useTablePaginationLogic";
import { queryClient } from "index";
import { IAppeal, TGetAppealsListApiParams } from "interfaces/IAppeals";
import { QueriesKeys } from "interfaces/queriesKeys";
import AppealsStore from "pages/appeals/store/Appeals.store";
import DrawerStore from "pages/appeals/store/Drawer.store";
import { useDebounce } from "pages/appeals/utils/useDebounce";

const TableFilterInstanceStore = new BuildingFilterStore();

const AppealConnectionModal = () => {
	const [createdBySearchTitle, setCreatedBySearchTitle] = useState("");
	const paginationData = useTablePaginationLogic({ clientSizeNumber: 7 });
	const [connectionAppeals, setConnectionAppeals] = useState([]);
	const [connectionAppealsAll, setConnectionAppealsAll] = useState([]);
	const debouncedSearchConnection = useDebounce(createdBySearchTitle, 500);

	const appeals = useQuery({
		queryFn: () => {
			const params: TGetAppealsListApiParams = {
				buildingObjectIds: TableFilterInstanceStore?.selectedBuildingObjectIds,
				page: paginationData.clientsCurrentPage - 1,
				size: paginationData.clientsSize,
				titleSearch: debouncedSearchConnection,
				complexIds: TableFilterInstanceStore?.selectedComplexIds,
				buildingIds: TableFilterInstanceStore?.selectedBuildingsIds,
				entranceIds: TableFilterInstanceStore?.selectedEntrancesIds,
				floorIds: TableFilterInstanceStore?.selectedFloorsIds,
				hasChildAppeals: String(false),
				hasParentAppeal: String(false),
			};

			return getAppealsListApi({ params });
		},
		onSuccess(data) {
			setConnectionAppeals(
				data.content
					.filter((v) => v.id !== DrawerStore.selectedAppeals.id)
					.map((v) => ({
						...v,
						checked: false,
					})),
			);
		},
		queryKey: [
			QueriesKeys.appealsConnectionList,
			DrawerStore.selectedAppeals.id,
			AppealsStore.selectedTab,
			debouncedSearchConnection,
			TableFilterInstanceStore.selectedBuildingObjectIds,
			TableFilterInstanceStore?.selectedComplexIds,
			TableFilterInstanceStore?.selectedBuildingsIds,
			TableFilterInstanceStore?.selectedEntrancesIds,
			TableFilterInstanceStore?.selectedFloorsIds,
			paginationData.clientsSize,
			paginationData.clientsCurrentPage,
		],
	});

	useEffect(() => {
		queryClient.invalidateQueries([QueriesKeys.appealsConnectionList]);
	}, [debouncedSearchConnection]);

	React.useEffect(() => {
		if (appeals.data) {
			paginationData.setClientsTotal(appeals.data.totalPages);
		}
	}, [appeals.data?.page]);

	const changeChildAppealFunc = useMutation(changeChildAppealApi);

	const createChildAppeal = () => {
		changeChildAppealFunc
			.mutateAsync({
				args: {
					childAppealIds: [...DrawerStore.selectedAppeals.childAppealIds, ...connectionAppealsAll.map((v) => v.id)],
				},
				id: DrawerStore.selectedAppeals.id,
			})
			.then((res) => {
				DrawerStore.setSelectedAppeals(res);
				TableFilterInstanceStore.clearFilter();
				DrawerStore.setIsOpenConnectionAppeal(false);
			});
	};

	useEffect(() => {
		if (DrawerStore.isOpenDrawer) {
			queryClient.invalidateQueries([QueriesKeys.appealsConnectionList]);
		}
	}, [DrawerStore.isOpenDrawer]);

	const changeChecked = (v: IAppeal) => {
		const isAlreadyChecked = connectionAppealsAll?.some((item) => item.id === v.id);

		const updatedConnectionAppealsAll = isAlreadyChecked
			? connectionAppealsAll.filter((item) => item.id !== v.id)
			: [...connectionAppealsAll, v];

		const map = connectionAppeals.map((val) => ({
			...val,
			checked: val.id === v.id ? !val.checked : val.checked,
		}));

		setConnectionAppealsAll(updatedConnectionAppealsAll);
		setConnectionAppeals(map);
	};

	const clearFunc = () => {
		setConnectionAppealsAll([]);
		setConnectionAppeals(
			connectionAppeals.map((v) => ({
				...v,
				checked: false,
			})),
		);
		TableFilterInstanceStore.clearFilter();
	};

	return (
		<TableFilterContext.Provider value={TableFilterInstanceStore}>
			<BaseModal
				isVisible={DrawerStore.isOpenConnectionAppeal}
				title={"Добавить связь"}
				contentContainerStyle={{ height: "calc(80vh)", overflowY: "auto" }}
				onExit={() => DrawerStore.setIsOpenConnectionAppeal(false)}
				footerSlot={
					<Space size={24} width="100%" justify="flex-end">
						<Button onClick={clearFunc} typeButton="secondary">
							Сбросить
						</Button>
						<Button onClick={createChildAppeal}>Добавить связь</Button>
					</Space>
				}>
				<Space width="100%" align="start" direction="column">
					<Search value={createdBySearchTitle} onChangeValue={setCreatedBySearchTitle} sizeInput="medium" />
					<TableFilter
						customSwitchOptions={{
							size: "m",
							isVisible: false,
							isNotShowButton: true,
							isNotLabel: true,
						}}
					/>
					<CheckedTitle>
						<Text type="table-cell-m-regular" color={colors.textNeutralPrimary}>
							Выбрано
						</Text>
						<CheckedText>{connectionAppealsAll.length} обращений</CheckedText>
					</CheckedTitle>
					{connectionAppeals?.map((v) => (
						<AppealConnectionWrapper onClick={() => changeChecked(v)} key={v.id}>
							<AppealTitle>
								<AppealText type="table-cell-m-regular" color={colors.textNeutralSecondary}>
									{dayjs(v.createdAt).format("DD.MM.YYYY")}
								</AppealText>
								<AppealText type="table-cell-l-medium" color={colors.textNeutralPrimary}>
									№{v.number} {v.title}
								</AppealText>
								<AppealText type="table-cell-m-regular" color={colors.textNeutralSecondary}>
									{v.buildingObjectAddress?.addressValue}
								</AppealText>
							</AppealTitle>
							<Checkbox checked={v.checked} />
						</AppealConnectionWrapper>
					))}
					<PaginationContainer style={{ width: "100%" }}>
						<Pagination
							onChangeSize={paginationData.setClientsSize}
							currentPage={paginationData.clientsCurrentPage}
							setCurrentPage={paginationData.setClientsCurrentPage}
							total={paginationData.clientsTotal}
						/>
					</PaginationContainer>
				</Space>
			</BaseModal>
		</TableFilterContext.Provider>
	);
};

const CheckedTitle = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 12px;
	border-bottom: 1px solid #12121229;
	align-items: center;
`;
const CheckedText = styled.div`
	border: 1px dashed #12121229;
	border-radius: 10px;
	padding: 6px;
	background: #be98411f;
`;

const AppealText = styled(Text)``;
const AppealTitle = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
`;
const AppealConnectionWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #12121229;
	padding: 12px;
	width: 100%;
	align-items: center;
	cursor: pointer;
	align-items: center;
`;
export default observer(AppealConnectionModal);
