import React from "react";

import { TinyTable } from "tap2visit-ui-kit";

import { IRole } from "interfaces/IRole";
import getRoleTranslate from "utils/getRoleTranslate";

import { IClient } from "../../../interfaces/IClient";
import formatPhoneNumber from "../../../utils/format/formatPhone";

const AccessRequestCollapseTable = ({ item }: { item: IClient & { role: IRole[] } }) => {
	const phones = item.contacts.filter((contact) => contact.type === "PHONE").map((phone) => formatPhoneNumber(phone.value));
	const emails = item.contacts.filter((contact) => contact.type === "EMAIL").map((email) => email.value);

	const tableColumns = [
		["Роль", item.role?.length > 0 ? item.role.map((role) => getRoleTranslate(role?.name)).join(", ") : "Данные отсутствуют"],
		["Фамилия", item.lastName ?? "Данные отсутствуют"],
		["Имя", item.firstName ?? "Данные отсутствуют"],
		["Отчество", item.middleName ?? "Данные отсутствуют"],
		["ИНН", item.inn ?? "Данные отсутствуют"],
		["Логин приложения", formatPhoneNumber(item.contacts.find((v) => v.type === "PHONE" && v.isDefault)?.value) || "Данные отсутствуют"],
		...phones.map((phone) => ["Телефон для связи", phone || "Данные отсутствуют"]),
		...emails.map((email) => ["Эл. почта", email || "Данные отсутствуют"]),
		["Эл. почта", item.contacts.find((v) => v.type === "EMAIL")?.value || "Данные отсутствуют"],
	];

	return <TinyTable containerWidth={"1050px"} rows={tableColumns} />;
};

export default AccessRequestCollapseTable;
