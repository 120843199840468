import { TAppealPriority } from "interfaces/IAppeals";

import CriticalSvg from "../img/CRITICAL.svg";
import { ReactComponent as CriticalPrioritySvg } from "../img/CRITICAL.svg";
import LowSvg from "../img/LOW.svg";
import { ReactComponent as LowPrioritySvg } from "../img/LOW.svg";
import MediumSvg from "../img/MEDIUM.svg";
import { ReactComponent as MediumPrioritySvg } from "../img/MEDIUM.svg";

export const getPriority = (priority: TAppealPriority) => {
	if (priority === "CRITICAL") {
		return {
			title: "Критический",
			img: CriticalSvg,
		};
	}
	if (priority === "MEDIUM") {
		return {
			title: "Средний",
			img: MediumSvg,
		};
	}
	if (priority === "LOW") {
		return {
			title: "Низкий",
			img: LowSvg,
		};
	}
};

export const filterPriority = [
	{ text: "Критический", key: "CRITICAL" },
	{ text: "Средний", key: "MEDIUM" },
	{ text: "Низкий", key: "LOW" },
	{ text: "Не назначен", key: "NONE" },
];

export const itemsPriority = [
	{ text: "Критический", key: "CRITICAL", focused: false, icon: CriticalPrioritySvg },
	{ text: "Средний", key: "MEDIUM", focused: false, icon: MediumPrioritySvg },
	{ text: "Низкий", key: "LOW", focused: false, icon: LowPrioritySvg },
];
