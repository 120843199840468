import { makeAutoObservable } from "mobx";
import { TYPE_DATA } from "../utils/const";

export interface IDataList {
	focused: boolean;
	key: string;
	text: string;
}

class UpdateDataStore {
	complexesIds: IDataList[] = [];
	buildingIds: IDataList[] = [];
	buildingTypes: IDataList[] = TYPE_DATA;
	uploadBucketFileId: string | undefined = undefined;
	isNeedToValidate = false;
	file: File | undefined = undefined;

	constructor() {
		makeAutoObservable(this);
	}

	setFile(file: File) {
		this.file = file;
	}

	setIsNeedToValidate(isNeedToValidate: boolean) {
		this.isNeedToValidate = isNeedToValidate;
	}

	setUploadBucketFileId(id: string) {
		this.uploadBucketFileId = id;
	}

	setComplexes(complexes: IDataList[]) {
		this.complexesIds = complexes;
	}

	setBuildingTypes(typeData: IDataList[]) {
		this.buildingTypes = typeData;
	}

	setBuildings(complexes: IDataList[]) {
		this.buildingIds = complexes;
	}

	getUploadBucketFileId() {
		return this.uploadBucketFileId;
	}

	getComplexes() {
		return this.complexesIds;
	}

	getTypesData() {
		return this.buildingTypes;
	}

	getBuildings() {
		return this.buildingIds;
	}

	getRequestData() {
		return {
			complexesIds: this.complexesIds.filter((item) => item.focused).map((item) => item.key),
			buildingIds: this.buildingIds.filter((item) => item.focused).map((item) => item.key),
			buildingTypes: this.buildingTypes.filter((item) => item.focused).map((item) => item.key),
		};
	}

	getFile() {
		return this.file;
	}

	resetData() {
		this.complexesIds = [];
		this.buildingIds = [];
		this.buildingTypes = TYPE_DATA;
		this.file = undefined;
	}
}

export default new UpdateDataStore();
