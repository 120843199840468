import { Permissions } from "constants/constants.roles";
import type { IUser } from "interfaces/User/IUser";

export const allUsers: IUser[] = [
	{
		id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
		email: "plushkin@gmail.com",
		mobilePN: "+7 800 000 00 00",
		role: Permissions.ADMIN,
		firstName: "Василий",
		lastName: "Плюшкин",
		middleName: "Борисович",
		isPasswordSet: true,
		residentialAddress: "123",
		series: "",
		number: "",
		issueBy: "",
		dateOfIssue: "",
		departmentCode: "",
		dateOfBirth: "",
		expirationDate: "",
		share: "",
		address: "",
		lastLogin: "",
		firstLogin: "",
		comment: "",
	},
];
