import React, { FC } from "react";

import { observer } from "mobx-react-lite";
import { Routes } from "react-router-dom";

import useRedirect from "./hooks/useRedirect";

interface IExtendedRoutes {
	children: React.ReactNode;
	publicPaths?: string[];
}

const ExtendedRoutes: FC<IExtendedRoutes> = (props) => {
	useRedirect({ publicPaths: ["/login"] });
	return <Routes>{props.children}</Routes>;
};

export default observer(ExtendedRoutes);
