export default (number: string) => {
	if (!number) {
		return number;
	}
	const cleaned = number.replace(/\D/g, "");

	if (cleaned.length === 11 && cleaned.startsWith("7")) {
		const formatted = `+${cleaned[0]} ${cleaned.slice(1, 4)} ${cleaned.slice(4, 7)}-${cleaned.slice(7, 9)}-${cleaned.slice(9, 11)}`;
		return formatted;
	}

	return number;
};
