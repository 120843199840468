import React, { useEffect, useState } from "react";

import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { useQuery } from "react-query";
import { colors, Drawer, Space, Text } from "tap2visit-ui-kit";

import { getAppealByIdApi } from "api/api.gateway";
import BaseModal from "components/Modals/BaseModal";
import { queryClient } from "index";
import { IAppeal } from "interfaces/IAppeals";
import { QueriesKeys } from "interfaces/queriesKeys";
import DrawerStore from "pages/appeals/store/Drawer.store";
import CitizenCommentsCollapse from "pages/citizens/components/CitizenComments.collapse";

import AppealAdress from "./AppealAdress";
import AppealApplicant from "./AppealApplicant";
import AppealCloseDrawer from "./AppealCloseDrawer";
import AppealConnection from "./AppealConnection";
import AppealDescription from "./AppealDescription";
import AppealFeedback from "./AppealFeedback";
import AppealSpecialist from "./AppealSpecialist";
import AppealStatus from "./AppealStatus";

const AppealItemDrawer = () => {
	const [isOpenModal, setIsOpenModal] = useState(false);
	const { data }: { data: IAppeal } = useQuery({
		queryFn: () => getAppealByIdApi(DrawerStore.selectedAppeals.id),
		queryKey: [QueriesKeys.appealsById, DrawerStore.selectedAppeals.id],
		enabled: !!DrawerStore.selectedAppeals.id,
		onSuccess(data) {
			DrawerStore.setSelectedAppeals(data);
		},
	});

	const closeDrawer = () => {
		if (
			data?.applicant.firstName !== DrawerStore.selectedAppeals.applicant.firstName ||
			data?.applicant.emails[0] !== DrawerStore.selectedAppeals.applicant.emails[0] ||
			data?.applicant.phoneNumbers[0] !== DrawerStore.selectedAppeals.applicant.phoneNumbers[0] ||
			data.title !== DrawerStore.selectedAppeals.title ||
			data.description !== DrawerStore.selectedAppeals.description
		) {
			return setIsOpenModal(true);
		}

		DrawerStore.closeDrawer();
	};
	useEffect(() => {
		if (DrawerStore.isOpenDrawer) {
			queryClient.invalidateQueries([QueriesKeys.appealsById]);
		}
	}, [DrawerStore.isOpenDrawer, DrawerStore.selectedAppeals.id]);

	return (
		<Drawer
			backgroundBody={colors.surfaceNeutralBg2}
			onClose={closeDrawer}
			showCancel={false}
			onCancel={closeDrawer}
			showOk={false}
			title={`Обращение № ${DrawerStore.selectedAppeals.number}`}
			width="560px"
			visible={DrawerStore.isOpenDrawer}>
			<Space align="start" direction="column" size={20}>
				<Text color={colors.textNeutralSecondary} type="base-regular">
					Дата обращения {dayjs(DrawerStore.selectedAppeals.createdAt).format("DD.MM.YYYY HH:mm")}
				</Text>
				<AppealDescription />
				<CitizenCommentsCollapse type="appeals" />
				<AppealAdress />
				<AppealStatus />
				<AppealSpecialist />
				<AppealConnection />
				<AppealApplicant />
				{DrawerStore.selectedAppeals.notification && <AppealFeedback />}
				<AppealCloseDrawer />
			</Space>
			<BaseModal
				isVisible={isOpenModal}
				okText="Продолжить"
				onCancel={() => setIsOpenModal(false)}
				onOk={() => {
					DrawerStore.closeDrawer();
					setIsOpenModal(false);
				}}
				cancelText="Отменить"
				status="delete"
				title="Не сохраненные изменения будут потеряны"
				description="Вы внесли изменения и не сохранили их"
			/>
		</Drawer>
	);
};

export default observer(AppealItemDrawer);
