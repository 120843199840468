import React, { useEffect, useState } from "react";

import { useQuery } from "react-query";
import { Avatar } from "tap2visit-ui-kit";

import { downloadFileApi } from "api/api.storage";
import { queryClient } from "index";
import { QueriesKeys } from "interfaces/queriesKeys";

interface IAvatarChat {
	avatarId?: string;
	lastName: string;
	firstName: string;
}
const AvatarChat = ({ avatarId, firstName, lastName }: IAvatarChat) => {
	const [img, setImg] = useState("");
	useQuery({
		queryFn: () => {
			if (avatarId) {
				return downloadFileApi(avatarId);
			}
			setImg(undefined);
		},
		enabled: !!avatarId,
		queryKey: [QueriesKeys.downloadFileAvatarChat, avatarId],
		onSuccess: (data) => {
			if (data) {
				const blob = new Blob([data]);
				const imgLink = URL.createObjectURL(blob);
				setImg(imgLink);
				return;
			}
			setImg(undefined);
		},
	});

	useEffect(() => {
		if (!avatarId) {
			setImg(undefined);
		}
		queryClient.invalidateQueries([QueriesKeys.downloadFileAvatarChat, avatarId]);
	}, [avatarId]);

	return <Avatar img={img ? img : undefined} size="32" firstName={firstName} lastName={lastName} />;
};

export default AvatarChat;
