import React, { useEffect, useState } from "react";

import { observer } from "mobx-react-lite";
import { useQuery } from "react-query";
import styled from "styled-components";
import { ButtonGroup, FormItem, Input, SelectV2, Space, TContextMenuItem, Text } from "tap2visit-ui-kit";

import { getClientsListApi } from "api/api.clients";
import { getClientsByBuildingObjectAndRoleApi, getRolesApi } from "api/api.realEstate";
import { useTableFilterContext } from "components/TableFilter/TableFilter.context";
import { mask } from "constants/constants.mask";
import { queryClient } from "index";
import { QueriesKeys } from "interfaces/queriesKeys";
import { ValidationStatuses } from "modules/validation/Validation.store";
import AppealsCreateValidationStore from "pages/appeals/store/AppealsCreateValidationStore";
import DrawerStore from "pages/appeals/store/Drawer.store";

const AppealsApplicantDrawer = ({ isViewDrawer }: { isViewDrawer?: boolean }) => {
	const [selectButton, setSelectButton] = useState<"OWNER" | "NOTOWNER">("OWNER");
	const [fullName, setFullName] = useState("");
	const [items, setItems] = useState<TContextMenuItem[]>([]);
	const [isOpenSelect, setIsOpenSelect] = useState(false);
	const tableFilterContext = useTableFilterContext();
	const validationFields = AppealsCreateValidationStore.validationData;
	const errorMessageFio = AppealsCreateValidationStore.getMessageIfError("fio");
	const errorMessagePhone = AppealsCreateValidationStore.getMessageIfError("phone");
	const errorMessageEmail = AppealsCreateValidationStore.getMessageIfError("email");
	const errorMessageLogin = AppealsCreateValidationStore.getMessageIfError("login");

	const roles = useQuery({
		queryFn: () => getRolesApi(),
		queryKey: [QueriesKeys.roles],
		staleTime: Infinity,
	});

	const { data: clientsListByObjectId } = useQuery({
		queryFn: () =>
			getClientsByBuildingObjectAndRoleApi({
				buildingObjectIds:
					tableFilterContext.selectedBuildingObjectIds[0] ?? DrawerStore.selectedAppeals.buildingObjectAddress.buildingObjectId,
				roleIds: roles.data.find((v) => v.name === "OWNER")?.id,
			}),
		queryKey: [
			QueriesKeys.clients,
			tableFilterContext.selectedBuildingObjectIds?.length,
			roles.data,
			DrawerStore.selectedAppeals.buildingObjectAddress,
		],
		enabled:
			(!!tableFilterContext.selectedBuildingObjectIds?.length || !!DrawerStore.selectedAppeals?.buildingObjectAddress?.buildingObjectId) &&
			!!roles.data.find((v) => v.name === "OWNER")?.id,
	});

	useEffect(() => {
		if (tableFilterContext.selectedBuildingObjectIds.length) {
			queryClient.invalidateQueries([QueriesKeys.clients]);
		}
	}, [tableFilterContext.selectedBuildingObjectIds]);

	useQuery({
		queryFn: () => {
			if (clientsListByObjectId.map((v) => v.clientId)?.length) {
				return getClientsListApi({ params: { clientIds: clientsListByObjectId.map((v) => v.clientId) } });
			}
			return [];
		},
		queryKey: [QueriesKeys.buildingObjectClientsDetail, clientsListByObjectId],
		enabled: !!clientsListByObjectId?.length,
		onSuccess(data) {
			setItems(
				data.map((v) => ({
					key: v.id,
					text: `${v.lastName} ${v.firstName} ${v.middleName}`,
					focused: false,
				})),
			);
		},
	});

	const buttons = [
		{
			text: "Собственник",
			selected: selectButton === "OWNER",
			disabled: isViewDrawer && !DrawerStore.selectedAppeals.applicant.isOwner,
			onClick: () => {
				setSelectButton("OWNER");
				DrawerStore.setSelectButtonIsOwner(true);
			},
		},
		{
			text: "Не собственник",
			selected: selectButton === "NOTOWNER",
			disabled: isViewDrawer && DrawerStore.selectedAppeals.applicant.isOwner,
			onClick: () => {
				setSelectButton("NOTOWNER");
				DrawerStore.setSelectButtonIsOwner(false);
			},
		},
	];

	useEffect(() => {
		if (isViewDrawer && !DrawerStore.selectedAppeals.applicant.isOwner) {
			setSelectButton("NOTOWNER");
		} else {
			setSelectButton("OWNER");
		}
	}, [isViewDrawer, DrawerStore.selectedAppeals?.applicant?.isOwner]);

	useEffect(() => {
		setFullName(items.find((v) => v.focused)?.text);
	}, [items]);

	useEffect(() => {
		if (DrawerStore.selectedAppeals?.applicant?.isOwner) {
			const lastName = DrawerStore.selectedAppeals.applicant.lastName;
			const firstName = DrawerStore.selectedAppeals.applicant.firstName;
			const middleName = DrawerStore.selectedAppeals.applicant.middleName ?? "";

			setFullName(`${lastName} ${firstName} ${middleName}`);
		}
	}, [DrawerStore.selectedAppeals.applicant]);

	useEffect(() => {
		if (
			tableFilterContext.selectedFloorsIds.length > 0 ||
			tableFilterContext.selectedEntrancesIds.length > 0 ||
			tableFilterContext.selectedComplexIds.length > 0 ||
			tableFilterContext.selectedBuildingsIds.length > 0 ||
			tableFilterContext.selectedBuildingObjectIds.length > 0
		)
			setFullName("");
	}, [
		tableFilterContext.selectedFloorsIds,
		tableFilterContext.selectedEntrancesIds,
		tableFilterContext.selectedComplexIds,
		tableFilterContext.selectedBuildingsIds,
		tableFilterContext.selectedBuildingObjectIds,
	]);

	return (
		<Space width="100%" direction="column" align="start" size={8}>
			{!isViewDrawer && <Text h="h5">Заявитель</Text>}
			<AppealsDrawerWrapper isViewDrawer={isViewDrawer}>
				<Space>
					<ButtonGroup buttons={buttons} />
				</Space>
				{selectButton === "OWNER" && (
					<FormItem isRequiredStarLeft required label="Житель">
						<SelectV2
							isDisabled={
								(isViewDrawer && !DrawerStore.selectedAppeals.buildingObjectAddress.buildingObjectId) ||
								(!isViewDrawer && tableFilterContext.selectedBuildingObjectIds.length === 0)
							}
							selectItemsListWidth="475px"
							size="large"
							onChange={(value) => {
								setFullName(value);
							}}
							value={fullName}
							items={items}
							onClickSelect={() => setIsOpenSelect(true)}
							isOpened={isOpenSelect}
							onClose={() => setIsOpenSelect(false)}
							onClickSelectItem={(item) => {
								setIsOpenSelect(false);
								setItems(
									items.map((v) => ({
										...v,
										focused: v.key === item.key,
									})),
								);
								DrawerStore.setApplicant({ ...DrawerStore.applicant, clientId: item.key });
								DrawerStore.setSelectedAppeals({
									...DrawerStore.selectedAppeals,
									applicant: {
										...DrawerStore.selectedAppeals.applicant,
										clientId: item.key,
									},
								});
							}}
							placeholder="Введите  ФИО"
						/>
					</FormItem>
				)}
				{selectButton === "NOTOWNER" && (
					<>
						<FormItem
							state={validationFields.fio !== ValidationStatuses.VALID ? "error" : "default"}
							message={errorMessageFio}
							isRequiredStarLeft
							required
							label="ФИО">
							<Input
								state={validationFields.fio !== ValidationStatuses.VALID ? "error" : "default"}
								value={
									isViewDrawer
										? `${DrawerStore.selectedAppeals.applicant.lastName} ${DrawerStore.selectedAppeals.applicant.firstName} ${
												DrawerStore.selectedAppeals.applicant?.middleName ?? ""
											}`
										: DrawerStore.applicant.firstName
								}
								onChange={(e) => {
									isViewDrawer
										? DrawerStore.setSelectedAppeals({
												...DrawerStore.selectedAppeals,
												applicant: {
													...DrawerStore.selectedAppeals.applicant,
													lastName: e.target.value.split(" ")[0],
													firstName: e.target.value.split(" ")[1],
													middleName: e.target.value.split(" ")[2],
												},
											})
										: DrawerStore.setApplicant({ ...DrawerStore.applicant, firstName: e.target.value });
								}}
								sizeInput="large"
								placeholder="Иванов Иван Петрович"
							/>
						</FormItem>
						<FormItem
							state={validationFields.login !== ValidationStatuses.VALID ? "error" : "default"}
							message={errorMessageLogin}
							label="Логин в приложении">
							<Input
								state={validationFields.login !== ValidationStatuses.VALID ? "error" : "default"}
								value={isViewDrawer ? DrawerStore.selectedAppeals.applicant.login : DrawerStore.applicant.login}
								onChange={(e) =>
									isViewDrawer
										? DrawerStore.setSelectedAppeals({
												...DrawerStore.selectedAppeals,
												applicant: {
													...DrawerStore.selectedAppeals.applicant,
													login: e.target.value,
												},
											})
										: DrawerStore.setApplicant({ ...DrawerStore.applicant, login: e.target.value })
								}
								sizeInput="large"
								mask={mask.phone}
								maskChair=""
								placeholder="+7 000 000-00-00"
							/>
						</FormItem>
						<FormItem
							state={validationFields.phone !== ValidationStatuses.VALID ? "error" : "default"}
							isRequiredStarLeft
							required
							message={errorMessagePhone}
							label="Телефон для связи">
							<Input
								state={validationFields.phone !== ValidationStatuses.VALID ? "error" : "default"}
								value={
									isViewDrawer ? DrawerStore.selectedAppeals.applicant.phoneNumbers[0] : (DrawerStore.applicant?.phoneNumbers?.[0] ?? "")
								}
								onChange={(e) =>
									isViewDrawer
										? DrawerStore.setSelectedAppeals({
												...DrawerStore.selectedAppeals,
												applicant: {
													...DrawerStore.selectedAppeals.applicant,
													phoneNumbers: [e.target.value],
												},
											})
										: DrawerStore.setApplicant({ ...DrawerStore.applicant, phoneNumbers: [e.target.value] })
								}
								sizeInput="large"
								mask={mask.phone}
								maskChair=""
								placeholder="+7 000 000-00-00"
							/>
						</FormItem>
						<FormItem
							state={validationFields.email !== ValidationStatuses.VALID ? "error" : "default"}
							message={errorMessageEmail}
							isRequiredStarLeft
							required
							label="Эл. почта">
							<Input
								state={validationFields.email !== ValidationStatuses.VALID ? "error" : "default"}
								value={isViewDrawer ? DrawerStore.selectedAppeals.applicant.emails[0] : (DrawerStore.applicant.emails?.[0] ?? "")}
								onChange={(e) =>
									isViewDrawer
										? DrawerStore.setSelectedAppeals({
												...DrawerStore.selectedAppeals,
												applicant: {
													...DrawerStore.selectedAppeals.applicant,
													emails: [e.target.value],
												},
											})
										: DrawerStore.setApplicant({ ...DrawerStore.applicant, emails: [e.target.value] })
								}
								sizeInput="large"
								placeholder="example@example.com"
							/>
						</FormItem>
					</>
				)}
			</AppealsDrawerWrapper>
		</Space>
	);
};

const AppealsDrawerWrapper = styled.div<{ isViewDrawer?: boolean }>`
	padding: 18px;
	border-radius: 12px;
	width: 100%;
	gap: 15px;
	display: flex;
	flex-direction: column;
	background: ${({ isViewDrawer }) => (!isViewDrawer ? "#12121205" : "none")};
	padding: ${({ isViewDrawer }) => (!isViewDrawer ? "18px" : "0")};
`;
export default observer(AppealsApplicantDrawer);
