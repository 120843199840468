import React, { useEffect, useState } from "react";

import { Space } from "antd";
import { observer } from "mobx-react-lite";
import { useQuery } from "react-query";
import styled from "styled-components";
import { Avatar, Button, Pagination, Text, colors } from "tap2visit-ui-kit";

import { getBuildingObjectsPageApi } from "api/api.gateway";
import BaseModal from "components/Modals/BaseModal";
import { RealEstateBuildingFilterStore } from "components/TableFilter/BuildingFilter.store";
import { queryClient } from "index";
import type { IBuildingObject, IBuildingObjectPageItem } from "interfaces/IBuilding";

import { getAliasesApi } from "../../../api/api.aliases";
import { getBuildingObjectsApi } from "../../../api/api.realEstate";
import useTablePaginationLogic from "../../../hooks/useTablePaginationLogic";
import { QueriesKeys } from "../../../interfaces/queriesKeys";
import { getBuildingObjectType } from "../../../utils/getBuildingObjectType";
import PaginationContainer from "../../PaginationContainer";
import CitizensDrawerStore from "../store/Citizens.drawer.store";
import { CitizenRealtyCreateDivider, FooterButtonWrapper, FooterWrapper } from "../style/Citizen.styled";
import { buttonsArrayHeaderRealty } from "../utils/Citizen.utils";

import CitizenChoiceButton from "./CitizenChoiceButton";
import CitizenRealityFilters from "./CitizenRealityFilters";
import CitizenRoleModal from "./CitizenRoleModal";

interface ICitizenRealtyCreate {
	open: boolean;
	setOpen: (v: boolean) => void;
}

type TBuildingObjectsWithAddress = IBuildingObject & { title: string };

const CitizenRealtyCreate = ({ open, setOpen }: ICitizenRealtyCreate) => {
	const paginationData = useTablePaginationLogic();
	const [openRoleDrawer, setOpenRoleDrawer] = useState(false);
	const [buttonsArrayHeader, setButtonsArrayHeader] = useState(buttonsArrayHeaderRealty);
	const [buildingObjectsMap, setBuildingObjectsMap] = useState<TBuildingObjectsWithAddress[]>([]);
	const [selectedBuildingId, setSelectedBuildingId] = useState<string>();

	const selectedBuildingObject = buildingObjectsMap.find((buildingObject) => buildingObject.id === selectedBuildingId);

	const buildingObjects = useQuery({
		queryFn: () => getBuildingObjectsApi(),
		queryKey: [QueriesKeys.buildingObjects],
	});

	const aliasesIds = buildingObjects.data
		?.filter((v) => v.aliasId)
		.map((v) => v.aliasId)
		.slice(0, 10);

	const aliasesMap = useQuery({
		queryFn: () => getAliasesApi({ params: { aliasIds: aliasesIds } }),
		queryKey: [QueriesKeys.aliases, ...(aliasesIds ?? [])],
		enabled: !!aliasesIds?.length,
	});

	const buildingObjectsPage = useQuery({
		queryFn: () =>
			getBuildingObjectsPageApi({
				params: {
					page: paginationData.clientsCurrentPage - 1,
					size: paginationData.clientsSize,
					sort: [],
					types: buttonsArrayHeader.find((button) => button.active)?.key,
					complexIds: RealEstateBuildingFilterStore.selectedComplexIds,
					buildingIds: RealEstateBuildingFilterStore.selectedBuildingsIds,
					floorIds: RealEstateBuildingFilterStore.selectedFloorsIds,
					entranceIds: RealEstateBuildingFilterStore.selectedEntrancesIds,
				},
			}),
		queryKey: [
			QueriesKeys.buildingObjectsPage,
			paginationData.clientsSize,
			paginationData.clientsCurrentPage,
			buttonsArrayHeader,
			RealEstateBuildingFilterStore.selectedComplexIds,
			RealEstateBuildingFilterStore.selectedBuildingsIds,
			RealEstateBuildingFilterStore.selectedFloorsIds,
			RealEstateBuildingFilterStore.selectedEntrancesIds,
		],
	});

	useEffect(() => {
		if (buildingObjectsPage.data) {
			paginationData.setClientsTotal(buildingObjectsPage.data.totalPages);
		}
	}, [buildingObjectsPage.data]);

	useEffect(() => {
		if (buildingObjects.data) {
			queryClient.invalidateQueries([QueriesKeys.aliases]);
		}
	}, [buildingObjects.data]);

	useEffect(() => {
		if (aliasesMap.data && buildingObjects.data) {
			setBuildingObjectsMap(
				buildingObjects.data.map((v) => ({
					...v,
					objectType: v.objectType,
					active: false,
					title: aliasesMap.data.find((val) => v.aliasId === val.id)?.value,
				})),
			);
		}
	}, [aliasesMap.data, buildingObjects.data]);

	useEffect(() => {
		if (!open) {
			setSelectedBuildingId(undefined);
		}
	}, [open]);

	const setSelectedClient = (building: IBuildingObjectPageItem) => {
		setSelectedBuildingId(building.id);
	};

	return (
		<>
			<CitizenRoleModal
				setOpen={setOpenRoleDrawer}
				open={openRoleDrawer}
				selectedCitizenId={CitizensDrawerStore.selectedUser.id}
				selectedBuildingAddress={selectedBuildingObject?.title}
				selectedBuildingId={selectedBuildingObject?.id}
				closeSelectUserDrawer={() => setOpen(false)}
			/>

			<BaseModal
				isVisible={open}
				title="Недвижимость"
				onCancel={() => setOpen(false)}
				onExit={() => setOpen(false)}
				contentContainerStyle={{ height: "calc(100vh - 250px)", overflowY: "auto" }}
				footerSlot={
					<FooterWrapper id="footer">
						<PaginationContainerWrapper>
							<Pagination
								currentPage={paginationData.clientsCurrentPage}
								setCurrentPage={paginationData.setClientsCurrentPage}
								total={paginationData.clientsTotal}
							/>
						</PaginationContainerWrapper>
						<CitizenRealtyCreateDivider />
						<FooterButtonWrapper>
							<Button onClick={() => setOpen(false)} typeButton="secondary">
								Отменить
							</Button>
							<Button
								disabled={!selectedBuildingObject}
								onClick={() => {
									setOpenRoleDrawer(true);
								}}>
								Продолжить
							</Button>
						</FooterButtonWrapper>
					</FooterWrapper>
				}>
				<ContentContainer id="hello">
					<SpaceWrapper direction="vertical" size={20}>
						<Space>
							{buttonsArrayHeader.map((v) => (
								<Button
									onClick={() => {
										setButtonsArrayHeader(
											buttonsArrayHeader.map((val) => ({
												...val,
												active: val.title === v.title,
											})),
										);
									}}
									typeButton={v.active ? "primary" : "default"}>
									{v.title}
								</Button>
							))}
						</Space>

						<CitizenRealityFilters />

						{buildingObjectsPage.data?.content?.map((v) => (
							<React.Fragment key={v.id}>
								<SpaceRealtyWrapper>
									<Space>
										<Avatar firstName="1" lastName="2" size="32" />
										<Space size={0} direction="vertical">
											<Text type="table-cell-l-medium" color={colors.textNeutralPrimary}>
												{v.address}
											</Text>
											<Text type="table-cell-m-regular" color={colors.textNeutralSecondary}>
												{getBuildingObjectType(v.type)}
											</Text>
										</Space>
									</Space>
									<CitizenChoiceButton isActive={selectedBuildingId === v.id} onClick={() => setSelectedClient(v)} />
								</SpaceRealtyWrapper>
								<CitizenRealtyCreateDivider />
							</React.Fragment>
						))}
					</SpaceWrapper>
				</ContentContainer>
			</BaseModal>
		</>
	);
};

export default observer(CitizenRealtyCreate);

const PaginationContainerWrapper = styled(PaginationContainer)`
	padding-bottom: 20px;
`;

const SpaceRealtyWrapper = styled(Space)`
	width: 100%;
	justify-content: space-between;
`;

const SpaceWrapper = styled(Space)`
	width: 100%;
`;

const ContentContainer = styled.div`
	width: 100%;
`;
