import { makeAutoObservable } from "mobx";

class AccessRequestCanApproveRequestStore {
	canApproveRequest = false;

	constructor() {
		makeAutoObservable(this);
	}

	setCanApproveRequest = () => {
		this.canApproveRequest = true;
	};

	dispose = () => {
		this.canApproveRequest = false;
	};
}

export default new AccessRequestCanApproveRequestStore();
