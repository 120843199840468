import { Divider } from "antd";
import styled from "styled-components";
import { colors } from "tap2visit-ui-kit";

import { ACCESS_REQUEST_COLUMN_DATA_WIDTH, ACCESS_REQUEST_COLUMN_DEFINITION_WIDTH } from "../AccessRequestUserTable";

const AccessRequestCollapseTitle = styled.p`
	font-weight: 700;
`;

const LabelWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const CustomDivider = styled(Divider)`
	margin: 13px 0 24px 0;
`;

const AccessRequestWrapperBottom = styled.div`
	margin: 34px 0 14px 0;
`;
const AccessRequestBottomTitle = styled.p`
	font-weight: 700;
	font-size: 20px;
`;
const AccessRequestBottomSubTitle = styled.p`
	font-size: 14px;
`;

const AccessRequestDrawerWidth = styled.div`
	width: 211px;
	display: flex;
	position: relative;
	height: 100%;
`;
const AccessRequestWrapperTitleTable = styled.div`
	display: flex;
	margin: 30px 0;
	align-items: center;
`;

const AccessRequestUserTableBorder = styled.div`
	border: 2px solid ${colors.strokeDivider};
	position: absolute;
	width: calc(${ACCESS_REQUEST_COLUMN_DATA_WIDTH} + 1px); // border width
	height: 102%;
	left: calc(${ACCESS_REQUEST_COLUMN_DEFINITION_WIDTH} - 1px); // border width
	top: -2%;
	border-radius: 6px;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
	box-shadow: 0 4px 7px -2px #4b4b4b0f;
`;
const AccessRequestUserTableWrapper = styled.div`
	position: relative;
`;

const WrapperUser = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
const UserName = styled.p`
	font-size: 16px;
	font-weight: 500;
`;
const UserNameSubtitle = styled.p`
	font-size: 14px;
	font-weight: 450;
	color: #12121299;
`;

const WrapperUserName = styled.div`
	margin-left: 10px;
`;

const WrapperUserAvatar = styled.div`
	display: flex;
`;

const TableAvatarContainer = styled.div`
	position: relative;
	top: 10px;
	left: -16px;
`;

const ComparedUserAvatarContainer = styled.div`
	position: absolute;
	top: 10px;
	left: 10px;
`;

const ComparedUserAvatarWrapper = styled.div`
	width: 215px;
	height: 58px;
	position: absolute;
	overflow-x: auto;
	overflow-y: hidden;
	top: -25px;
	left: -10px;
`;

export {
	ComparedUserAvatarWrapper,
	ComparedUserAvatarContainer,
	TableAvatarContainer,
	WrapperUserAvatar,
	WrapperUserName,
	UserNameSubtitle,
	UserName,
	WrapperUser,
	AccessRequestUserTableWrapper,
	AccessRequestUserTableBorder,
	AccessRequestWrapperTitleTable,
	AccessRequestDrawerWidth,
	AccessRequestCollapseTitle,
	LabelWrapper,
	CustomDivider,
	AccessRequestWrapperBottom,
	AccessRequestBottomTitle,
	AccessRequestBottomSubTitle,
};
