import { observer } from "mobx-react-lite";

import UpdateDataTabs from "./components/UpdateData.tabs";
import UpdateDataTabsStore from "./store/UpdateData.tabs.store";
import UpdateDataTable from "./Table/UpdateDataTable";
import { TabsWrapper, MainWrapper } from "./UpdateData.styled";
import { UpdateDataDownload } from "./components/UpdateData.download";

const UpdateData = () => (
	<>
		<TabsWrapper>
			<UpdateDataTabs />
		</TabsWrapper>
		<MainWrapper>
			{UpdateDataTabsStore.activeTab === "download" && <UpdateDataDownload />}
			{UpdateDataTabsStore.activeTab === "history" && <UpdateDataTable />}
		</MainWrapper>
	</>
);

export default observer(UpdateData);
