import { observer } from "mobx-react-lite";
import styled from "styled-components";

import Tab from "components/ui/Tab/Tab";

import { mainSectionsHeader } from "../constants";
import AppealsStore from "../store/Appeals.store";

const AppealsTab = () => (
	<ExclamationTabWrapper>
		<Tab
			activeKey={AppealsStore.selectedTab}
			onTabClick={(key) => AppealsStore.setSelectedTab(key as "ACTIVE" | "ARCHIVE")}
			withLine={true}
			paddingItem={"12px 0"}
			style={{ borderBottom: "none" }}
			items={mainSectionsHeader ?? []}
			direction={"horizontal"}
		/>
	</ExclamationTabWrapper>
);

const ExclamationTabWrapper = styled.div`
	margin: 20px;
	border-bottom: 1px solid #12121229;
`;
export default observer(AppealsTab);
