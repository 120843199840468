import { Row } from "antd";
import styled from "styled-components";

const AccessRequestRow = styled(Row)`
	button:first-child {
		margin-right: 10px;
	}
`;

export { AccessRequestRow };
