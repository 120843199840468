import { useEffect, useState } from "react";
import React from "react";

import { Row } from "antd";
import { observer } from "mobx-react-lite";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Pagination, Table, colors } from "tap2visit-ui-kit";

import { archiveAnnouncementApi, deleteAnnouncementByIdApi } from "api/api.announcements";
import { getUserApi } from "api/api.employee";
import { getRolesApi } from "api/api.realEstate";
import Page from "components/layout/Page";
import BaseModal from "components/Modals/BaseModal";
import PaginationContainer from "components/PaginationContainer";
import TableEmptySlug from "components/Table/TableEmptySlug";
import TableLoader from "components/Table/TableLoader";
import { MIN_TABLE_HEIGHT } from "constants/constants.common";
import { Permissions } from "constants/constants.roles";
import useTablePaginationLogic from "hooks/useTablePaginationLogic";
import useWindowWidth from "hooks/useWindowWidth";
import { queryClient } from "index";
import type { IAnnouncementsListGatewayBody } from "interfaces/IAnnouncements";
import type { TSortingDirection } from "interfaces/ISorting";
import { QueriesKeys } from "interfaces/queriesKeys";
import { ReactComponent as ArchiveIcon } from "public/announcement-icon.svg";
import { ReactComponent as EditIcon } from "public/edit-icon.svg";
import { ReactComponent as RefreshSvg } from "public/refresh.svg";
import { ReactComponent as TrashSvg } from "public/small_trash.svg";
import SideBarStore from "store/SideBar.store";
import { joinArrParams } from "utils/joinParams";

import { getAnnouncementsListApi } from "../../../api/api.gateway";
import { useTableFilterContext } from "../../../components/TableFilter/TableFilter.context";
import getRoleTranslate from "../../../utils/getRoleTranslate";
import getTypeTranslate from "../../../utils/getTypeTranslate";
import AnnouncementsTabStore from "../Announcements.tab.store";
import AnnouncementsStore from "../store/Announcements.currentItem.store";
import getMightVisibilityAnnouncementActions from "../utils/getMightVisibilityAnnouncementActions";

import DrawerStore from "./Drawer.store";

const getParam = (arrValues: string[]) => (arrValues.length ? arrValues.join(",") : undefined);

const AnnouncementTable = () => {
	const windowWidth = useWindowWidth();

	const roles = useQuery({
		queryFn: () => getRolesApi(),
		queryKey: [QueriesKeys.roles],
	});

	const tableFilterContext = useTableFilterContext();
	const [createdBySearch, setCreatedBySearch] = useState<undefined | string>(undefined);
	const [filterAnnouncesStatuses, setFilterAnnouncesStatuses] = React.useState<string[]>([]);
	const [sortedByType, setSortedByType] = React.useState<TSortingDirection | undefined>();
	const [sortedByCreationDate, setSortedByCreationDate] = React.useState<TSortingDirection | undefined>();
	const [sortedByStartPublish, setSortedByStartPublish] = React.useState<TSortingDirection | undefined>();
	const [sortedByEndPublish, setSortedByEndPublish] = React.useState<TSortingDirection | undefined>();
	const [tableColumns, setTableColumns] = useState([]);
	const archiveAnnouncement = useMutation(archiveAnnouncementApi);

	const [isVisibleModalConfirmMoveAnnouncementToArchive, setIsVisibleModalConfirmMoveAnnouncementToArchive] = React.useState(false);
	const [isVisibleModalConfirmDeleteAnnouncement, setIsVisibleModalConfirmDeleteAnnouncement] = React.useState(false);

	const [announcementTextSearch, setAnnouncementTextSearch] = React.useState("");
	const [filteredRoles, setFilteredRoles] = React.useState<string[]>([]);

	const paginationActiveTableData = useTablePaginationLogic();
	const paginationArchiveTableData = useTablePaginationLogic();
	const paginationData = AnnouncementsTabStore.selectedTab === "ACTIVE" ? paginationActiveTableData : paginationArchiveTableData;

	const navigate = useNavigate();

	const deleteAnnouncement = useMutation({
		mutationFn: (id: string) =>
			deleteAnnouncementByIdApi({
				path: {
					id,
				},
			}),
	});

	const deleteAnnouncementHandler = async () => {
		await deleteAnnouncement.mutateAsync(AnnouncementsStore.announcementsItem.id);

		if (AnnouncementsStore.announcementsItem.type === "EMERGENCY") {
			queryClient.invalidateQueries([QueriesKeys.countOfEmergency]);
		}

		queryClient.invalidateQueries([QueriesKeys.announcements]);
		setIsVisibleModalConfirmDeleteAnnouncement(false);
	};

	useEffect(() => {
		setFilterAnnouncesStatuses(AnnouncementsTabStore.sortedByTypeStore);
	}, [AnnouncementsTabStore.sortedByTypeStore]);

	useEffect(() => {
		if (sortedByType || sortedByCreationDate || sortedByStartPublish || sortedByEndPublish) {
			paginationData.setClientsCurrentPage(1);
		}
	}, [sortedByType, sortedByCreationDate, sortedByStartPublish, sortedByEndPublish]);

	const announcements = useQuery({
		queryFn: () => {
			const params: IAnnouncementsListGatewayBody = {
				complexIds: joinArrParams(tableFilterContext.selectedComplexIds),
				buildingIds: joinArrParams(tableFilterContext.selectedBuildingsIds),
				entranceIds: joinArrParams(tableFilterContext.selectedEntrancesIds),
				floorIds: joinArrParams(tableFilterContext.selectedFloorsIds),
				buildingObjectIds: joinArrParams(tableFilterContext.selectedBuildingObjectIds),
				textSearch: announcementTextSearch,
				createdBySearch,
				page: paginationData.clientsCurrentPage - 1,
				size: paginationData.clientsSize,
				types: getParam(filterAnnouncesStatuses),
				roleIds:
					tableFilterContext.selectRoles.length === 1 ? roles.data.find((role) => role.name === "OWNER").id : getParam(filteredRoles),
				sort: {
					type: sortedByType,
					createDateTime: sortedByCreationDate,
					startDateTime: sortedByStartPublish,
					endDateTime: sortedByEndPublish,
				},
				statuses:
					AnnouncementsTabStore.selectedTab === "ACTIVE" ? `${AnnouncementsTabStore.selectedTab},NEW` : AnnouncementsTabStore.selectedTab,
			};

			return getAnnouncementsListApi(params);
		},
		queryKey: [
			QueriesKeys.announcements,
			tableFilterContext.selectedComplexIds,
			tableFilterContext.selectedBuildingsIds,
			tableFilterContext.selectedEntrancesIds,
			tableFilterContext.selectedFloorsIds,
			tableFilterContext.selectedBuildingObjectIds,
			tableFilterContext.selectRoles,
			filterAnnouncesStatuses,
			filteredRoles,
			announcementTextSearch,
			createdBySearch,
			sortedByType,
			sortedByCreationDate,
			sortedByStartPublish,
			sortedByEndPublish,
			AnnouncementsTabStore.selectedTab,
			AnnouncementsTabStore.sortedByTypeStore,
			paginationData.clientsSize,
			paginationData.clientsCurrentPage,
		],
		enabled: !!tableFilterContext.selectRoles.length,
	});

	const isVisiblePagination = !announcements.isFetching && announcements.data;

	React.useEffect(() => {
		if (announcements.data) {
			paginationData.setClientsTotal(announcements.data.totalPages);
		}
	}, [announcements.data?.page, announcementTextSearch, createdBySearch]);

	const saveAnnouncementToArchive = async () => {
		await archiveAnnouncement.mutateAsync({
			path: { id: AnnouncementsStore.announcementsItem.id },
		});
		setIsVisibleModalConfirmMoveAnnouncementToArchive(false);
		announcements.refetch();
	};

	React.useEffect(() => {
		queryClient.invalidateQueries([QueriesKeys.announcements]);
	}, [announcementTextSearch, createdBySearch]);

	// TODO why roles.data is empty if we moved from Citizens (temporary workaround)
	React.useEffect(() => {
		queryClient.invalidateQueries([QueriesKeys.roles]);
	}, []);

	const onCell = (props) => {
		if (props?.type === "ЧП") {
			props.style = { background: colors.strokeDangerTransparent };
		}

		return props;
	};

	useEffect(() => {
		setTableColumns([
			{
				dataIndex: "type",
				key: "type",
				title: "Тип",
				onSortedBy: setSortedByType,
				filters: [
					{ text: "Объявление", key: "ORDINARY" },
					{ text: "ЧП", key: "EMERGENCY", active: AnnouncementsTabStore.sortedByTypeStore.length > 0 ? true : false },
				],
				onChangeFilter: (data) => setFilterAnnouncesStatuses(data.map((d) => d.key)),
				onCell: onCell,
				width: "180px",
			},
			{
				dataIndex: "address",
				key: "address",
				title: "Адресаты",
				onCell: onCell,
			},
			{
				dataIndex: "role",
				key: "role",
				title: "Роль",
				filters: roles?.data?.map((r) => ({ text: getRoleTranslate(r.name), key: r.id })),
				onChangeFilter: (data) => setFilteredRoles(data.map((r) => r.key)),
				onCell: onCell,
			},
			{
				dataIndex: "text",
				key: "text",
				title: "Текст объявления",
				onClearSearch: () => setAnnouncementTextSearch(""),
				onSearch: setAnnouncementTextSearch,
				onCell: onCell,
			},
			{
				dataIndex: "account",
				key: "account",
				title: "Отправитель",
				onSearch: (item) => {
					setCreatedBySearch(item);
				},
				onCell: onCell,
				width: "150px",
			},
			{
				dataIndex: "date",
				key: "date",
				title: "Дата создания",
				onSortedBy: setSortedByCreationDate,
				onCell: onCell,
				width: "150px",
			},
			{
				dataIndex: "start",
				key: "start",
				title: "Начало публикации",
				onSortedBy: setSortedByStartPublish,
				onCell: onCell,
				width: "150px",
			},
			{
				dataIndex: "end",
				key: "end",
				title: "Конец публикации",
				onSortedBy: setSortedByEndPublish,
				onCell: onCell,
				width: "150px",
			},
			{
				dataIndex: "button",
				key: "button",
				title: "",
				width: "100px",
				onCell: onCell,
			},
		]);
	}, [AnnouncementsTabStore.sortedByTypeStore]);

	const user = useQuery({
		queryFn: () => getUserApi(),
	});

	const tableData = announcements?.data?.content?.map((v) => {
		const visibilityActionsButton = getMightVisibilityAnnouncementActions(v);

		return {
			type: getTypeTranslate(v.type),
			address:
				v.complexAddresses?.join(",") ||
				v.buildingAddresses?.join(",") ||
				v.floorAddresses?.join("") ||
				v.entranceAddresses?.join("") ||
				v.buildingObjectAddresses?.join("") ||
				"",
			role: v.roleNames.map((val) => getRoleTranslate(val)).join(", "),
			text: <p style={{ whiteSpace: "break-spaces" }}>{v.text}</p>,
			account: v.fullName,
			date: new Date(v.createDateTime).toLocaleDateString(),
			start: new Date(v.startDateTime).toLocaleDateString(),
			end: new Date(v.endDateTime).toLocaleDateString(),
			button: (
				<>
					<ExclamationRow align="middle" justify="center" onClick={(e) => e.stopPropagation()}>
						{visibilityActionsButton.edit && (
							<EditIconWrapper
								onClick={() => {
									AnnouncementsStore.setAnnouncementsItem(v);
									navigate(`/announcement/edit/${v.id}`);
								}}
							/>
						)}
						{visibilityActionsButton.archive && (
							<ArchiveIconWrapper
								onClick={() => {
									AnnouncementsStore.setAnnouncementsItem(v);
									setIsVisibleModalConfirmMoveAnnouncementToArchive(true);
								}}
							/>
						)}

						{visibilityActionsButton.delete ||
							(user.data.additionalProperties?.role === Permissions.ADMIN && (
								<TrashIconWrapper
									onClick={() => {
										AnnouncementsStore.setAnnouncementsItem(v);
										setIsVisibleModalConfirmDeleteAnnouncement(true);
									}}
								/>
							))}
						{visibilityActionsButton.repeat && (
							<div onClick={(e) => e.stopPropagation()}>
								<RefreshIconWrapper
									onClick={() => {
										AnnouncementsStore.setAnnouncementsItem(v);
										navigate(`/announcement/create/${v.id}`);
									}}>
									<RefreshSvg fill={colors.iconNeutralDefault} />
								</RefreshIconWrapper>
							</div>
						)}
					</ExclamationRow>
				</>
			),
		};
	});

	return (
		<Page>
			<Table
				isFullWidth
				columns={tableColumns}
				onRowClick={(_, idx) => {
					DrawerStore.openDrawer(announcements.data.content[idx]);
				}}
				style={{
					width: `calc(${windowWidth}px - ${SideBarStore.sideBarWidth} )`,
					overflowX: "auto",
					minHeight: MIN_TABLE_HEIGHT,
				}}
				data={tableData ?? []}
				size="m"
				emptyComponent={announcements.isFetching || roles.isFetching ? TableLoader : TableEmptySlug}
			/>
			{isVisiblePagination && (
				<PaginationContainer>
					<Pagination
						onChangeSize={paginationData.setClientsSize}
						currentPage={paginationData.clientsCurrentPage}
						setCurrentPage={paginationData.setClientsCurrentPage}
						total={paginationData.clientsTotal}
						size={paginationData.clientsSize as 10 | 20 | 50 | 100}
					/>
				</PaginationContainer>
			)}
			<BaseModal
				isVisible={isVisibleModalConfirmMoveAnnouncementToArchive}
				okText="Да"
				onCancel={() => setIsVisibleModalConfirmMoveAnnouncementToArchive(false)}
				onOk={saveAnnouncementToArchive}
				cancelText="Нет"
				status="delete"
				title="Вы уверены, что хотите снять объявление с публикации?"
			/>
			<BaseModal
				isVisible={isVisibleModalConfirmDeleteAnnouncement}
				okText="Да"
				onCancel={() => setIsVisibleModalConfirmDeleteAnnouncement(false)}
				onOk={deleteAnnouncementHandler}
				cancelText="Нет"
				status="delete"
				title="Вы уверены, что хотите удалить объявление?"
			/>
		</Page>
	);
};

export default observer(AnnouncementTable);

const ExclamationRow = styled(Row)`
	gap: 8px;
	@media (max-width: 1440px) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
	}
`;

const EditIconWrapper = styled(EditIcon)`
	cursor: pointer;
	width: 20px;
	height: 20px;
	fill: #12121299;
`;

const ArchiveIconWrapper = styled(ArchiveIcon)`
	cursor: pointer;
	width: 20px;
	height: 20px;
`;

const TrashIconWrapper = styled(TrashSvg)`
	cursor: pointer;
	width: 20px;
	height: 20px;
	transform: scale(0.8);
`;
const RefreshIconWrapper = styled.div`
	cursor: pointer;
`;
