import { Fragment } from "react";

import { observer } from "mobx-react-lite";
import { useQuery } from "react-query";
import { Navigate, Route } from "react-router-dom";
import { colors } from "tap2visit-ui-kit";

import { getConfig } from "api/api.gateway";
import { isVisibleFunctionality } from "constants/constants.common";
import Appeals from "pages/appeals/Appeals";
import ChatItem from "pages/chat/ChatItem";
import Citizens from "pages/citizens/Citizens";
import { Counters } from "pages/counters/Counters";
import Employee from "pages/employee/Employee";
import Polls from "pages/polls/Polls/Polls";
import PollsEdit from "pages/polls/PollsEdit/PollsEdit";
import PollsCreateTemplate from "pages/polls/PollsEditTemplate/PollsEditTemplate";
import PollsView from "pages/polls/PollsView/PollsView";
import Receipts from "pages/receipts/Receipts";
import UpdateData from "pages/updateData/UpdateData";
import { ReactComponent as Arrow } from "public/arrow_right.svg";

import { getProfiles } from "../api/office/api.office";
import { QueriesKeys } from "../interfaces/queriesKeys";
import AccessRequest from "../pages/accessRequest/AccessRequest";
import Announcement from "../pages/announcemets/Announcement";
import AnnouncementChange from "../pages/announcemets/AnnouncementChange";
import AuthorizationPage from "../pages/authorization/Authorization";
import Buildings from "../pages/buildings/Buildings";
import BuildingsEntrance from "../pages/buildings/BuildingsEntrance";
import BuildingsFloor from "../pages/buildings/BuildingsFloor";
import BuildingsHouse from "../pages/buildings/BuildingsHouse";
import ProfileUKPage from "../pages/profileUK/ProfileUK";
import AuthStore from "../store/Auth.Store";
import UKStore from "../store/UK.Store";

import { ROUTES } from "./constants/routes.constants";
import ExtendedRoutes from "./extendedRoutes";
import WithNavbar from "./withSideBar";

const MainRouter = observer(() => {
	const currentUK = UKStore.getUK();

	const path = currentUK?.managingOrganizationId ? "/" : "/login";

	const configUK = useQuery({
		queryFn: () => getConfig(currentUK.managingOrganizationId),
		queryKey: ["config", currentUK?.id],
		enabled: !!currentUK?.managingOrganizationId,
	});

	useQuery({
		queryFn: () => getProfiles(),
		enabled: AuthStore.checkAuth(),
		queryKey: [QueriesKeys.checkAuth],
		onSuccess: (res) => {
			const array = res.map((item) => ({
				title: item.name,
				slot: <Arrow />,
				id: item.id,
				managingOrganizationId: item.managingOrganizationId,
				leftIcon: {
					firstAltLetter: item.name[0],
					secondAltLetter: "",
					type: "avatar",
				},
			}));
			UKStore.setCompanies(array);
		},
	});

	const routerConfig = [
		{
			type: "RESIDENTIAL_COMPLEX",
			children: (
				<Route path={ROUTES.RESIDENTIAL_COMPLEX}>
					<Route index element={<Buildings />} />
					<Route path="house/:id" element={<BuildingsHouse />} />
					<Route path="entrance/:id" element={<BuildingsEntrance />} />
					<Route path="floor/:id" element={<BuildingsFloor />} />
					<Route path="*" element={<Navigate to={"../"} />} />
				</Route>
			),
		},
		{
			type: "PROFILE_MO",
			children: <Route element={<ProfileUKPage />} path={ROUTES.PROFILE_MO} />,
		},
		{
			type: "PAYMENT",
			children: (
				<Route path={ROUTES.PAYMENT}>
					<Route index element={<Receipts />} />
					<Route path="*" element={<Navigate to={"../"} />} />
				</Route>
			),
		},
		{
			type: "METERING_DEVICE",
			children: (
				<Route path={ROUTES.METERING_DEVICE}>
					<Route index element={<Counters />} />
					<Route path="*" element={<Navigate to={"../"} />} />
				</Route>
			),
		},
		{
			type: "RESIDENT",
			children: <Route path={ROUTES.RESIDENT} element={<Citizens />} />,
		},
		{
			type: "REQUEST_ACCESS",
			children: <Route path={ROUTES.REQUEST_ACCESS} element={<AccessRequest />} />,
		},
		{
			type: "CHAT",
			children: (
				<Route path={ROUTES.CHAT}>
					<Route index element={<></>} />
					<Route path=":id" element={<ChatItem />} />
				</Route>
			),
		},
		{
			type: "APPEAL",
			children: <Route path={ROUTES.APPEAL} element={<Appeals />} />,
		},
		{
			type: "ANNOUNCEMENT",
			children: (
				<Route path={ROUTES.ANNOUNCEMENT}>
					<Route index element={<Announcement />} />
					<Route path="create" element={<AnnouncementChange />} />
					<Route path="create/:id" element={<AnnouncementChange />} />
					<Route path="edit/:id" element={<AnnouncementChange />} />
					<Route path="*" element={<Navigate to={"../"} />} />
				</Route>
			),
		},
		{
			type: "EMPLOYEE",
			children: <Route path={ROUTES.EMPLOYEE} element={<Employee />} />,
		},
		{
			type: "VOTE",
			children: (
				<Route path={ROUTES.VOTE}>
					<Route index element={<Polls />} />
					<Route path="edit/:id" element={<PollsEdit />} />
					<Route path="view/:id" element={<PollsView />} />
					<Route path="create" element={<PollsEdit />} />
					<Route path="create-template" element={<PollsCreateTemplate />} />
					<Route path="*" element={<Navigate to={"../"} />} />
				</Route>
			),
		},
		{
			type: "ARCUS",
			children: <Route path={ROUTES.ARCUS} element={<UpdateData />} />,
		},
	];
	const enabledModules = configUK.data?.modules || [];

	const visibleMenuItems = isVisibleFunctionality
		? routerConfig
		: routerConfig.filter((item) => enabledModules.some((module) => module.type === item.type && module.enabled));

	return (
		<div style={{ background: colors.surfaceNeutralBg2, minHeight: "100vh" }}>
			<ExtendedRoutes>
				<Route Component={AuthorizationPage} path="/login" />
			</ExtendedRoutes>
			{configUK.isFetched && (
				<WithNavbar>
					<>
						{visibleMenuItems.map((menuItem) => (
							<Fragment key={menuItem.type}>{menuItem.children}</Fragment>
						))}
						{<Route path="*" element={<Navigate to={path} />} />}
					</>
				</WithNavbar>
			)}
		</div>
	);
});

export default MainRouter;
