import ValidationStore, { ValidationStatuses } from "modules/validation/Validation.store";

export type TCitizenPassportValidationFields = {
	series: keyof typeof ValidationStatuses;
	number: keyof typeof ValidationStatuses;
	departmentCode: keyof typeof ValidationStatuses;
	dateOfIssue: keyof typeof ValidationStatuses;
	dateOfBirth: keyof typeof ValidationStatuses;
	issueBy: keyof typeof ValidationStatuses;
	address: keyof typeof ValidationStatuses;
};

const initialValidationData: TCitizenPassportValidationFields = {
	series: ValidationStatuses.VALID,
	number: ValidationStatuses.VALID,
	departmentCode: ValidationStatuses.VALID,
	dateOfIssue: ValidationStatuses.VALID,
	dateOfBirth: ValidationStatuses.VALID,
	issueBy: ValidationStatuses.VALID,
	address: ValidationStatuses.VALID,
};

const COMMON_REQUIRED_ERROR_MESSAGE = "Обязательно к заполнению";

export const ERROR_MESSAGES: Record<keyof TCitizenPassportValidationFields, Partial<Record<keyof typeof ValidationStatuses, string>>> = {
	series: { REQUIRED: COMMON_REQUIRED_ERROR_MESSAGE, EQUALS: "Поле должно содержать 4 символа" },
	number: { REQUIRED: COMMON_REQUIRED_ERROR_MESSAGE, EQUALS: "Поле должно содержать 6 символов" },
	departmentCode: { REQUIRED: COMMON_REQUIRED_ERROR_MESSAGE, EQUALS: "Поле должно содержать 6 символов" },
	address: { REQUIRED: COMMON_REQUIRED_ERROR_MESSAGE, LESS_THEN: "Поле не должно содержать более 500 символов" },
	dateOfBirth: { REQUIRED: COMMON_REQUIRED_ERROR_MESSAGE },
	dateOfIssue: { REQUIRED: COMMON_REQUIRED_ERROR_MESSAGE },
	issueBy: { REQUIRED: COMMON_REQUIRED_ERROR_MESSAGE, LESS_THEN: "Поле не должно содержать более 500 символов" },
};

const checkRules = {
	series: {
		[ValidationStatuses.REQUIRED]: true,
		[ValidationStatuses.EQUALS]: 4,
	},
	number: {
		[ValidationStatuses.REQUIRED]: true,
		[ValidationStatuses.EQUALS]: 6,
	},
	departmentCode: {
		[ValidationStatuses.REQUIRED]: true,
		[ValidationStatuses.EQUALS]: 6,
	},
	address: {
		[ValidationStatuses.REQUIRED]: true,
		[ValidationStatuses.LESS_THEN]: 500,
	},
	dateOfBirth: {
		[ValidationStatuses.REQUIRED]: true,
	},
	dateOfIssue: {
		[ValidationStatuses.REQUIRED]: true,
	},
	issueBy: {
		[ValidationStatuses.REQUIRED]: true,
		[ValidationStatuses.LESS_THEN]: 500,
	},
};

const CitizenPassportValidationStore = new ValidationStore<TCitizenPassportValidationFields>(
	initialValidationData,
	ERROR_MESSAGES,
	checkRules,
);

export default CitizenPassportValidationStore;
