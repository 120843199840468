import React from "react";

interface IUseTablePaginationLogic {
	clientSizeNumber?: number;
}
const useTablePaginationLogic = (props?: IUseTablePaginationLogic) => {
	const [clientsSize, setClientsSize] = React.useState(props?.clientSizeNumber || 20);
	const [clientsCurrentPage, setClientsCurrentPage] = React.useState(1);
	const [clientsTotal, setClientsTotal] = React.useState(1);

	const updateClientSize = (size: number) => {
		setClientsSize(size);
		setClientsCurrentPage(1);
	};

	return {
		clientsSize,
		clientsTotal,
		clientsCurrentPage,
		setClientsCurrentPage,
		setClientsSize: updateClientSize,
		setClientsTotal,
	};
};

export default useTablePaginationLogic;
