import { FC } from "react";

import { observer } from "mobx-react-lite";
import { Pagination, Table } from "tap2visit-ui-kit";

import Page from "components/layout/Page";
import PaginationContainer from "components/PaginationContainer";
import TableEmptySlug from "components/Table/TableEmptySlug";
import TableLoader from "components/Table/TableLoader";
import { MIN_TABLE_HEIGHT } from "constants/constants.common";
import useWindowWidth from "hooks/useWindowWidth";
import SideBarStore from "store/SideBar.store";

import useTableData from "./hooks/useTableData";

const UpdateDataTable: FC = () => {
	const tableData = useTableData();
	const windowWidth = useWindowWidth();

	return (
		<Page>
			<Table
				columns={tableData.columns}
				data={tableData.data}
				size={"m"}
				isFullWidth
				style={{
					width: `calc(${windowWidth}px - ${SideBarStore.sideBarWidth} )`,
					overflowX: "auto",
					minHeight: MIN_TABLE_HEIGHT,
					marginTop: "28px",
				}}
				cellStyle={{ cursor: "default" }}
				emptyComponent={tableData.isFetching ? TableLoader : TableEmptySlug}
			/>

			<PaginationContainer>
				<Pagination
					onChangeSize={tableData.paginationData.setClientsSize}
					currentPage={tableData.paginationData.clientsCurrentPage}
					setCurrentPage={tableData.paginationData.setClientsCurrentPage}
					total={tableData.paginationData.clientsTotal}
					size={tableData.paginationData.clientsSize as 10 | 20 | 50 | 100}
				/>
			</PaginationContainer>
		</Page>
	);
};

export default observer(UpdateDataTable);
