import React from "react";

import { observer } from "mobx-react-lite";
import { OutlineUpdate1 } from "t2v-icons";
import { Button } from "tap2visit-ui-kit";

import useTablePaginationLogic from "hooks/useTablePaginationLogic";
import { useRestoreRequest } from "pages/accessRequest/api/api.request";

import { AccessRequestRow } from "./AccessRequestTable.styled";

// import { ReactComponent as UserChecked } from "public/userChecked.svg";
// import { ReactComponent as UserDelete } from "public/userDelete.svg";

// import AccessRequestRejectModalStore from "../ModalCommentRejection/store/AccessRequestRejectModal.store";

interface IAccessRequestButtons {
	id: string;
	status: boolean;
	canBeRestored: boolean;
}

export const AccessRequestButtons = ({ id, status, canBeRestored }: IAccessRequestButtons) => {
	const paginationData = useTablePaginationLogic();
	const { mutate: restoreRequest } = useRestoreRequest({
		requestUserId: id,
		onRequestSuccess: () => paginationData.setClientsCurrentPage(1),
	});

	const isDisabled = () => !canBeRestored || status;

	/** need for next release */
	// if (type === "mobileApp") {
	// return (
	// 	<AccessRequestRow justify="center">
	// 		<Button shape="square" size="medium" typeButton="primary" icon={UserChecked} disabled />
	// 		<Button
	// 			onClick={(e) => {
	// 				e.stopPropagation();
	// 				AccessRequestRejectModalStore.showModal({ requestId: id });
	// 			}}
	// 			shape="square"
	// 			size="medium"
	// 			typeButton="primary"
	// 			variant="red"
	// 			icon={UserDelete}
	// 		/>
	// 	</AccessRequestRow>
	// );
	return (
		<AccessRequestRow justify="center">
			<Button
				disabled={isDisabled()}
				onClick={(e) => {
					e.stopPropagation();
					restoreRequest();
				}}
				shape="square"
				size="medium"
				typeButton="primary"
				variant="default"
				icon={OutlineUpdate1}
			/>
		</AccessRequestRow>
	);
};

export default observer(AccessRequestButtons);
