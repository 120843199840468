import { AxiosError } from "axios";

import type { IALias, IAddress, IAliasCreate } from "interfaces/IGeocoder";
import { joinArrParams } from "utils/joinParams";

import apiClient from "./api.client";

export type TCreateAliasApi = (args: { body: IAliasCreate }) => Promise<IALias>;

export const createOrGetAliasApi: TCreateAliasApi = async (args) => {
	try {
		const res = await apiClient.post("geocoder/v1/aliases", args.body);

		return res.data;
	} catch (error: unknown) {
		try {
			if (error instanceof AxiosError) {
				if (error.request.status === 400) {
					const res = getAddressById({ params: { id: args.body.addressId } });

					return res;
				}
			}
		} catch {
			return Promise.reject(error);
		}
		return Promise.reject(error);
	}
};

export type TGetAliasesApi = (args: { params: { aliasIds: string[] } }) => Promise<IAddress[]>;

export const getAliasesApi: TGetAliasesApi = async (args) => {
	try {
		const { aliasIds, ...otherParams } = args.params;

		const params: Record<string, string | number> = {
			...otherParams,
		};

		if (aliasIds?.length) {
			params.aliasIds = aliasIds.join(",");
		}

		const res = await apiClient.get("geocoder/v1/aliases", {
			params,
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TGetAdressByIdApi = (args: { params: { id: string } }) => Promise<IALias>;

export const getAddressById: TGetAdressByIdApi = async (args) => {
	try {
		const res = await apiClient.get(`geocoder/v1/address/${args.params.id}`);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TSearchAddressApi = (args: { params: { query: string } }) => Promise<IAddress>;

export const searchAddressApi: TSearchAddressApi = async (args) => {
	try {
		const res = await apiClient.get("geocoder/v1/address/search", {
			params: args.params,
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TSearchSuggestAddressApi = (args: { params: { query: string } }) => Promise<IAddress[]>;

export const searchSuggestAddressApi: TSearchSuggestAddressApi = async (args) => {
	try {
		const res = await apiClient.get("geocoder/v1/address/suggest", {
			params: args.params,
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

export type TGetAddressesApi = (args?: { params?: { addressIds?: string[] } }) => Promise<IAddress[]>;

export const getAddressesApi: TGetAddressesApi = async (args) => {
	try {
		const params: Record<string, string> = {};

		if (args?.params?.addressIds) {
			params.addressIds = joinArrParams(args.params.addressIds);
		}

		const res = await apiClient.get("geocoder/v1/address", {
			params,
		});

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};
