import { useCallback, useEffect, useState } from "react";

import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { useMutation } from "react-query";
import styled from "styled-components";
import { FormItem, Upload } from "tap2visit-ui-kit";

import { postFileUploadApi, validateFileUploadApi } from "api/api.storage";

import { MAX_FILE_SIZE_MB } from "../constants/constants";
import UpdateDataStore from "../store/UpdateDataStore";

interface IUpdateDataFileProps {
	disabled?: boolean;
	setIsFileUploading?: (value: boolean) => void;
}

const UpdateDataFile = ({ disabled, setIsFileUploading }: IUpdateDataFileProps) => {
	const postFileUpload = useMutation(postFileUploadApi);
	const validateFileUpload = useMutation(validateFileUploadApi);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | undefined>(undefined);
	const file = UpdateDataStore.getFile();

	useEffect(() => {
		(async () => {
			if (!UpdateDataStore.isNeedToValidate) return;
			setLoading(true);

			const formData = new FormData();
			formData.append("file", UpdateDataStore.getFile());
			try {
				const { id } = await postFileUpload.mutateAsync({ file: formData, bucket: "ARCUS_INTEGRATION" });
				await validateFileUpload.mutateAsync({
					id,
					buildingType: toJS(UpdateDataStore.getRequestData().buildingTypes[0]),
					buildingId: toJS(UpdateDataStore.getRequestData().buildingIds[0]),
				});
				UpdateDataStore.isNeedToValidate = true;
				setError(undefined);
			} catch (error) {
				const message = error.response?.data?.message;
				setError(message);
				UpdateDataStore.isNeedToValidate = true;
			} finally {
				setLoading(false);
			}
		})();
	}, [UpdateDataStore.complexesIds, UpdateDataStore.buildingIds, UpdateDataStore.buildingTypes]);

	const handleFileUpload = useCallback(
		async (selectedFile: File) => {
			if (!selectedFile) return;

			const fileSizeMB = selectedFile.size / (1024 * 1024);
			if (fileSizeMB > MAX_FILE_SIZE_MB) {
				setError("Файл превышает максимальный размер 20 МБ");
				return;
			}

			UpdateDataStore.setFile(selectedFile);
			const formData = new FormData();
			formData.append("file", selectedFile);
			setLoading(true);

			try {
				const { id } = await postFileUpload.mutateAsync({ file: formData, bucket: "ARCUS_INTEGRATION" });
				await validateFileUpload.mutateAsync({
					id,
					buildingType: toJS(UpdateDataStore.getRequestData().buildingTypes[0]),
					buildingId: toJS(UpdateDataStore.getRequestData().buildingIds?.[0]),
				});
				UpdateDataStore.setUploadBucketFileId(id);
				setIsFileUploading(true);
				setError(undefined);
			} catch (error) {
				const message = error.response?.data?.message;
				setError(message);
				UpdateDataStore.isNeedToValidate = true;
			} finally {
				setLoading(false);
			}
		},
		[postFileUpload, validateFileUpload, setIsFileUploading],
	);

	return (
		<CustomFormItem required label="Файл" labelPosition={"vertical"}>
			<Upload
				files={file ? [file] : undefined}
				onChangeFiles={(e) => {
					const selectedFile = e?.target?.files ? e.target.files[0] : null;
					if (selectedFile) {
						handleFileUpload(selectedFile);
					}
					setIsFileUploading(false);
				}}
				onRemove={() => {
					UpdateDataStore.setFile(undefined);
				}}
				accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
				type="file"
				description="Документ.xlsx (макс. 20 МБ)"
				loading={loading}
				disabled={disabled}
				error={!!error}
				textError={error}
				title="Нажмите что бы загрузить файл"
				isProgressBar
				width="100%"
				wrapperStyle={{ width: "100%" }}
			/>
		</CustomFormItem>
	);
};

const CustomFormItem = styled(FormItem)`
	width: 100%;
`;

export default observer(UpdateDataFile);
