import ValidationStore, { ValidationStatuses } from "modules/validation/Validation.store";

export type TTableFilterValidation = {
	selectComplexes: keyof typeof ValidationStatuses;
	selectBuildings: keyof typeof ValidationStatuses;
	selectEntrances: keyof typeof ValidationStatuses;
	selectFloors: keyof typeof ValidationStatuses;
	selectBuildingObjects: keyof typeof ValidationStatuses;
};

const initialValidationData: TTableFilterValidation = {
	selectComplexes: ValidationStatuses.VALID,
	selectBuildings: ValidationStatuses.VALID,
	selectEntrances: ValidationStatuses.VALID,
	selectFloors: ValidationStatuses.VALID,
	selectBuildingObjects: ValidationStatuses.VALID,
};

const COMMON_REQUIRED_ERROR_MESSAGE = "Обязательно к заполнению";

export const ERROR_MESSAGES: Record<keyof TTableFilterValidation, Partial<Record<keyof typeof ValidationStatuses, string>>> = {
	selectComplexes: { REQUIRED: COMMON_REQUIRED_ERROR_MESSAGE },
	selectBuildings: { REQUIRED: COMMON_REQUIRED_ERROR_MESSAGE },
	selectEntrances: { REQUIRED: COMMON_REQUIRED_ERROR_MESSAGE },
	selectFloors: { REQUIRED: COMMON_REQUIRED_ERROR_MESSAGE },
	selectBuildingObjects: { REQUIRED: COMMON_REQUIRED_ERROR_MESSAGE },
};

const checkRules = {
	selectComplexes: {
		[ValidationStatuses.REQUIRED]: true,
	},
	selectBuildings: {
		[ValidationStatuses.REQUIRED]: true,
	},
	selectEntrances: {
		[ValidationStatuses.REQUIRED]: true,
	},
	selectFloors: {
		[ValidationStatuses.REQUIRED]: true,
	},
	selectBuildingObjects: {
		[ValidationStatuses.REQUIRED]: true,
	},
};

const TableFilterValidation = new ValidationStore<TTableFilterValidation>(initialValidationData, ERROR_MESSAGES, checkRules);

export default TableFilterValidation;
