import { emailRegExp } from "constants/constants.regexp";
import ValidationStore, { ValidationStatuses } from "modules/validation/Validation.store";

export type TCitizenEmailValidationFields = {
	comment: keyof typeof ValidationStatuses;
	email: keyof typeof ValidationStatuses;
};

const initialValidationData: TCitizenEmailValidationFields = {
	comment: ValidationStatuses.VALID,
	email: ValidationStatuses.VALID,
};

const COMMON_REQUIRED_ERROR_MESSAGE = "Обязательно к заполнению";

export const ERROR_MESSAGES: Record<keyof TCitizenEmailValidationFields, Partial<Record<keyof typeof ValidationStatuses, string>>> = {
	comment: { REQUIRED: COMMON_REQUIRED_ERROR_MESSAGE, LESS_THEN: "Поле не должно содержать более 100 символов" },
	email: { REQUIRED: COMMON_REQUIRED_ERROR_MESSAGE, MATCH: "Эл. почта некорректна" },
};

const checkRules = {
	comment: {
		[ValidationStatuses.REQUIRED]: true,
		[ValidationStatuses.LESS_THEN]: 100,
	},
	email: {
		[ValidationStatuses.REQUIRED]: true,
		[ValidationStatuses.MATCH]: emailRegExp,
	},
};

const CitizenEmailStore = new ValidationStore<TCitizenEmailValidationFields>(initialValidationData, ERROR_MESSAGES, checkRules);

export default CitizenEmailStore;
