import Input from "components/ui/Input/Input";
import styled, { css } from "styled-components";
import { TextArea, Text, colors } from "tap2visit-ui-kit";

export const FormItemContainer = styled.div`
	margin-bottom: 6px;
`;

export const FormItemsContainer = styled.div`
	display: flex;
	flex-direction: column;
	background: #12121205;
	border-radius: 10px;
	padding: 8px 24px 20px 24px;
`;

export const UploadContainer = styled.div`
	padding: 10px;
	border-radius: 10px;
	margin-top: 10px;
	background: #1212120a;
	p {
		margin-bottom: 10px;
	}
`;

export const FormItemsContainerWithButton = styled.div`
	margin-left: 40px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 8px;
`;

const BaseWidth = {
	width: "460px",
};

export const CustomInput = styled(Input)`
	width: 100%;
	background: white;
	font-weight: 450;
`;

export const CustomInputSmall = styled(Input)`
	width: 380px;
`;

export const CustomTextArea = styled(TextArea)`
	width: 100%;
	background: white;
	height: 100px;
	resize: none;
`;

export const CustomText = styled(Text)`
	${BaseWidth};
`;

export const Divider = styled.div<{ horizontal?: boolean }>`
	background-color: ${colors.strokeDivider};
	height: 0.5px;
	width: 100%;

	${(props) => {
		if (props.horizontal) {
			return css`
				height: 100%;
				width: 0.5px;
			`;
		}
	}}
`;
