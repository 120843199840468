import { useMutation } from "react-query";

import apiClient from "api/api.client";
import { queryClient } from "index";
import { QueriesKeys } from "interfaces/queriesKeys";

import {
	IApproveRequestParams,
	IApproveResponse,
	IApproveRestoreParams,
	TApproveRequestApi,
	TCancelRequestApi,
	TRestoreRequestApi,
} from "../types/types";

const cancelRequestApi: TCancelRequestApi = async (args) => {
	try {
		const res = await apiClient.post(`api-gateway/v1/requests/${args.path.requestId}/cancel`, args.body);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

const approveRequestApi: TApproveRequestApi = async (args) => {
	try {
		const res = await apiClient.post("api-gateway/v1/requests/approve", args.body);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

const restoreRequestApi: TRestoreRequestApi = async (args) => {
	try {
		const res = await apiClient.post(`api-gateway/v1/requests/${args.path.requestId}/restore`);

		return res.data;
	} catch (error) {
		return Promise.reject(error);
	}
};

const useRestoreRequest = ({ requestUserId, onRequestSuccess, onRequestError }: IApproveRestoreParams) =>
	useMutation({
		mutationFn: () =>
			restoreRequestApi({
				path: { requestId: requestUserId },
			}),
		onSuccess: () => {
			onRequestSuccess();
			queryClient.invalidateQueries([QueriesKeys.requests]);
		},
		onError: () => {
			onRequestError();
		},
	});

const useApproveRequest = ({ requestUserId, compareUserId, onRequestSuccess, onRequestError }: IApproveRequestParams) =>
	useMutation(
		() =>
			approveRequestApi({
				body: { requestId: requestUserId, baseClientId: compareUserId },
			}),
		{
			onSuccess: (data: IApproveResponse) => {
				onRequestSuccess(data);
				queryClient.invalidateQueries([QueriesKeys.requests]);
			},
			onError: (error: Error) => {
				onRequestError(error);
			},
		},
	);

export { cancelRequestApi, approveRequestApi, restoreRequestApi, useApproveRequest, useRestoreRequest };
