import React, { FC } from "react";

import { CollapseProps } from "antd";
import styled from "styled-components";
import { colors, Text } from "tap2visit-ui-kit";

import Collapse from "components/Collapse/Collapse";

import CitizenComments, { TCommentsType } from "./CitizenComments";

interface ICitizenCommentsCollapse {
	type: TCommentsType;
}

const CitizenCommentsCollapse: FC<ICitizenCommentsCollapse> = (props) => {
	const commentCollapse: CollapseProps["items"] = [
		{
			key: "1",
			label: (
				<Text style={{ marginLeft: "-4px" }} type="table-cell-m-bold">
					Комментарии
				</Text>
			),
			children: <CitizenComments type={props.type} />,
		},
	];

	return <CollapseWrapperComment items={commentCollapse} />;
};

export default CitizenCommentsCollapse;

const CollapseWrapperComment = styled(Collapse)`
	background: white;
	width: 100%;
	.ant-collapse-content-box {
		background: ${colors.surfaceNeutralBg2};
	}
`;
