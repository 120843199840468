import React, { FC } from "react";

import { ReactComponent as PlusSvg } from "public/plus12.svg";
import styled from "styled-components";
import { colors } from "tap2visit-ui-kit";

import { CitizenDrawerButtonAddText } from "./CitizenTexts";

interface ICitizenAddButton {
	onClick: () => void;
	text: string;
}

const CitizenAddButton: FC<ICitizenAddButton> = (props) => (
	<ButtonWrapper onClick={props.onClick}>
		<>
			<PlusSvg height={12} width={12} />
			<CitizenDrawerButtonAddText text={props.text} />
		</>
	</ButtonWrapper>
);

export default CitizenAddButton;

const ButtonWrapper = styled.button`
	justify-content: flex-start;
	background: ${colors.surfaceNeutralBgWhite};
	padding: 13px 12px;
	border-radius: 10px;
	width: 100%;

	display: flex;
	align-items: center;
	gap: 8px;

	cursor: pointer;
`;
