import React, { FC } from "react";

import { useQuery } from "react-query";
import styled from "styled-components";
import { Button, Switch } from "tap2visit-ui-kit";

import { getAddressById } from "api/api.aliases";
import TextHeader from "components/TextHeader";
import { IWorkingTimeCreate } from "interfaces/IOffice";
import { QueriesKeys } from "interfaces/queriesKeys";
import { ReactComponent as Eye_off } from "public/eye_off.svg";
import { ReactComponent as Eye_on } from "public/eye_on.svg";

import profileUKVisibilityStore from "../../profileUKVisibility.store";
import PageEditWeekDayCard from "../PageEdit.weekDayCard";
import RenderByCondition from "../RenderByCondition";

import { ITimeAction } from "./hooks/useTimeState";

interface IPageEditTime {
	dispatchTimeState: React.Dispatch<ITimeAction>;
	timeState: IWorkingTimeCreate[];
	initialTimeState: IWorkingTimeCreate[];
	resetTimeState: () => void;
}
const PageEditTime: FC<IPageEditTime> = (props) => {
	const [isOpenHours, setIsOpenHours] = React.useState(
		localStorage.getItem("hours_is_shown") ? localStorage.getItem("hours_is_shown") : true,
	);
	const [shouldISpecifyOfficeHours, setShouldISpecifyOfficeHours] = React.useState(false);

	const address = useQuery({
		queryFn: () => getAddressById({ params: { id: profileUKVisibilityStore.editableOffice.addressId } }),
		queryKey: [QueriesKeys.officeAddress, profileUKVisibilityStore.editableOffice?.id],
		enabled: !!profileUKVisibilityStore.editableOffice,
	});

	React.useEffect(() => {
		props.resetTimeState();
		props.initialTimeState.forEach((time) => {
			const editableTime = profileUKVisibilityStore.editableOffice?.timetables?.find((currTime) => currTime.dayOfWeek === time.dayOfWeek);

			if (editableTime) {
				props.dispatchTimeState({ payload: { ...time, ...editableTime } });
			}
		});
	}, [profileUKVisibilityStore.editableOffice, address.data]);

	return (
		<>
			<InlineHeader>
				<TextHeader>Часы работы</TextHeader>
				<ButtonStyled
					icon={isOpenHours ? (props) => <Eye_on fillOpacity={0.6} {...props} /> : Eye_off}
					typeButton={"secondary"}
					variant={"default"}
					size={"medium"}
					shape="square"
					onClick={() => {
						localStorage.setItem("hours_is_shown", String(!isOpenHours));
						setIsOpenHours((init) => !init);
					}}
				/>
			</InlineHeader>
			<RenderByCondition condition={!!isOpenHours}>
				<TimeTableWrapper>
					<div>
						<HoursShow>
							<SwitchStyled
								label="Указывать приемные часы"
								labelPosition={"left"}
								isActive={shouldISpecifyOfficeHours}
								onChangeActive={setShouldISpecifyOfficeHours}
								size={"medium"}
							/>
						</HoursShow>
						<WeekContainer>
							{props.timeState.map((item, idx) => (
								<PageEditWeekDayCard
									key={item?.workingTimePeriod?.startTime + item?.workingTimePeriod?.endTime + idx + item.dayOfWeek}
									weekDay={item}
									dispatchTimeState={props.dispatchTimeState}
									shouldISpecifyOfficeHours={shouldISpecifyOfficeHours}
								/>
							))}
						</WeekContainer>
					</div>
				</TimeTableWrapper>
			</RenderByCondition>
		</>
	);
};

export default PageEditTime;

const SwitchStyled = styled(Switch)`
	p {
		font-size: 14px;
	}
`;
const ButtonStyled = styled(Button)``;
const WeekContainer = styled.div`
	gap: 16px;
	display: grid;
`;

const InlineHeader = styled.div`
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-bottom: 20px;
`;

const TimeTableWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 16px;
	width: inherit;
`;

const HoursShow = styled.div`
	margin-bottom: 17px;
	width: fit-content;
`;
