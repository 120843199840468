import { Modal } from "tap2visit-ui-kit";

import ChoiceUKList from "../../authorization/components/ChoiceUKList";

interface ITemplateBasicOrganizationProps {
	setOpen: (v: boolean) => void;
}

export const TemplateBasicOrganization = ({ setOpen }: ITemplateBasicOrganizationProps) => {
	const toFirstItemMenu = () => {
		setOpen(false);
	};

	return (
		<Modal onOk={toFirstItemMenu} okText="Закрыть" title="Выбрать УК" onExit={toFirstItemMenu}>
			<ChoiceUKList onClose={toFirstItemMenu} />
		</Modal>
	);
};
