import React, { FC } from "react";

import styled from "styled-components";
import { Text } from "tap2visit-ui-kit";

interface ITableEmptySlugProps {
	text?: string;
}

const TableEmptySlug: FC<ITableEmptySlugProps> = (props) => {
	const { text = "Нет данных по таблице" } = props || {};

	return (
		<Container>
			<Text type="large-regular">{text}</Text>
		</Container>
	);
};

export default TableEmptySlug;

const Container = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 10px;
`;
