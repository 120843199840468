import React from "react";

import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";

import { getPollByIdApi } from "api/api.votes";
import { QueriesKeys } from "interfaces/queriesKeys";
import usePollIdFromURL from "pages/polls/hooks/usePollIdFromURL";
import PollsEditStore from "pages/polls/store/PollsItem.store";

const useSelectedPoll = () => {
	const location = useLocation();
	const [pollLocationType, pollId] = usePollIdFromURL();

	const searchParams = new URLSearchParams(location.search);
	const pollIdFromExistPoll = searchParams.get("from");
	const pollIdFromExistTemplate = searchParams.get("from-template");

	const editedPoll = useQuery({
		queryFn: () =>
			getPollByIdApi({
				path: {
					id: pollIdFromExistPoll ?? pollIdFromExistTemplate ?? pollId,
				},
			}),
		queryKey: [QueriesKeys.poll, pollIdFromExistPoll ?? pollIdFromExistTemplate ?? pollId],
		enabled:
			!!pollIdFromExistPoll || !!pollIdFromExistTemplate || ((pollLocationType === "edit" || pollLocationType === "view") && !!pollId),
	});

	React.useEffect(() => {
		if (editedPoll.data) {
			PollsEditStore.populatePoll({
				poll: {
					...editedPoll.data,
					isTemplate: pollIdFromExistTemplate ? false : editedPoll.data.isTemplate,
				},
				mode: pollIdFromExistPoll ? "new" : "edit",
			});
		}
	}, [editedPoll.data]);

	return editedPoll.isFetching;
};

export default useSelectedPoll;
