import React from "react";

import { Button, Space } from "tap2visit-ui-kit";

import TextHeader from "components/TextHeader";

import DrawerStore from "./store/Drawer.store";

const AppealsHeader = () => (
	<Space width="100%" justify="space-between">
		<TextHeader>Обращения</TextHeader>
		<Space>
			<Button onClick={() => DrawerStore.openCreateDrawer()}>Создать обращение</Button>
			{/* <Button typeButton="secondary">Шаблоны уведомлений</Button> */}
		</Space>
	</Space>
);

export default AppealsHeader;
