import React, { FC } from "react";

import { getUserApi } from "api/api.employee";
import { Divider } from "components/DrawerFormFields/DrawerFormFields";
import { ReactComponent as ExitSvg } from "public/exit.svg";
import { createPortal } from "react-dom";
import { useQuery } from "react-query";
import AuthStore from "store/Auth.Store";
import styled, { css } from "styled-components";
import { Avatar, Text, colors, useClickAway } from "tap2visit-ui-kit";

import { RowItem } from "../styles";

const USER_ROW_ID = "user_row";
const USER_MENU_CONTAINER_ID = "user_menu_container";
interface IMenuProfile {
	collapsed: boolean;
}

const MenuProfile: FC<IMenuProfile> = (props) => {
	const [isVisibleUserContextMenu, setIsVisibleUserContextMenu] = React.useState(false);
	const contextMenuDomNode = React.useRef(document.getElementById("user-info"));

	useClickAway({
		id: USER_ROW_ID,
		outerIds: [USER_MENU_CONTAINER_ID],
		callback: () => {
			setIsVisibleUserContextMenu(false);
		},
	});

	const user = useQuery({
		queryFn: () => getUserApi(),
		enabled: AuthStore.checkAuth(),
	});

	const firstName = user.data?.firstName;
	const lastName = user.data?.lastName;

	const contextMenuItems = [
		{
			icon: ExitSvg,
			text: "Выйти",
			action: AuthStore.logout,
		},
	];

	return (
		<RowItem
			key={"subBottom2"}
			collapsed={props.collapsed}
			id={USER_MENU_CONTAINER_ID}
			onClick={() => {
				setIsVisibleUserContextMenu((status) => !status);
			}}>
			<Container>
				{user.data && (
					<UserRow>
						<Avatar size={"32"} firstName={lastName?.[0]} lastName={firstName?.[0]} />
						{!props.collapsed && (
							<Text type={"base-medium"}>
								{firstName} {lastName}
							</Text>
						)}
					</UserRow>
				)}
				{isVisibleUserContextMenu && (
					<>
						{createPortal(
							<UserContextMenu collapsed={props.collapsed} id={USER_ROW_ID}>
								<ContextMenuHeader>
									<Text type="base-bold">
										{firstName} {lastName}
									</Text>
									<Text type="small-regular">{user.data?.email}</Text>
								</ContextMenuHeader>
								<Divider />

								<BodyBlock>
									<>
										{contextMenuItems.map((item) => {
											const Icon = item.icon;
											return (
												<BodyRow onClick={item.action}>
													<Icon />
													<Text type="base-regular">{item.text}</Text>
												</BodyRow>
											);
										})}
									</>
								</BodyBlock>
							</UserContextMenu>,
							contextMenuDomNode.current,
						)}
					</>
				)}
			</Container>
		</RowItem>
	);
};

export default MenuProfile;

const paddingMixin = "padding: 10px 18px";

const Container = styled.div`
	position: relative;
`;

const UserRow = styled.div`
	display: flex;
	align-items: center;
	gap: 12px;
	width: 100%;
	height: 100%;
`;

const UserContextMenu = styled.div<{ collapsed: boolean }>`
	min-width: 250px;
	border: 1px solid ${colors.strokeDivider};
	border-radius: 8px;
	overflow: hidden;

	position: fixed;
	bottom: 50px;
	z-index: 1000;

	// TODO Add shadows from ui kit | need to update shadow variables after request from designers
	box-shadow: 0px 24px 48px -12px #1018282e;

	${(props) => {
		const offsetLeft = props.collapsed ? "70px" : "260px";

		return css`
			left: ${offsetLeft};
		`;
	}}
`;

const ContextMenuHeader = styled.div`
	// TODO Need to disccusion with designers about color without alpha chanel
	background-color: #fafafa;

	${paddingMixin};
`;

const BodyBlock = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;
	background-color: ${colors.surfaceNeutralBgWhite};

	${paddingMixin};

	cursor: pointer;
`;

const BodyRow = styled.div`
	display: flex;
	gap: 5px;
`;
