import React, { FC, useState } from "react";

import { Divider, Space } from "antd";
import { useQuery } from "react-query";
import styled from "styled-components";
import { Avatar, Button, Pagination, Text } from "tap2visit-ui-kit";

import { Spacer } from "components/layout/Spacer";
import BaseModal from "components/Modals/BaseModal";
import BuildingFilterStore from "components/TableFilter/BuildingFilter.store";
import Input from "components/ui/Input/Input";
import { IClientGateway } from "interfaces/IClient";
import { ReactComponent as PlusSvg } from "public/plus.svg";

import { getClientApi, TGetClientApiParams } from "../../../api/api.gateway";
import CitizenChoiceButton from "../../../components/Citizens/components/CitizenChoiceButton";
import CitizenRoleModal from "../../../components/Citizens/components/CitizenRoleModal";
import CitizenBuildingObjectStore from "../../../components/Citizens/store/CitizenBuildingObject.store";
import CitizensDrawerStore from "../../../components/Citizens/store/Citizens.drawer.store";
import PaginationContainer from "../../../components/PaginationContainer";
import useTablePaginationLogic from "../../../hooks/useTablePaginationLogic";
import { QueriesKeys } from "../../../interfaces/queriesKeys";

interface ICitizenConfidantModal {
	open: boolean;
	setOpen: (v: boolean) => void;
}

const TableFilterInstanceStore = new BuildingFilterStore();

const CitizenConfidantModal: FC<ICitizenConfidantModal> = ({ open, setOpen }) => {
	const [openCitizenRoleDrawer, setOpenCitizenRoleDrawer] = useState(false);
	const paginationData = useTablePaginationLogic();
	const [searchInput, setSearchInput] = React.useState("");

	const [selectedCitizenId, setSelectedCitizenId] = React.useState<string>();

	const clients = useQuery({
		queryFn: () => {
			const params: TGetClientApiParams = {
				complexIds: TableFilterInstanceStore.selectedComplexIds,
				buildingIds: TableFilterInstanceStore.selectedBuildingsIds,
				entranceIds: TableFilterInstanceStore.selectedEntrancesIds,
				floorIds: TableFilterInstanceStore.selectedFloorsIds,
				roleIds: TableFilterInstanceStore.selectRoles,
				page: paginationData.clientsCurrentPage - 1,
				size: paginationData.clientsSize,
				fullNameSearch: searchInput,
			};

			return getClientApi({
				params,
			});
		},

		queryKey: [QueriesKeys.clients, paginationData.clientsSize, paginationData.clientsCurrentPage],
	});

	const onClickFindButton = () => {
		clients.refetch();
	};

	React.useEffect(() => {
		if (clients.data) {
			paginationData.setClientsTotal(clients.data.totalPages);
		}
	}, [clients.data?.page]);

	const isVisiblePagination = !clients.isFetching && clients.data;

	const onCloseDrawer = () => {
		setOpen(false);
		CitizenBuildingObjectStore.setPreselectedRole(undefined);
	};

	const createNewUser = () => {
		CitizensDrawerStore.openDrawer({ mode: "create" });
	};

	const setSelectedClient = (client: IClientGateway) => {
		setSelectedCitizenId(() => {
			if (client.id === selectedCitizenId) {
				return undefined;
			}
			return client.id;
		});
	};

	React.useEffect(
		() => () => {
			if (!open) {
				setSelectedCitizenId(undefined);
			}
		},
		[open],
	);

	return (
		<>
			<CitizenRoleModal
				open={openCitizenRoleDrawer}
				setOpen={setOpenCitizenRoleDrawer}
				selectedCitizenId={selectedCitizenId}
				closeSelectUserDrawer={() => setOpen(false)}
			/>
			<BaseModal
				isVisible={open}
				title={"Жители"}
				contentContainerStyle={{ height: "70vh", overflow: "auto" }}
				onCancel={onCloseDrawer}
				onExit={onCloseDrawer}
				footerSlot={
					<>
						{isVisiblePagination && (
							<PaginationContainerWrapper>
								<Pagination
									currentPage={paginationData.clientsCurrentPage}
									setCurrentPage={paginationData.setClientsCurrentPage}
									total={paginationData.clientsTotal}
								/>
							</PaginationContainerWrapper>
						)}
						<DividerWrapper />
						<Spacer px={16} />
						<FooterButtonWrapper>
							<Button typeButton="secondary" icon={PlusSvg} onClick={createNewUser}>
								Новый житель
							</Button>
							<Space>
								<Button onClick={() => setOpen(false)} typeButton="secondary">
									Отменить
								</Button>
								<Button
									disabled={!selectedCitizenId}
									onClick={() => {
										setOpenCitizenRoleDrawer(true);
									}}>
									Продолжить
								</Button>
							</Space>
						</FooterButtonWrapper>
					</>
				}>
				<ContentContainer>
					<SpaceWrapper direction="vertical" size={20}>
						<Space size={20}>
							<InputWrapper value={searchInput} onChangeValue={setSearchInput} sizeInput="medium" placeholder="Поиск по ФИО или ИНН" />
							<Button size="medium" onClick={onClickFindButton}>
								Найти
							</Button>
						</Space>
						{clients.data?.content.map((v) => (
							<>
								<SpaceWrapper>
									<Space>
										<Avatar size="32" lastName={v.fullName.split(" ")[1]} firstName={v.fullName.split(" ")[0]} />
										<Space direction="vertical" size={0}>
											<ClientsTitle>{v.fullName}</ClientsTitle>
											<ClientsText type="table-cell-m-regular">{v.accounts[0].number}</ClientsText>
										</Space>
									</Space>
									<CitizenChoiceButton onClick={() => setSelectedClient(v)} isActive={v.id === selectedCitizenId} />
								</SpaceWrapper>
								<DividerWrapper />
							</>
						))}
					</SpaceWrapper>
				</ContentContainer>
			</BaseModal>
		</>
	);
};

export default CitizenConfidantModal;

const FooterButtonWrapper = styled(Space)`
	justify-content: space-between;
	width: 100%;
`;

const PaginationContainerWrapper = styled(PaginationContainer)`
	padding-bottom: 16px;
`;

const DividerWrapper = styled(Divider)`
	margin: 0px;
`;
const ClientsTitle = styled.p`
	font-size: 16px;
`;
const ClientsText = styled(Text)`
	color: rgba(18, 18, 18, 0.6);
	font-size: 14px;
`;
const SpaceWrapper = styled(Space)`
	width: 100%;
	justify-content: space-between;
`;
const InputWrapper = styled(Input)`
	width: 420px;
`;
const ContentContainer = styled.div`
	width: 100%;
`;
